import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	createManifesto,
	getData,
	numerosEditadoBR,
	updateTableNew,
	valorMoeda,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";
import axios from "axios";
import { getErrorMessage } from "../../services/helpers";
import ModalErrorPretty from "./ModalErrorPretty";
import ModalSuccessPretty from "./ModalSuccessPretty";

export default function ModalCancelaManifesto(props) {
	const user = useSelector((state) => state.userSlice);
	
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Cancelar",
		style: "primary",
	});


	const [error, setError] = useState({ show: false, message:"" });
	const [success, setSuccess] = useState({ show: false, message:"" });


	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, show: false }));

	const [body, setBody] = useState({chave: props.bodyList.chave, justificativa: ""})

	const onSubmitForm = async (e) => {
		e.preventDefault()
		setLoading({isLoading: true, msg:"Carregando", style:'warning'})
		try {
			if(!body.chave) throw new Error("Chave MDFe não informada.")
			if(!body.justificativa) throw new Error("UF de Encerramento não informada.")
				
			const updateData = await updateTableNew('manifesto/v2/cancela-mdfe', body)
			if(updateData.error) throw new Error(updateData.error)
			setLoading({isLoading: false, msg:"Cancelado", style:'success'})
			props.updateStatusManifesto()
			
			setTimeout(() => {
				handlerSimpleClose()
			}, 750)
		} catch (error) {
			setError({show: true, message: getErrorMessage(error)})
		} finally {
			setLoading({isLoading: false, msg:"Cancelar", style:'primary'})
		}
	}



	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Cancelar Manifesto
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={onSubmitForm}>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Chave:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									value={body.chave}
									disabled
								/>
							</Col>
						</Row>
						<Row className="my-5">
							<Col className="d-flex flex-column">
								<h5>Motivo do Cancelamento:</h5>
								<textarea rows={ 7 } value={body.justificativa} onChange={(e) => setBody(last => ({...last, justificativa: e.target.value}))}></textarea>
							</Col>
						</Row>
					<Row style={{ width: "100%" }} className="mt-5">
						<Col>
							<Button variant={loading.style} type="submit">
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
					</Form>
				</Modal.Body>
				{error.show ? (
					<ModalErrorPretty setShow={setError} {...error} />
				) : (
					""
				)}
				{success.show ? (
					<ModalSuccessPretty setShow={setSuccess} {...success} />
				) : (
					""
				)}
			</Modal>
		</>
	);
}
