import { createRef, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Loading from "../../loading/Loading";
import UserLayout from "../layout/UserLayout";
import {
	apenasNumeros,
	cpf_cnpj_mask,
	cpf_mask,
	createFuncionario,
	getFuncionarios,
	pis_mask,
	telefone_mask,
} from "../../../services/Servicos";
import { useDispatch, useSelector } from "react-redux";
import {
	update,
	updateFiliaisList,
	updateFuncionarios,
} from "../../../redux/slicers/user";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const nomeD = createRef(null);
const emailD = createRef(null);
const telefoneD = createRef(null);
const cpfD = createRef(null);
const cnhD = createRef(null);
const dataNascimentoD = createRef(null);
const dataValidadeCnh = createRef(null);
const userStatusD = createRef(null);
const tipoD = createRef(null);
const senhaD = createRef(null);
const localSeparacaoD = createRef(null);
const DescLocalSeparacaoD = createRef(null);
const propriedadeD = createRef(null);
const pisD = createRef(null);

export default function UserCadastro() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const filiaisList = useSelector((state) => state.userSlice.filiaisList.data);

	const [user, setUser] = useState({
		name: "",
		email: "",
		telefone: "",
		cpf: "",
		cnh: "",
		tipo: "",
		id_filial: "",
		local_separacao: "",
		status: false,
		data_nascimento: "",
		password: "",
		typeImg: "CNH",
		fotoCnh: "",
		desc_setor_sep: "",
		error: {
			flag: false,
			msg: "",
		},
		pis: "",
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ show: false });

	const onChangeInput = (e) => {
		e.preventDefault();
		// )
		setUser((last) => ({
			...last,
			name: nomeD.current.value.toUpperCase(),
			email: emailD.current.value.toUpperCase(),
			telefone: telefone_mask(telefoneD.current.value),
			cpf: cpf_mask(cpfD.current.value),
			pis: pis_mask(pisD.current.value),
			cnh: apenasNumeros(cnhD.current.value),
			tipo: tipoD.current.value,
			id_filial: propriedadeD.current.value,
			local_separacao:
				tipoD.current.value === "Separador"
					? localSeparacaoD.current.value
					: "",
			desc_setor_sep:
				tipoD.current.value === "Separador"
					? DescLocalSeparacaoD.current.value
					: "",
			password: senhaD.current.value,
			data_nascimento: dataNascimentoD.current.value,
			data_valid_cnh: dataValidadeCnh.current.value,
			error: { flag: false, msg: "" },
		}));
	};

	const onChangeInputFile = (e) => {
		e.preventDefault();

		setUser((last) => ({
			...last,
			fotoCnh: e.target.files[0],
		}));
	};

	const updateFuncionariosList = () => {
		getFuncionarios().then((response) => {
			dispatch(updateFuncionarios(response));
			navigate("/user/cadastrados");
		});
	};

	const submitHandler = (e) => {
		e.preventDefault();
		setLoading(!loading);

		createFuncionario(user)
			.then((response) => {
				setLoading(false);
				updateFuncionariosList();
			})
			.catch((error) => {
				const message = error?.response?.data?.message || error?.message;
				setError({ show: true, message: message });
				setLoading(false);
			});
	};

	const onlyNumbersHandler = (e) => {
		// e.preventDefault()
		const buttonsAllowed = [
			"backspace",
			"alt",
			"arrowleft",
			"arrowright",
			"delete",
			"tab",
		].indexOf(e.key.toLowerCase());
		if (buttonsAllowed >= 0) {
		} else if (buttonsAllowed >= 0 || /[0-9]/g.test(e.key)) {
		} else {
			e.preventDefault();
			return false;
		}
	};

	return (
		<UserLayout>
			<Form onSubmit={submitHandler}>
				<h3 className="card-header">Dados Cadastrais</h3>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Propriedade:</Form.Label>
						<Form.Select ref={propriedadeD} onChange={onChangeInput}>
							<option></option>
							{filiaisList.map((item, index) => (
								<option key={index} value={item.idFilial}>
									{item.identificacaoInterna}
								</option>
							))}
						</Form.Select>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Nome Completo:</Form.Label>
						<Form.Control
							type="text"
							placeholder="joao"
							required
							ref={nomeD}
							value={user.name}
							onChange={onChangeInput}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Email:</Form.Label>
						<Form.Control
							type="email"
							placeholder="joao@gmail.com"
							required
							ref={emailD}
							value={user.email}
							onChange={onChangeInput}
						/>
						{user?.error?.msg?.includes("email") ? (
							<Form.Text className="text-muted error-form">
								Email Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>CPF:</Form.Label>
						<Form.Control
							type="text"
							placeholder="Somente numeros"
							required
							name="CPF"
							onKeyDown={onlyNumbersHandler}
							maxLength={14}
							ref={cpfD}
							onChange={onChangeInput}
							// pattern={/[0-9]/g}
							value={user.cpf}
						/>

						{user.error.msg.includes("cpf") ? (
							<Form.Text className="text-muted error-form">
								CPF Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Telefone:</Form.Label>
						<Form.Control
							type="text"
							placeholder="00000000000"
							maxLength={15}
							required
							ref={telefoneD}
							onKeyDown={onlyNumbersHandler}
							onChange={onChangeInput}
							value={user.telefone}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Data de Nascimento:</Form.Label>
						<Form.Control
							type="date"
							placeholder="xxxxxxx"
							ref={dataNascimentoD}
							onChange={onChangeInput}
						/>
					</Col>
				</Row>
				<h3 className="card-header mt-5">Informações do Documento</h3>
				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>PIS:</Form.Label>
						<Form.Control
							type="text"
							placeholder="00000000000"
							onKeyDown={onlyNumbersHandler}
							maxLength={14}
							ref={pisD}
							onChange={onChangeInput}
							required
							value={user.pis}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>CNH:</Form.Label>
						<Form.Control
							type="text"
							placeholder="00000000000"
							onKeyDown={onlyNumbersHandler}
							maxLength={11}
							ref={cnhD}
							onChange={onChangeInput}
							required
							value={user.cnh}
						/>
						{user.error.msg.includes("cnh") ? (
							<Form.Text className="text-muted error-form">
								CNH Já Cadastrado!
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>FOTO DA CNH:</Form.Label>
						<Form.Control
							type="file"
							name={user.name}
							onChange={onChangeInputFile}
						/>
						{user.error.msg.includes("imagem") ? (
							<Form.Text className="text-muted error-form">
								{user.error.msg}
							</Form.Text>
						) : (
							""
						)}
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Validade da CNH :</Form.Label>
						<Form.Control
							type="date"
							placeholder="xxxxxxx"
							required
							ref={dataValidadeCnh}
							onChange={onChangeInput}
						/>
					</Col>
				</Row>
				<h3 className="card-header mt-5">Informações do Usuário</h3>

				<Row className="my-2">
					<Col md="4" className="mb-3">
						<Form.Label>Tipo:</Form.Label>
						<Form.Select
							ref={tipoD}
							// onChange={onChangeInput}
							onChange={() => {
								if (tipoD.current.value !== "Separador") {
									setUser((last) => ({ ...last, local_separacao: "" }));
									localSeparacaoD.current.value = "";
								}
								setUser((last) => ({ ...last, tipo: tipoD.current.value }));
							}}
							required
						>
							<option value="Caminhoneiro">Motorista</option>
							<option value="Carregador">Carregador</option>
							<option value="Gestor">Gestor</option>
							<option value="Separador">Separador</option>
							<option value="Financeiro">Financeiro</option>
						</Form.Select>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Setor da Separação:</Form.Label>
						<Form.Select
							ref={localSeparacaoD}
							onChange={onChangeInput}
							disabled={tipoD?.current?.value === "Separador" ? false : true}
						>
							<option value=""></option>
							<option value="SETOR_1">SETOR_1</option>
							<option value="SETOR_2">SETOR_2</option>
							<option value="SETOR_3">SETOR_3</option>
							<option value="SETOR_4">SETOR_4</option>
							<option value="SETOR_5">SETOR_5</option>
							<option value="SETOR_6">SETOR_6</option>
						</Form.Select>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Descricao do Setor de Separação:</Form.Label>
						<Form.Control
							ref={DescLocalSeparacaoD}
							onChange={onChangeInput}
							disabled={tipoD?.current?.value === "Separador" ? false : true}
						/>
					</Col>
					<Col md="4" className="mb-3">
						<Form.Label>Senha:</Form.Label>
						<Form.Control
							type="text"
							placeholder="xxxxxxx"
							required
							ref={senhaD}
							onChange={onChangeInput}
						/>
					</Col>
					<Col md="6" className="mb-3">
						<Form.Group>
							<Form.Label>Status:</Form.Label>
							<Form.Check
								type="switch"
								ref={userStatusD}
								onChange={() =>
									setUser((last) => ({ ...last, status: !user.status }))
								}
								label={user.status ? "Usuário Ativo" : "Usuário Inativo"}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row className="mx-auto justify-content-center my-5">
					<Col md="3" className="mb-3">
						<Button variant="primary" type="submit">
							{loading ? <Loading msg="Salvando" /> : "Salvar"}
						</Button>
					</Col>
					<Col md="3" className="mb-3">
						<Button variant="secondary" type="reset">
							Apagar
						</Button>
					</Col>
				</Row>
			</Form>
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
		</UserLayout>
	);
}
