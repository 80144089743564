import { Canvas, useFrame } from "@react-three/fiber";
import { Fragment, Suspense, useEffect, useRef, useState } from "react";
import { OrbitControls, useHelper } from "@react-three/drei";
import * as THREE from "three";
import "./Carregamento.scss";
import { Selection, Select } from "@react-three/postprocessing";
import { useDispatch, useSelector } from "react-redux";
import {
	addProduto,
	removeProduto,
	updateProduto,
	updateSteps,
} from "../../redux/slicers/carga";
import { v4 as uuidv4 } from "uuid";
import {
	getData,
	numerosEditadoBR,
	updateTable,
} from "../../services/Servicos";
import {
	update,
	updateEmbalagensList,
	updateEmbalagensListCarregados,
	updateProdutosEEmbalagensBySku,
	updateProdutosList,
	updateProdutosListCarregados,
} from "../../redux/slicers/user";
import Loading from "../loading/Loading";
import ModalError from "../modal/ModalError";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ModalGetCargaCarregamento from "../modal/ModalGetCargaCarregamento";
import ModalErrorCarregamento_old from "../modal/ModalErrorCarregameto_old";
import Caminhao from "./Caminhao";
import {
	Line,
	Loader,
	distanceVector,
	fatorMedida,
	positionCalculadaProduto,
} from "./Helpers";
import CarregarCaminhaoCargaLocal from "./CarregarCaminhaoCargaLocal";
import ModalSelectCargas from "../modal/ModalSelectCargas";
import { unicos } from "../../services/helpers";

function WoodBox({
	position,
	args,
	target,
	setTarget,
	name,
	mass,
	argsDesenho,
	argsRealCaminhao,
	actual,
	last,
	tipo,
	setInformacoesCarga = () => {},
	cor_caixa,
	index,
}) {
	// const dimensoesDesenho = useSelector(state => state.cargaSlicer.dimensoesDesenho)
	// const dimensoesCaminhao = useSelector(state => state.cargaSlicer.dimensoesCaminhao)
	const [inside, setInside] = useState(false);

	const caminhaoDB = useSelector(
		(state) => state.userSlice.caminhaoSelecionado
	);

	const fatorMedidaObjeto = fatorMedida(argsDesenho, argsRealCaminhao);
	const argsReal = [
		args[0] * fatorMedidaObjeto.fator_largura,
		args[1] * fatorMedidaObjeto.fator_altura,
		args[2] * fatorMedidaObjeto.fator_comprimento,
	];
	const argsSimulado = (args) => [
		args[0] / fatorMedidaObjeto.fator_largura,
		args[1] / fatorMedidaObjeto.fator_altura,
		args[2] / fatorMedidaObjeto.fator_comprimento,
	];
	const argsRealLast = (args) => [
		args[0] * fatorMedidaObjeto.fator_largura,
		args[1] * fatorMedidaObjeto.fator_altura,
		args[2] * fatorMedidaObjeto.fator_comprimento,
	];
	const positionCalculada = positionCalculadaProduto(position, argsReal); // [position[0] + argsReal[0] / 2, position[1] + argsReal[1] / 2, position[2] + argsReal[2] / 2]

	// let positionCalculada = position
	const ref = useRef(null);

	const [hover, setHover] = useState(false);

	useFrame(() => {
		if (target && target.name === name) {
			/* restrições de movimento para os objetos */
			target.position.x =
				target.position.x < argsReal[0] / 2
					? argsReal[0] / 2
					: target.position.x < argsDesenho.l - argsReal[0] / 2
					? target.position.x
					: argsDesenho.l - argsReal[0] / 2;
			target.position.y =
				target.position.y < argsReal[1] / 2
					? argsReal[1] / 2
					: target.position.y < argsDesenho.a - argsReal[1] / 2
					? target.position.y
					: argsDesenho.a - argsReal[1] / 2;
			target.position.z =
				target.position.z < argsReal[2] / 2
					? argsReal[2] / 2
					: target.position.z < argsDesenho.c - argsReal[2] / 2
					? target.position.z
					: argsDesenho.c - argsReal[2] / 2;
			const lastArgs = last.geometry.parameters;
			const teste2 = distanceVector(actual.position, last.position);
			const para1 = distanceVector(last.position, actual.position);

			const distanceOrigem1 = distanceVector(actual.position, {
				x: 0,
				y: 0,
				z: 0,
			});

			if (actual.position.z > last.position.z) {
				teste2.z -= lastArgs?.depth - lastArgs?.depth;
				para1.z -= lastArgs?.depth - lastArgs?.depth;

				/* origem */
				distanceOrigem1.z -= lastArgs?.depth - lastArgs?.depth;
			} else {
				teste2.z += lastArgs?.depth - lastArgs?.depth;
				para1.z += lastArgs?.depth - lastArgs?.depth;

				/* origem */
				distanceOrigem1.z += lastArgs?.depth - lastArgs?.depth;
			}
			if (actual.position.y > last.position.y) {
				teste2.z -= lastArgs?.height - lastArgs.height;
				para1.z -= lastArgs?.height - lastArgs?.height;
				/* origem */
				distanceOrigem1.z -= lastArgs?.height - lastArgs.height;
			} else {
				teste2.z += lastArgs?.height - lastArgs?.height;
				para1.z += lastArgs?.height - lastArgs?.height;
				/* origem */
				distanceOrigem1.z += lastArgs?.height - lastArgs?.height;
			}
			if (actual.position.x > last.position.x) {
				teste2.z -= lastArgs?.width - lastArgs?.width;
				para1.z -= lastArgs?.width - lastArgs?.width;
				// origem
				distanceOrigem1.z -= lastArgs?.width - lastArgs?.width;
			} else {
				teste2.z += lastArgs?.width - lastArgs?.width;
				para1.z += lastArgs?.width - lastArgs?.width;
				/* origem */
				distanceOrigem1.z += lastArgs?.width - lastArgs?.width;
			}

			let teste4 = {};
			let teste5 = {};
			let teste6 = {};
			teste4.D = (distanceOrigem1.x / argsReal[0]) * args[0] - args[0] / 2;
			teste4.P = (distanceOrigem1.y / argsReal[1]) * args[1] - args[1] / 2;
			teste4.T = (distanceOrigem1.z / argsReal[2]) * args[2] - args[2] / 2;
			// Frente
			teste5.E =
				(distanceOrigem1.x / argsReal[0]) * args[0] +
				args[0] / 2 -
				caminhaoDB.caminhao_largura;
			teste5.T =
				(distanceOrigem1.y / argsReal[1]) * args[1] +
				args[1] / 2 -
				caminhaoDB.caminhao_altura;
			teste5.F =
				(distanceOrigem1.z / argsReal[2]) * args[2] +
				args[2] / 2 -
				caminhaoDB.caminhao_comprimento;

			setInformacoesCarga((last) => ({
				...last,
				distancia_ao_caminhao: {
					...last.distancia_ao_caminhao,
					tampa_frontral: numerosEditadoBR(teste5.F), // frontal
					teto: numerosEditadoBR(teste5.T), // teto
					tampa_lateral_esquerda: numerosEditadoBR(teste5.E), // lateral esquerda
					tampa_traseira: numerosEditadoBR(teste4.T), // traseira
					acoalho: numerosEditadoBR(teste4.P), // piso
					tampa_lateral_direita: numerosEditadoBR(teste4.D), // lateral direita
				},
			}));

			const dimensaoRealLast = argsSimulado([
				last?.geometry?.parameters?.width,
				last?.geometry?.parameters?.height,
				last?.geometry?.parameters?.depth,
			]);
			const dimensaoDesenholLast = argsRealLast([
				dimensaoRealLast[0],
				dimensaoRealLast[1],
				dimensaoRealLast[2],
			]);

			if (!dimensaoRealLast[0]) {
				if (inside) setInside(false);
				return;
			}
			const menorLarg =
				dimensaoRealLast[0] > args[0] ? args[0] : dimensaoRealLast[0];
			const menorAlt =
				dimensaoRealLast[1] > args[1] ? args[1] : dimensaoRealLast[1];
			const menorComp =
				dimensaoRealLast[2] > args[2] ? args[2] : dimensaoRealLast[2];

			const maiorLarg =
				dimensaoRealLast[0] < args[0] ? args[0] : dimensaoRealLast[0];
			const maiorAlt =
				dimensaoRealLast[1] < args[1] ? args[1] : dimensaoRealLast[1];
			const maiorComp =
				dimensaoRealLast[2] < args[2] ? args[2] : dimensaoRealLast[2];

			// // objeto
			teste6.l =
				-1 *
				((para1.x / dimensaoDesenholLast[0]) * dimensaoRealLast[0] -
					dimensaoRealLast[0] / 2 +
					args[0] / 2);
			teste6.a =
				-1 *
				((para1.y / dimensaoDesenholLast[1]) * dimensaoRealLast[1] -
					dimensaoRealLast[1] / 2 +
					args[1] / 2);
			teste6.c =
				-1 *
				((para1.z / dimensaoDesenholLast[2]) * dimensaoRealLast[2] -
					dimensaoRealLast[2] / 2 +
					args[2] / 2);
			const selecteMaiorAltura = (param) => {
				if (param) {
					if (maiorAlt * -1 <= teste6.a && teste6.a <= menorAlt * 1) {
						return true;
					} else {
						return false;
					}
				} else {
					if (maiorAlt >= teste6.a && teste6.a >= menorAlt * -1) {
						return true;
					} else {
						return false;
					}
				}
			};
			const selecteMaiorComprimento = (param) => {
				if (param) {
					if (maiorComp * -1 <= teste6.c && teste6.c <= menorComp * 1) {
						return true;
					} else {
						return false;
					}
				} else {
					if (maiorComp >= teste6.c && teste6.c >= menorComp * -1) {
						return true;
					} else {
						return false;
					}
				}
			};
			const selecteMaiorLargura = (param) => {
				if (param) {
					if (maiorLarg * -1 <= teste6.l && teste6.l <= menorLarg * 1) {
						return true;
					} else {
						return false;
					}
				} else {
					if (maiorLarg >= teste6.l && teste6.l >= menorLarg * -1) {
						return true;
					} else {
						return false;
					}
				}
			};

			const produtoMaiorLarguraSelecionado =
				args[0] === maiorLarg ? true : false;
			const produtoMaiorAlturaSelecionado = args[1] === maiorAlt ? true : false;
			const produtoMaiorComprimentoSelecionado =
				args[2] === maiorComp ? true : false;
			const flagAltura = produtoMaiorAlturaSelecionado
				? selecteMaiorAltura(true)
				: selecteMaiorAltura(false);
			const flagComprimento = produtoMaiorComprimentoSelecionado
				? selecteMaiorComprimento(true)
				: selecteMaiorComprimento(false);
			const flagLargura = produtoMaiorLarguraSelecionado
				? selecteMaiorLargura(true)
				: selecteMaiorLargura(false);

			if (flagAltura && flagLargura && flagComprimento) {
				if (!inside) {
					setInside(true);
				}
			} else {
				if (inside) {
					setInside(false);
				}
			}

			setInformacoesCarga((last) => ({
				...last,
				distancia_ao_produto: {
					...last.distancia_ao_caminhao,
					altura: numerosEditadoBR(teste6.a), // frontal
					largura: numerosEditadoBR(teste6.l), // teto
					comprimento: numerosEditadoBR(teste6.c), // lateral esquerda
				},
			}));

			return;
		}
	}, []);

	useEffect(() => {
		if (index) {
			setTarget(ref.current);
		}
	}, []);

	return (
		<Select enabled={hover}>
			<mesh
				dispose={null}
				castShadow
				receiveShadow
				onClick={(e) => {
					e.stopPropagation(); /* para não continuar e pegar o objeto atras do selecionado */
					setTarget(e.object);
				}}
				name={name}
				side={THREE.DoubleSide}
				ref={ref}
				position={positionCalculada}
				mass={mass}
				tipo={tipo}
			>
				<boxGeometry args={argsReal} />
				<meshLambertMaterial
					color={
						inside && target?.name === name
							? "#ff0000"
							: target?.name === name
							? "#A63F03"
							: cor_caixa
					}
				/>
			</mesh>
		</Select>
	);
}

function LightComponent({ position, args }) {
	const sLightRef = useRef();
	useHelper(sLightRef, THREE.PointLightHelper, 1, "red");
	return (
		<pointLight
			/* ref={sLightRef} */ position={position}
			args={args}
			castShadow
		/>
	);
}

function LightScene() {
	return (
		<Fragment>
			<LightComponent position={[50, 10, 0]} args={["#ffffff", 1]} />
			<LightComponent position={[-50, 10, 0]} args={["#ffffff", 1]} />
			<LightComponent position={[0, 50, 50]} args={["#ffffff", 1]} />
			<LightComponent position={[0, 50, -50]} args={["#ffffff", 1]} />
			<ambientLight args={["#ffffff", 0.1]} />
		</Fragment>
	);
}

function AxisHelper() {
	return <primitive object={new THREE.AxesHelper(5)} />;
}

function Cena({
	setTarget,
	target,
	boxes,
	setBoxes,
	setInformacoesCarga = () => {},
}) {
	/*  caminhao Direcionado pela empresa para aquele determinado caminhoneiro*/
	// const caminhao = useSelector(state => state.cargaSlicer.dimensoesCaminhao)
	const user = useSelector((state) => state.userSlice);
	const caminhaoDB = useSelector(
		(state) => state.userSlice.caminhaoSelecionado
	);
	const produtos = useSelector((state) => state.userSlice.produtosList.data);
	const embalagens = useSelector(
		(state) => state.userSlice.embalagensList.data
	);
	const romaneiosList = useSelector(
		(state) => state.userSlice.romaneiosList.data
	);
	const produtosEEmbalagensA = user.produtosEEmbalagens.data;
	const [argsDesenho, setArgsDesenho] = useState({
		c: 0,
		a: 0,
		l: 0,
	});

	const selectProduto = (id) => produtos.filter((item) => item.id === id)[0];
	const selectEmbalagem = (id) =>
		embalagens.filter((item) => item.id === id)[0];
	const selectRomaneio = (numero_pedido) =>
		romaneiosList.find((item) => item.numero_pedido === numero_pedido);
	const [last, setLast] = useState({
		name: "",
		position: {
			x: 0,
			y: 0,
			z: 0,
		},
		geometry: {
			parameters: {
				width: 0,
				depth: 0,
				height: 0,
			},
		},
	});

	const [reset, setReset] = useState(false);
	const [actual, setActual] = useState({
		name: "",
		position: {
			x: 0,
			y: 0,
			z: 0,
		},
		geometry: {
			parameters: {
				width: 0,
				depth: 0,
				height: 0,
			},
		},
	});

	useEffect(() => {
		switch (caminhaoDB.caminhao_tipo) {
			case "Carreta":
				if (caminhaoDB.caminhao_qtd_eixos == 2) {
					setArgsDesenho({
						c: 7.33725,
						a: 2.55226,
						l: 2.35579,
					});
					return;
				}
				if (caminhaoDB.caminhao_qtd_eixos == 3) {
					setArgsDesenho({
						c: 9.87056,
						a: 2.55226,
						l: 2.35579,
					});
					return;
				}
			default:
				setArgsDesenho({ c: 6.00757, a: 2.4785, l: 2.3774 });
		}
	}, []);

	useEffect(() => {
		if (!target) {
			setInformacoesCarga((last) => ({
				...last,
				info_produto: {
					...last.info_produto,
					descricao: "",
					codigo: 0,
					tipo: "",

					altura: 0,
					comprimento: 0,
					largura: 0,
					peso: 0,
					volume: 0,
				},
			}));
			setActual({
				name: "",
				position: {
					x: 0,
					y: 0,
					z: 0,
				},
				geometry: {
					parameters: {
						width: 0,
						depth: 0,
						height: 0,
					},
				},
			});

			setLast({
				name: "",
				position: {
					x: 0,
					y: 0,
					z: 0,
				},
				geometry: {
					parameters: {
						width: 0,
						depth: 0,
						height: 0,
					},
				},
			});
			return;
		}

		const targetBox = boxes.filter((item) => item.name === target.name);
		let produto = {};
		let romaneio = {};
		if (target.tipo === "EMBALAGEM") {
			produto = produtosEEmbalagensA.find(
				(item) => item.id == Number(targetBox[0]?.id)
			);
			romaneio = selectRomaneio(produto?.numero_pedido);
		} else {
			produto = selectProduto(Number(targetBox[0]?.id));
			romaneio = selectRomaneio(produto?.numero_pedido);
		}

		setInformacoesCarga((last) => ({
			...last,
			info_produto: {
				...last.info_produto,
				descricao:
					produto?.descricao.length > 20
						? `${produto?.descricao.slice(0, 20)}... `
						: `${produto?.descricao}`,
				codigo: produto?.codigo || produto?.numero_embalagem,
				numero_pedido: romaneio?.numero_pedido || "NÃO ENCONTRADO",
				cliente:
					romaneio?.nome_razao?.length > 20
						? `${romaneio.nome_razao.slice(0, 20)}...`
						: romaneio?.nome_razao,
				romaneio: String(romaneio?.romaneio).padStart(7, 0),
				cidade: romaneio?.cidade || "NÃO ENCONTRADO",
				tipo: target.tipo,

				qtde: numerosEditadoBR(produto?.qtde || produto?.qtde_produtos || 0),
				altura: numerosEditadoBR(produto?.altura),
				comprimento: numerosEditadoBR(produto?.comprimento),
				largura: numerosEditadoBR(produto?.largura),
				peso: numerosEditadoBR(produto?.peso),
				volume: numerosEditadoBR(produto?.volume),
			},
		}));

		setActual((last) => ({ ...last, position: target.position }));
	}, [target]);

	return (
		<Fragment>
			{/* eixo central de ajuda do sistema */}
			<AxisHelper />
			<LightScene />
			{/* <Environment files="/estacao_de_onibus/hamburg_hbf_4k.hdr" ground={{ height: 32, radius: 130 }} /> */}
			{/* <Stage preset="rembrandt" intensity={0.2} environment="city"> */}

			{/* <Suspense fallback={<Loader />}>
                <LoadMesh />
            </Suspense> */}

			{/* </Stage> */}
			<Selection>
				{/* <EffectComposer multisampling={80} autoClear={false}>
                    <Outline blur visibleEdgeColor="white" edgeStrength={1000} width={5000} />
                </EffectComposer> */}
				{[...boxes]
					.sort((a, b) => {
						if (a.data_carregamento > b.data_carregamento) return 1;
						if (a.data_carregamento < b.data_carregamento) return -1;
						return 0;
					})
					.map((item, index) => (
						<WoodBox
							key={index}
							position={item.position}
							args={item.args}
							name={item.name}
							target={target}
							setTarget={setTarget}
							argsDesenho={argsDesenho}
							argsRealCaminhao={{
								a: caminhaoDB.caminhao_altura,
								c: caminhaoDB.caminhao_comprimento,
								l: caminhaoDB.caminhao_largura,
							}}
							mass={item.mass}
							actual={actual}
							last={last}
							tipo={item.tipo}
							setInformacoesCarga={setInformacoesCarga}
							cor_caixa={item.cor_caixa}
							index={boxes.length - 1 == index ? true : false}
						/>
					))}
			</Selection>
			{target ? (
				<Line
					lineStart={actual.position}
					lineEnd={last.position}
					lineStraight={distanceVector(actual.position, last.position)}
				/>
			) : (
				""
			)}

			{/* responsavel pela movimentação do ambiente */}

			<OrbitControls
				rotateSpeed={0.15}
				makeDefault
				maxDistance={50} /* distancia maxima do centro da eixo */
				minDistance={1} /* distancia minima do centro do eixo */

				// minPolarAngle={Math.PI/4}
				// maxPolarAngle={Math.PI/1.8} /* rotação maxima em relação ao eixo y expressa em radianos, 180*0.49 ~ 88° */

				// maxAzimuthAngle={Math.PI /1.5}
				// minAzimuthAngle={-Math.PI*2.1}
			/>
		</Fragment>
	);
}

export default function CarregarCaminhaoNew(props) {
	const boxes = useSelector((state) => state.cargaSlicer);
	const user = useSelector((state) => state.userSlice);
	const cargas_id = user.cargas_id;
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const embalagensCarregadas = useSelector(
		(state) => state.userSlice.embalagensList.data
	).filter((item) => item.carregado);
	const caminhaoDB = user.caminhaoSelecionado;
	const cargaSelecionada = user.cargaSelecionada;

	// const cena = memo(Cena)
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [target, setTarget] = useState(null);
	const [produtosList, setProdutosList] = useState(false);
	const [error, setError] = useState(false);
	const [errorP, setErrorP] = useState({
		flag: false,
		msg: "",
		error: "",
	});

	const [errorCargaAEntregar, setErrorCargaAEntregar] = useState({
		flag: false,
		msg: "",
		error: "",
	});
	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "dark",
		msg: "Finalizar Carregamento",
	});
	const [loadingRemover, setLoadingRemover] = useState({
		flag: false,
		style: "warning",
		msg: "Remover Produto",
	});
	const [atualizartPedido, setAtualizarPedido] = useState({
		pedido: {},
		flag: false,
	});

	const atualizarInformacoesPedido = async (pedido) => {
		const itensCarregadoPedido = produtosRomaneio.data.find(
			(item) =>
				item.carregado &&
				item.numero_pedido == atualizartPedido.pedido.numero_pedido
		);
		const status = itensCarregadoPedido ? 10 : 9;

		const itemsCarregadoCarga = produtosRomaneio.data.find(
			(item) => item.carregado
		);
		const status_carga = itemsCarregadoCarga ? 10 : 9;

		await updateTable("cargas/carregada/caminhoneiro", cargas_id, {
			status,
			pedidos: "" + atualizartPedido.pedido.numero_pedido,
			local: "carregamento",
			status_carga,
		});

		setAtualizarPedido({
			flag: false,
			pedido: {},
		});
	};

	const onRemove_old = async (e) => {
		e.preventDefault();
		if (!target) return;
		// let indexPlanejadoRemoved = null
		setLoadingRemover((last) => ({ ...last, flag: true, msg: "Removendo" }));
		const productId = boxes.listaProdutos.find(
			(item) => item.name === target.name
		)?.id;
		const produto_qr_sku = boxes.listaProdutos.find(
			(item) => item.name === target.name
		)?.qr_sku;
		let produto_selecionado = "";

		dispatch(updateSteps({ qr_sku: produto_qr_sku, concluido: false }));
		const objAtt = {
			// pos_x: null,
			// pos_y: null,
			// pos_z: null,
			user_carregamento: null,
			data_carregamento: null,
			carregado: false,
		};
		if (target.tipo === "EMBALAGEM") {
			const productId = boxes.listaProdutos.find(
				(item) => item.name === target.name
			)?.qr_sku;

			let produtoAtt = {
				carregado: false,
				cargas_id: cargas_id,
			};

			const embalagem = embalagensCarregadas.find(
				(item) => item.qr_sku === productId
			);
			produto_selecionado = embalagem;
			dispatch(
				updateEmbalagensListCarregados({ qr_sku: productId, carregado: false })
			);

			const produtosDaEmbalagem = produtosRomaneio.data.filter(
				(item) =>
					item.embalado &&
					Number(embalagem.numero_embalagem) === item.numero_embalagem
			);
			for await (let produto of produtosDaEmbalagem) {
				dispatch(
					updateProdutosListCarregados({
						qr_sku: produto.qr_sku,
						carregado: false,
						data: null,
						user: null,
					})
				);
			}

			await updateTable(
				"cargas/funcionarios/embalagens",
				embalagem.qr_sku,
				produtoAtt
			);
			await updateTable(
				"carga/caminhoneiro/produtos/carregados/embalagens",
				embalagem.numero_embalagem,
				{ carregado: false, user_carregamento: null, data_carregamento: null }
			);
		} else {
			produto_selecionado = produtosRomaneio.data.find(
				(item) => productId == item.id
			);
			await updateTable(
				"carga/caminhoneiro/produto/carregado",
				productId,
				objAtt
			)

			dispatch(
				updateProdutosListCarregados({
					qr_sku: produto_qr_sku,
					carregado: false,
				})
			);
		}

		setAtualizarPedido({
			flag: true,
			pedido: produto_selecionado,
		});
		// chamar função para verificar o status do pedido

		let newArray = [...boxes.listaProdutos];

		newArray = newArray /* .map(item => {
            if(item.step > indexPlanejadoRemoved){
                return ({...item, step: item.step - 1})
            }
            return item
        }) */
			.filter((item) => item.name !== target.name);

		dispatch(removeProduto(newArray));
		setTarget(null);

		setLoadingRemover((last) => ({
			...last,
			flag: false,
			msg: "Remover Produto",
		}));
	};

	const onAdd = (e) => {
		e.preventDefault();
		if (verificarCargaEmProcessoEntrega()) return;
		if (target) updateProdutoSelected(target);
		setProdutosList(true);
		setTarget(null);
	};

	const onClickFinalizarCarregamento = async (e) => {
		e.preventDefault();
		// forçamos a atualização do ultimo produto selecionado
		updateProdutoSelected(target);
		setTarget(null);

		const algumProdutoNaoCarregado = user.produtosList.data.find(
			(item) => !item.carregado
		);
		const produtosList = user.produtosList.data.filter(
			(item) => !item.embalado
		);
		const produtosCheckeds = produtosList.filter((item) => item?.carregado);
		const embalagesCheckeds = user.embalagensList.data.filter(
			(item) => item?.carregado
		);

		if (algumProdutoNaoCarregado) {
			setError((last) => ({
				...last,
				flag: true,
				msg: {
					title: "Produtos não Carregados",
					...user.produtosList.data
						.filter((item) => !item.carregado)
						.map((item) => ({
							pedido: `Numero do Pedido: ${item.numero_pedido}`,
							descricao: `Produto: ${item.codigo} - ${item.descricao}`,
							unidade: "Unidade: " + item.abreviatura,
							item_embalagem: `Embalado: ${
								item.embalado
									? `SIM -> N°${String(item.numero_embalagem).padStart(
											7,
											0
									  )} - Item: ${item.numero_item_embalagem}`
									: "NÃO"
							}`,
						})),
				},
				title: "Erro ao Finalizar Carregamento",
			}));
			return;
		}
		// if(!produtosCheckeds.length && !embalagesCheckeds.length){
		//     setError(last => ({
		//         ...last, flag:true, msg:"Nenhum produto carregados. Por favor, inicie o processo de carregamento!",title:'Erro ao Finalizar Carregamento'
		//     }))
		//     return
		// }
		if (
			produtosCheckeds.length !==
			produtosList.length /*   || (embalagesCheckeds.length !== user.embalagensList.data.length)  */
		) {
			setErrorP((last) => ({
				...last,
				flag: true,
				msg: "Existem produtos não carregados. Deseja salvar planejamento parcial?",
				title: "Atenção!",
			}));
			return;
		}
		// alert('addcasd')
		// return
		setLoadingFinalizar({
			flag: true,
			msg: "Finalizando",
			style: "warning",
		});

		await updateTable("cargas/carregada/caminhoneiro", cargas_id, {
			status: 11,
			status_carga: 11,
			pedidos: null,
			local: "carregamento",
		})
			.then((resp) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					msg: "Carga Totalmente Carregada!",
					style: "success",
				}));
				setTarget(() => {
					window.location.replace("/map");
				}, 500);
				// gerarManifestoArtos()
			})
			.catch((error) => {
				setLoadingFinalizar({
					flag: false,
					msg: "ERROR",
					style: "danger",
				});
			});
	};

	const updateProdutoSelected = (target) => {
		if (!target) return;
		const position = [target.position.x, target.position.y, target.position.z];
		const argsReal = [
			target.geometry.parameters.width,
			target.geometry.parameters.height,
			target.geometry.parameters.depth,
		];
		const positionCalculada = [
			position[0] - argsReal[0] / 2,
			position[1] - argsReal[1] / 2,
			position[2] - argsReal[2] / 2,
		];

		const item = {
			itemName: target.name,
			newPosition: positionCalculada,
		};
		dispatch(updateProduto(item));
	};

	const verificarCargaCarregamento = async () => {
		const produtosGet = await getData(
			"cargas/produtos/separador",
			cargas_id,
			{}
		);

		if (!produtosGet.data.length) return;
		const produtos = produtosGet.data.filter((item) => item.conferido);
		dispatch(updateProdutosList(produtos));
		updateArrayProdutosCarregados();
	};

	const cargaJaCarregada = () => {
		// const caminhaoPlaca = user.caminhaoSelecionado?.caminhao_placa

		if (user.cargaSelecionada.data_inicio_entrega) {
			setErrorCargaAEntregar((last) => ({
				...last,
				flag: true,
				msg: "Carregamento ja finalizado. Você esta sendo redirecionado...",
				title: "Aviso",
			}));
			setTimeout(() => {
				window.location.replace("/map");
			}, 1500);
		}
	};

	const updateArrayProdutosCarregados = async (carga_id = 1) => {
		if (boxes.listaProdutos.length) {
			return;
		}
		// const caminhaoPlaca = user.caminhaoSelecionado?.caminhao_placa
		// const getNextCarga = await getData('cargas/produtos/caminhao', caminhaoPlaca)
		// const cargaDisponivelCarregamento = getNextCarga.data.filter(item => item.status >= 6 && item.status <= 7 )[0]?.id //4, 5, 6
		const cargaDisponivelCarregamento = cargas_id;

		const produtosGet = await getData(
			"cargas/produtos/separador",
			cargaDisponivelCarregamento,
			{}
		);
		const embalagensGet = await getData(
			"cargas/funcionarios/embalagens/carga_id",
			cargaDisponivelCarregamento,
			{}
		);
		const embalagensPlanejadas = embalagensGet.data.filter(
			(item) => item.carregado
		);
		const produtosPlanejados = produtosGet.data.filter(
			(item) => item.planejado && !item.embalado && item.carregado
		);

		// return
		for (const produtos in produtosPlanejados) {
			const item = produtosPlanejados[produtos];
			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "produto",
				})
			);
		}
		for (const embalagems in embalagensPlanejadas) {
			const item = embalagensPlanejadas[embalagems];
			dispatch(
				addProduto({
					name: uuidv4(),
					position: [item.pos_z, item.pos_y, item.pos_x],
					args: [item.largura, item.altura, item.comprimento],
					mass: item.peso,
					id: item.id,
					qr_sku: item.qr_sku,
					carregado: item.carregado,
					descarregado: item.descarregado,
					step: item.step_planejado,
					tipo: "embalagem",
				})
			);
		}
		const carregados = produtosGet.data.filter((item) => item.conferido);
		const embalagensSeparadas = embalagensGet.data.filter(
			(item) => item.conferido
		);
		dispatch(updateProdutosList(carregados));
		dispatch(updateEmbalagensList(embalagensSeparadas));
		// setLoading(false)
	};

	useEffect(() => {
		if (!atualizartPedido.flag) return;
		atualizarInformacoesPedido(atualizartPedido);
	}, [atualizartPedido]);

	useEffect(() => {
		if (user.caminhaoSelecionado.status) {
			setLoading(false);
			return;
		}
		setTimeout(() => {
			getData("caminhao/caminhoneiro", user.id)
				.then((resp) => {
					const result = {};
					setLoading(false);

					result.status = true;
				})
				.catch((error) => {
					setLoading(false);
					dispatch(update({ authenticated: false }));
				});
		}, 1500);
	}, [user.caminhaoSelecionado.status /* hasPassed */]);

	useEffect(() => {
		cargaJaCarregada();
	}, []);

	// useEffect(() => {
	//     if(!user.caminhaoSelecionado.status) return
	//     verificarCargaCarregamento()
	// }, [user.caminhaoSelecionado])

	const [informacoesCarga, setInformacoesCarga] = useState({
		distancia_ao_caminhao: {
			tampa_traseira: 0,
			tampa_frontral: 0,
			acoalho: 0,
			teto: 0,
			tampa_lateral_direita: 0,
			tampa_lateral_esquerda: 0,
		},
		info_produto: {
			// romaneio
			romaneio: "",
			numero_pedido: "",
			cliente: "",
			cidade: "",
			descricao: 0,
			codigo: 0,
			tipo: "",

			qtde: 0,
			altura: 0,
			comprimento: 0,
			largura: 0,
			peso: 0,
			volume: 0,
		},
		distancia_ao_produto: {
			altura: 0,
			comprimento: 0,
			largura: 0,
		},
	});

	const updateArrayProdutos = async (carga_id = 1) => {
		if (
			produtosRomaneio.data.length > 0 &&
			(produtosRomaneio.data.filter((item) => !item.planejado).length ||
				produtosRomaneio.data.filter((item) => item.planejado).length ==
					produtosRomaneio.data.length)
		) {
			setLoading(false);
			return;
		}

		// rota destinda a funcionarios
		let produtosGet = await getData(
			"cargas/produtos/separador",
			props.cargas_id,
			{}
		);

		if (!produtosGet.data.length) {
			return;
		}

		const produtosPlanejados = produtosGet.data.filter(
			(item) => item.conferido
		);
		dispatch(updateProdutosList(produtosPlanejados));
	};
	/* protudos */
	useEffect(() => {
		updateArrayProdutos();
	}, []);

	const [modalSelecionarCargas, setModalSelecionarCargas] = useState({
		cargas: [],
		flag: false,
	});

	const [produtosEEmbalagens, setProdutosEEmbalagens] = useState([]);

	const verificarCargaEmProcessoEntrega = () => {
		if (cargaSelecionada.find((item) => item.data_inicio_entrega)) {
			setError({
				flag: true,
				list: [
					"msg: Impossivel carregar cargas com processo de entrega ja iniciado!",
				],
				title: "Erro",
			});
			return true;
		}
	};
	const verificaCargasDiponiveis = async () => {
		if (verificarCargaEmProcessoEntrega()) return;
		setModalSelecionarCargas({ flag: true, setProdutosEEmbalagens });
	};

	const [styledButton, setStyledButton] = useState(false);

	const carregarFn = async (produtoSelecionado, checked) => {
		const objAtt = {
			user_carregamento: !checked ? null : user.email,
			data_carregamento: !checked ? null : new Date().toISOString(),
			carregado: checked,
			qr_sku: produtoSelecionado.qr_sku,
			tipo: produtoSelecionado.tipo,
			numero_embalagem: produtoSelecionado.numero_embalagem,
		};
		dispatch(
			updateProdutosEEmbalagensBySku({
				parameter: "carregado",
				qr_sku: produtoSelecionado.qr_sku,
				carregado: true,
				data: new Date().toISOString(),
				user: objAtt.user_carregamento,
			})
		);
		try {
			const updated = await updateTable(
				"cargas/motorista/update-carregamento",
				produtoSelecionado.cargas_id,
				{ ...objAtt }
			);
			setError({
				title: "Aviso",
				flag: true,
				list: [
					...Object.keys(updated)
						.filter((item) => !["headers", "config", "request"].includes(item))
						.map((e) => `${e}: ${updated[e]}`),
				],
			});
			if (updated.data.includes("Carga Disponivel para Entrega")) {
				setTimeout(() => {
					window.location.replace("map");
				}, 2500);
			}
		} catch (error) {
			const err = error.response;
			setError({
				flag: true,
				list: Object.keys(err).map((e) => `${e}: ${err[e]}`),
			});
		}
	};
	const onRemove = async (e) => {
		e.preventDefault();
		if (verificarCargaEmProcessoEntrega()) return;
		if (!target) return;
		// let indexPlanejadoRemoved = null
		setLoadingRemover((last) => ({ ...last, flag: true, msg: "Removendo" }));
		const productId = boxes.listaProdutos.find(
			(item) => item.name === target.name
		)?.id;
		const produto_qr_sku = boxes.listaProdutos.find(
			(item) => item.name === target.name
		)?.qr_sku;
		let produto_selecionado = "";

		// dispatch(updateSteps({qr_sku: produto_qr_sku, concluido: false}))
		const objAtt = {
			// pos_x: null,
			// pos_y: null,
			// pos_z: null,
			user_carregamento: null,
			data_carregamento: null,
			carregado: false,
		};
		if (target.tipo === "EMBALAGEM") {
			const productId = boxes.listaProdutos.find(
				(item) => item.name === target.name
			)?.qr_sku;
			const embalagem = embalagensCarregadas.find(
				(item) => item.qr_sku === productId
			);
			produto_selecionado = embalagem;
			dispatch(
				updateEmbalagensListCarregados({ qr_sku: productId, carregado: false })
			);
		} else {
			produto_selecionado = produtosRomaneio.data.find(
				(item) => productId == item.id
			);
			dispatch(
				updateProdutosListCarregados({
					qr_sku: produto_qr_sku,
					carregado: false,
				})
			);
		}

		await carregarFn(produto_selecionado, false);

		let newArray = [...boxes.listaProdutos];

		newArray = newArray.filter((item) => item.name !== target.name);

		dispatch(removeProduto(newArray));
		setTarget(null);

		setLoadingRemover((last) => ({
			...last,
			flag: false,
			msg: "Remover Produto",
		}));
	};

	const finalizarCarregamento = async () => {
		setLoading({
			flag: true,
			msg: "Salvando",
			style: "warning",
		});
		try {
			const data = await Promise.all([
				...unicos(produtosEEmbalagens, "cargas_id").map((item) =>
					updateTable("cargas/motorista/update-pedidos", item.cargas_id, {
						status: 11,
					})
				),
			]);

			setLoading({
				flag: false,
				msg: "Salvo",
				style: "success",
			});

			setTimeout(() => {
				window.location.replace("/map");
			}, 500);
		} catch (error) {
			setError((last) => ({
				...last,
				flag: true,
				msg: {
					ERRO: "Erro na finalização do carregamento!!",
					...error.response.data.separado.map((item) => ({
						pedido: `Numero do Pedido: ${item.numero_pedido}`,
						descricao: `Produto: ${item.codigo} - ${item.descricao}`,
						unidade: "Unidade: " + item.abreviatura,
						separado: item.conferido ? "SEPARADO: SIM" : "SEPARADO: NÃO",
						carregado: item.carregado ? "CARREGADO: SIM" : "CARREGADO: NÃO",
						item_embalagem: `Embalado: ${
							item.embalado
								? `SIM -> N°${String(item.numero_embalagem).padStart(
										7,
										0
								  )} - Item: ${item.numero_item_embalagem}`
								: "NÃO"
						}`,
					})),
					...error.response.data.carregado.map((item) => ({
						pedido: `Numero do Pedido: ${item.numero_pedido}`,
						descricao: `Produto: ${item.codigo} - ${item.descricao}`,
						unidade: "Unidade: " + item.abreviatura,
						separado: item.conferido ? "SEPARADO: SIM" : "SEPARADO: NÃO",
						carregado: item.carregado ? "CARREGADO: SIM" : "CARREGADO: NÃO",
						item_embalagem: `Embalado: ${
							item.embalado
								? `SIM -> N°${String(item.numero_embalagem).padStart(
										7,
										0
								  )} - Item: ${item.numero_item_embalagem}`
								: "NÃO"
						}`,
					})),
				},
				title: "Erro ao Finalizar Carregamento",
			}));
			setLoading({
				flag: false,
				msg: "Finalizar",
				style: "dark",
			});
		}
	};
	return (
		<Fragment>
			{loading ? <Loading msg={"Carregando"} color={"black"} /> : ""}
			<div className="pb-1">
				<Row className="justify-content-between">
					<Col sm="12" md="6" lg="8" className="mb-1  text-sm-center ">
						<h3 style={{ display: "flex", alignItems: "center" }}>
							Produtos da Carga
						</h3>
					</Col>
					<Col sm="12" md="6" lg="2" className="mb-1">
						<Button
							variant={"primary"}
							onClick={() => verificaCargasDiponiveis()}
						>
							Selecionar Cargas
						</Button>
					</Col>
				</Row>
			</div>
			{!user?.cargaSelecionada.length ? <h2>Selecione alguma carga</h2> : ""}
			{user?.cargaSelecionada?.find(
				(item) => item.regiao_entrega === "LOCAL"
			) ? (
				<CarregarCaminhaoCargaLocal />
			) : (
				""
			)}
			{user?.cargaSelecionada?.find(
				(item) => item.regiao_entrega !== "LOCAL"
			) ? (
				!user.caminhaoSelecionado.status ? (
					<div>
						<h2>Caminhão Não Disponibilizado pela Empresa!</h2>
					</div>
				) : (
					<div className="carregamento">
						<Canvas
							shadows
							style={{ background: "#0CABA8" }}
							orthographic
							camera={{ zoom: 50, position: [100, 100, 100] }}
							onPointerMissed={() => {
								if (!target) return;
								updateProdutoSelected(target);
								setTarget(null);
							}}
						>
							<Suspense fallback={<Loader />}>
								<Caminhao />
								<Cena
									target={target}
									setTarget={setTarget}
									boxes={boxes.listaProdutos}
									setBoxes={updateProdutoSelected}
									setInformacoesCarga={setInformacoesCarga}
								/>
							</Suspense>
						</Canvas>
						<div className="info-products">
							<pre>
								<div className="mb-2">
									<Button variant="primary" onClick={onAdd}>
										Adicionar Produto
									</Button>
								</div>
								<div className="mb-2">
									{/* <Button variant='warning' onClick={onRemove}>Remover Produto</Button> */}
									<Button variant={loadingRemover.style} onClick={onRemove}>
										{
											<Fragment>
												{loadingRemover.msg}{" "}
												{loadingRemover.flag ? (
													<Spinner
														animation="border"
														variant="black"
														size="sm"
													/>
												) : (
													""
												)}
											</Fragment>
										}
									</Button>
								</div>
								{/*  <div className="mb-2">
                                    <Button variant={loadingFinalizar.style} onClick={onClickFinalizarCarregamento}>{
                                        <Fragment>{loadingFinalizar.msg} {loadingFinalizar.flag ?<Spinner animation="border" variant="black" size='sm' /> : ""}</Fragment>
                                    }</Button>
                                    </div> */}
							</pre>
						</div>
						<div
							className={`info-products info-carga ${
								styledButton ? "hide-card" : ""
							}`}
						>
							<pre>
								<div className="d-flex justify-content-between align-items-center">
									<h4 className="m-0">Informações da Carga </h4>
									<i
										className={`i bi-arrow-up-circle-fill rotateButton ${
											styledButton ? "rotate-up" : ""
										}`}
										onClick={(e) => setStyledButton(!styledButton)}
									></i>
								</div>
								<h6 className="card-header">Produto Selecionado</h6>
								<ul>
									<li>
										Romaneio: <b>{informacoesCarga.info_produto.romaneio}</b>
									</li>
									<li>
										Pedido: <b>{informacoesCarga.info_produto.numero_pedido}</b>
									</li>
									<li>
										Cliente: <b>{informacoesCarga.info_produto.cliente}</b>
									</li>
									<li>
										Cidade: <b>{informacoesCarga.info_produto.cidade}</b>
									</li>
									<li>
										Descrição: <b>{informacoesCarga.info_produto.descricao}</b>
									</li>
									<li>
										Tipo: <b>{informacoesCarga.info_produto.tipo}</b>
									</li>
									<li>
										Código: <b>{informacoesCarga.info_produto.codigo}</b>
									</li>
									<li>
										Quantidade: <b>{informacoesCarga.info_produto.qtde}</b>
									</li>
									<li>
										Altura: <b>{informacoesCarga.info_produto.altura} [ m ]</b>
									</li>
									<li>
										Comprimento:{" "}
										<b>{informacoesCarga.info_produto.comprimento}</b> [ m ]
									</li>
									<li>
										Largura:{" "}
										<b>{informacoesCarga.info_produto.largura} [ m ]</b>
									</li>
									<li>
										Peso: <b>{informacoesCarga.info_produto.peso}</b> [ kg ]
									</li>
									<li>
										Volume:{" "}
										<b>
											{informacoesCarga.info_produto.volume} [ m<sup>3</sup> ]
										</b>
									</li>
								</ul>
								<h6 className="card-header">
									Distância do Produto ao Caminhão
								</h6>
								<ul>
									<li>
										TAMPA TRASEIRA:{" "}
										<b>
											{informacoesCarga.distancia_ao_caminhao.tampa_traseira}
										</b>{" "}
										[ m ]
										<InformacaoParametro
											title={
												"Distância entre a face TRASEIRA do ITEM e a FACE TRASEIRA interna do CAMINHÃO."
											}
										/>
									</li>
									<li>
										TAMPA FRONTAL:{" "}
										<b>
											{informacoesCarga.distancia_ao_caminhao.tampa_frontral}
										</b>{" "}
										[ m ]
										<InformacaoParametro
											title={
												"Distância entre a face FRONTAL do ITEM e a FACE FRONTAL interna do CAMINHÃO."
											}
										/>
									</li>
									<li>
										TETO: <b>{informacoesCarga.distancia_ao_caminhao.teto}</b> [
										m ]
										<InformacaoParametro
											title={
												"Distância entre a face SUPERIOR do ITEM e a FACE SUPERIOR interna do CAMINHÃO."
											}
										/>
									</li>
									<li>
										ASSOALHO:{" "}
										<b>{informacoesCarga.distancia_ao_caminhao.acoalho}</b> [ m
										]
										<InformacaoParametro
											title={
												"Distância entre a face INFERIOR do ITEM e o ASSOALHO do CAMINHÃO."
											}
										/>
									</li>
									<li>
										TAMPA LATERAL ESQUERDA:{" "}
										<b>
											{
												informacoesCarga.distancia_ao_caminhao
													.tampa_lateral_esquerda
											}
										</b>{" "}
										[ m ]
										<InformacaoParametro
											title={
												"Distância entre a face LATERAL ESQUERDA do ITEM e a face LATERAL ESQUERDA do CAMINHÃO."
											}
										/>
									</li>
									<li>
										TAMPA LATERAL DIREITA:{" "}
										<b>
											{
												informacoesCarga.distancia_ao_caminhao
													.tampa_lateral_direita
											}
										</b>{" "}
										[ m ]
										<InformacaoParametro
											title={
												"Distância entre a face LATERAL DIREITA do ITEM e a face LATERAL DIREITA do CAMINHÃO."
											}
										/>
									</li>
								</ul>
								<h6 className="card-header">
									Distância do Produto ao Produto Anterior
								</h6>
								<ul>
									<li>
										ALTURA:{" "}
										<b>{informacoesCarga.distancia_ao_produto.altura}</b> [ m ]
										{/* <InformacaoParametro title={"Distância entre a face TRASEIRA do ITEM e a FACE TRASEIRA interna do CAMINHÃO."}/> */}
									</li>
									<li>
										COMPRIMENTO:{" "}
										<b>{informacoesCarga.distancia_ao_produto.comprimento}</b> [
										m ]
										{/* <InformacaoParametro title={"Distância entre a face FRONTAL do ITEM e a FACE FRONTAL interna do CAMINHÃO."}/> */}
									</li>
									<li>
										LARGURA:{" "}
										<b>{informacoesCarga.distancia_ao_produto.largura}</b> [ m ]
										{/* <InformacaoParametro title={"Distância entre a face SUPERIOR do ITEM e a FACE SUPERIOR interna do CAMINHÃO."}/> */}
									</li>
								</ul>

								<h6 className="card-header">Caminhão</h6>
								<ul>
									<li>
										PLACA: <b>{caminhaoDB.caminhao_placa}</b>
									</li>
									<li>
										CARGA: <b>{props.carga_id}</b>
									</li>
									<li>
										ROMANEIOS:{" "}
										<b>
											{
												user.produtosList.data.filter(
													(i, n, s) =>
														s.findIndex(
															(o) => o.numero_pedido === i.numero_pedido
														) === n
												).length
											}
										</b>
									</li>
									<li>
										PRODUTOS: <b>{user.produtosList.data.length}</b>
									</li>
									<li>
										EMBALAGENS: <b>{user.embalagensList.data.length}</b>
									</li>
									<li title="Produtos + Embalagens Carregadas no Caminhão">
										VOLUME CARREGADO:
										<b>
											{Number(
												user.produtosList.data
													.filter((item) => !item.embalado && item.planejado)
													.reduce((p, c) => p + c.volume, 0) +
													user.embalagensList.data
														.filter((item) => item.planejado)
														.reduce((p, c) => p + c.volume, 0)
											).toLocaleString("pr-BR")}{" "}
											[ m<sup>3</sup> ]
										</b>
									</li>
									<li title="Produtos + Embalagens Carregadas no Caminhão">
										PESO CARREGADO:
										<b>
											{Number(
												user.produtosList.data
													.filter((item) => !item.embalado && item.planejado)
													.reduce((p, c) => p + c.peso, 0) +
													user.embalagensList.data
														.filter((item) => item.planejado)
														.reduce((p, c) => p + c.peso, 0)
											).toLocaleString("pr-BR")}{" "}
											[ kg ]
										</b>
									</li>
								</ul>
							</pre>
						</div>
					</div>
				)
			) : (
				""
			)}

			{produtosList ? (
				<ModalGetCargaCarregamento
					show={produtosList}
					setShow={setProdutosList}
					setError={setError}
					placaCaminhao={props?.caminhao_placa}
					fnRetorno={carregarFn}
					produtosEEmbalagens={produtosEEmbalagens}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError show={error.flag} setShow={setError} {...error} />
			) : (
				""
			)}
			{errorP.flag ? (
				<ModalErrorCarregamento_old
					msg={errorP.msg}
					title={errorP.title}
					show={errorP.flag}
					setShow={setErrorP}
				/>
			) : (
				""
			)}
			{errorCargaAEntregar.flag ? (
				<ModalError
					msg={errorCargaAEntregar.msg}
					title={errorCargaAEntregar.title}
					show={errorCargaAEntregar.flag}
					setShow={setErrorCargaAEntregar}
				/>
			) : (
				""
			)}
			{modalSelecionarCargas.flag ? (
				<ModalSelectCargas
					show={modalSelecionarCargas.flag}
					setShow={setModalSelecionarCargas}
					{...modalSelecionarCargas}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

export const InformacaoParametro = (props) => {
	return (
		<i
			className="bi bi-info-circle"
			title={props.title}
			style={{ fontSize: "0.8rem" }}
		></i>
	);
};

/* memo function sereve para memorizar o ultimo estado do componente */
// export default memo(Carregamento)
