import {v4 as uuid} from 'uuid'

export function filtroDinamicoArrays(filters, key, array) {
    let arrayFiltrada = []
    if (!array || !key) return []
    if (['idFilial','id_filial','filial','id_filial_destino'].includes(key)) { 
        const idFilial = filters[key].map(item => item.split('-')[0]?.trim())
        arrayFiltrada.push(...array.filter(pedido => idFilial.indexOf(`${pedido[key]}`) >= 0))
    } else if (key === 'caminhoneiro') { 
        const funcionario = filters[key].map(item => item.split(' - ')[0]?.trim())
        arrayFiltrada.push(...array.filter(pedido => pedido?.caminhoneiro?.includes(funcionario)))
    } else{ 
        arrayFiltrada.push(...array.filter(pedido => {
            return filters[key].indexOf(`${pedido[key]}`.toLowerCase()) >= 0
        }))
    }
    return arrayFiltrada
}

export const removerDadosDuplicados = (arr, key, key2) => arr.filter((item, index, self) => self.findIndex(obj => obj[key] === item[key] && obj[key2] === item[key2]) === index)
export const removerDadosDuplicadosold = (arr, key) => arr.filter((item, index, self) => self.findIndex(obj => obj[key] === item[key]) === index)

export const sortedArrByDate = (arr, key) =>
    arr
        .sort((a, b) => {
            if(new Date(a[key]).getTime() > new Date(b[key]).getTime())  return 1
            if(new Date(a[key]).getTime() < new Date(b[key]).getTime())  return -1
            return 0
        })
        .reverse()


export const telefoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,'($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
}

export const returnDataWithCorrection = (date) => {
    const horas = new Date(date)
    horas.setHours(horas.getHours() - horas.getTimezoneOffset()/60)
    return horas
}

export const getDateUTC = (date) =>  new Date(date).toLocaleDateString('pt-br', { timeZone: 'UTC' })

export const unicos = (array, params, params2) => array.filter((i, n, s) => s.findIndex(o => o[params] === i[params] && o[params2] === i[params2]) === n)

export const getErrorMessage = (errorObj) => {
	if (errorObj.message == "Network Error") return "Servidor não respondeu como esperado. Contate o suporte";
	return (
		errorObj?.response?.data?.error?.message ||
		errorObj?.response?.data?.description ||
		errorObj?.response?.data?.message ||
		errorObj?.response?.data?.msg ||
		errorObj?.response?.data ||
		errorObj.message
	);
};

export const getSuccessMessage = (response) => {
	if (response.message == "Network Error") return "Servidor não respondeu como esperado. Contate o suporte";
	return (
		response?.data?.message ||
		response?.data?.msg ||
		response?.data ||
		response.statusText
	);
};

export function getNewId(){
    return uuid()
}

export function getPrettierNumber(num) {
    return  Number(num).toLocaleString("pt-br", { maximumFractionDigits: 2, minimumFractionDigits: 2,})
}

export function getFilialName(arrayFiliais, id_filial) {
    if(!id_filial) return 'N/A'
    return arrayFiliais.find(i => i.idFilial === id_filial)?.identificacaoInterna || id_filial
}
export const getColorOfFilial = (codigoFilial) => {
    
   	return [
		{filial: '2', color:'#ffffff', bgColor:'#0A061E'},
		{filial: '3', color:'#ffffff', bgColor:'#00DB48'},
		{filial: '4', color:'#000000F0', bgColor:'#00DADB'},
		{filial: '5', color:'#ffffff', bgColor:'#03DB00'},
		{filial: '6', color:'#ffffff', bgColor:'#0095DB'},
		{filial: '7', color:'#ffffff', bgColor:'#E58102'},
		{filial: '8', color:'#ffffff', bgColor:'#6100E6'},
		{filial: '9', color:'#ffffff', bgColor:'#5E3B8F'},
		{filial: '12', color:'#ffffff', bgColor:'#3B6646'},
		{filial: '13', color:'#ffffff', bgColor:'#3B0046'},
		{filial: '16', color:'#ffffff', bgColor:'#E028B4'},
		{filial: '17', color:'#ffffff', bgColor:'#4A6160'},
		{filial: '20', color:'#ffffff', bgColor:'#8A517D'},
	]
    .find((o) => o.filial.toLocaleLowerCase().includes(String(codigoFilial).toLowerCase())) || {filial: '2', color:'#fff', bgColor:'#000'}
}
export const getColorOfManifestoStatus = (status) => {
   	return [
        {status: 'aprovado', color:'#000000', bgColor:'#03DB00'},
		{status: 'reprovado', color:'#ffffff', bgColor:'#e5024f'},
		{status: 'encerrado', color:'#ffffff', bgColor:'#5E3B8F'},
		{status: 'cancelado', color:'#ffffff', bgColor:'#000'},
	]
    .find((o) => o.status.toLowerCase() == status.toLowerCase()) || {filial: 'N/A', color:'#fff', bgColor:'#000'}
}
export const getColorOfStatusBool = (status) => {
   	return [
            {status: '0', color:'#ffffff', bgColor:'#ee1313'},
            {status: '1', color:'#000000', bgColor:'#03DB00'},
            {status: '2', color:'#000000', bgColor:'#ecee13fa'},
	]
    .find((o) => o.status.toLowerCase() == status.toLowerCase()) || {color:'#fff', bgColor:'#000'}
}

export const isSuporte = (email) => email.toUpperCase() === process.env.REACT_APP_EMAIL_SUPORTE