import { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	compareDates,
	getAllData,
	getDateInit,
	getTimesAgo,
	numerosEditadoBR,
} from "../../../services/Servicos";
import Chart from "react-apexcharts";
import { Card, Form, Row, Spinner, Table } from "react-bootstrap";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import { getFilialName, getNewId } from "../../../services/helpers";


const tableRef = createRef();
const tbodyRef = createRef();

export default function GraficoDateLead({
	title,
	arrayData = [],
	dataInicial
}) {
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data
	const [loading, setLoading] = useState(false);
	const [head, setHead] = useState([]);
	const [arrayFiltered, setArraFiltered] = useState([]);
	const [arrayFull, setArrayFull] = useState([]);
	const [filialSelected, setFilialSelected] = useState(``);
	const [zoom, setZoom] = useState(1);
	const [indexList, setIndexList] = useState(30);

	useEffect(() => {
		setLoading(true)
		if(!dataInicial) return
		const dataI = new Date(dataInicial)
		const [dias] = getTimesAgo(dataI).split(' ')
		let arrayDias = []
		let i = 0
		while (i <= dias) {
			dataI.setDate(dataI.getDate() + 1)
			arrayDias.push({
				label: dataI.toLocaleDateString('pt-BR', {weekday: "long", day:'2-digit', month:'2-digit'}),
				value: dataI.getTime()
			})
			i++
		}
		setHead(arrayDias)
		setLoading(false)
		setArrayFull(arrayData)
	}, [arrayData])
	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;

			if (scrollPosition + (1000 * zoom)  >= (tbodyHeight - divTableHeight) * zoom) {
				setTimeout(() => {
					setIndexList(indexList + 30);
				}, 500);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const handlerChangeFilial = (e) => {
		e.preventDefault()
		if(e.target.value != 'all'){
			const filtered = arrayFull.filter(i => i.id_filial == e.target.value)
			setArraFiltered(filtered)
		}else{
			setArraFiltered([])	
		}
		setFilialSelected(e.target.value)
	}

	const handlerControlZoom = (action) => {
		if(zoom < 0.6 && action == '-') return
		switch (action) {
			case '+':
				setZoom(zoom + 0.1)
				break
			case '-':
				setZoom(zoom - 0.1)
				break
		}
	}
	if(arrayFiltered.length) {
		arrayData = arrayFiltered
	}
	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
			</Card.Title>
			<Card.Body className="p-0">
				<h4>{arrayData.length ? `Total de Cargas: ${arrayData.length}`: ``}</h4>
			<ScrollbarLeft
					tableRef={tableRef}
					coluna2={2}
					maxima_distancia={50}
				/>
				<div className={`d-flex p-2 justify-content-between align-items-center`} style={{ columnGap: '15px' }}>
					<div className="d-flex p-2">
							{[
								{ lable: 'Criação', classN: 'bg-info' },
								{ lable: 'Separação', classN: 'bg-warning' },
								{ lable: 'Carregamento', classN: 'bg-dark' },
								{ lable: 'Inicio da Entrega', classN: 'bg-danger' },
								{ lable: 'Finalização da Entrega', classN: 'bg-success' },
							].map(item =>
								<div key={getNewId()} className="d-flex" style={{columnGap:'2px'}}>
									<div className={`${item.classN}`} style={{ width: '20px', height: '20px', borderRadius: '50%' }}></div>
									<span>{ item.lable }</span>
								</div>

						)}
					</div>
					<div className="zoom-controler d-flex gap-2 align-items-end">
						<div className="zoom-controler d-flex gap-2">
							<Form.Group>
								<Form.Select onChange={handlerChangeFilial} value={filialSelected}>
									<option value={`all`}>TODAS FILIAIS</option>
									{ filiaisList
										.map(i => 
											arrayFull.find(f => f.id_filial == i.idFilial) ?
											<option key={getNewId()} value={`${i.idFilial}`}>
												{i.identificacaoInterna}
											</option>
											: ''
										)
									}
								</Form.Select>

							</Form.Group>
						</div>
						<div style={{cursor: 'pointer'}} onClick={() => handlerControlZoom('+')}><i className="bi bi-zoom-in"></i></div>
						<div>{ Number(zoom * 100).toFixed(0) }%</div>
						<div style={{cursor: 'pointer'}} onClick={() => handlerControlZoom('-')}><i className="bi bi-zoom-out"></i></div>
					</div>
				</div>
				<div ref={tableRef} className=" responsive-table tes" style={{ position: "relative", height:600, maxHeight: "unset" }}>
					<Table style={{ zoom }}>
						<thead style={{zIndex:'12'}}>
							<tr>
								<th>#</th>
								<th>Carga</th>
								<th>Nome</th>
								{head.map(i => <th key={getNewId()}>{i.label}</th>)}
							</tr>
						</thead>
						<tbody ref={tbodyRef}>
							{arrayData?.map((i, index) => {
								if(index > indexList) return
								const data_emissao = {
									label:  new Date(i.emissao).toLocaleString(),
									value: new Date(i.emissao).getTime()
								}
								const data_separacao = {
									label:  new Date(i.data_separacao).toLocaleString(''),
									value: new Date(i.data_separacao).getTime()
								}
								const data_carregamento = {
									label:  new Date(i.data_carregamento).toLocaleString(''),
									value: new Date(i.data_carregamento).getTime()
								}
								const data_inicio_entrega = {
									label:  new Date(i.data_inicio_entrega).toLocaleString(''),
									value: new Date(i.data_inicio_entrega).getTime()
								}
								const data_chegada = {
									label: new Date(i.data_chegada).toLocaleString(''),
									value: new Date(i.data_chegada).getTime()
								}

								let color = 'lightblue'
								let classPuted = []
								return <tr key={getNewId()}>
									<td >{index + 1}</td>
									<td style={{whiteSpace: "nowrap"}}>{i.id} - { getFilialName(user.filiaisList.data, i.id_filial) }</td>
									<td style={{whiteSpace: "nowrap"}}>{i.nome}</td>
									{head?.map(i2 => {
										let classList = []
										if (compareDates(data_emissao.value, i2.value) == 0 ) {
											classList.push({ step: 1, color: 'bg-info', title: `Data de Criação: ${data_emissao.label}` })
											classPuted.push({ step: 1, color: 'bg-info', title: `Data de Criação: ${data_emissao.label}` })
										}
										if (compareDates(data_separacao.value, i2.value) == 0) {
											classList.push({ step: 2, color: 'bg-warning', title: `Data de Separação: ${data_separacao.label}`  })
											classPuted.push({ step: 2, color: 'bg-warning', title: `Data de Separação: ${data_separacao.label}` })
										}
										if (compareDates(data_carregamento.value, i2.value) == 0) {
											classList.push({ step: 3, color: 'bg-dark', title: `Data do Carregamento: ${data_carregamento.label}` })
											classPuted.push({ step: 3, color: 'bg-dark', title: `Data do Carregamento: ${data_carregamento.label}` })
										}
										if (compareDates(data_inicio_entrega.value, i2.value) == 0) {
											classList.push({ step: 4, color: 'bg-danger', title: `Data de Inicio da Entrega: ${data_inicio_entrega.label}`  })
											classPuted.push({ step: 4, color: 'bg-danger', title: `Data de Inicio da Entrega: ${data_inicio_entrega.label}` })
										}
										if (compareDates(data_chegada.value, i2.value) == 0) {
											classList.push({
												step: 5,
												color: 'bg-success',
												title: 
													<div className="d-flex flex-column gap-2">
														<div>Data da Chegada: {data_chegada.label}</div>
														<div>Dias totais: {compareDates(data_emissao.value, data_chegada.value)}</div>
														{i.data_inicio_entrega ?
															<div>Dias na entrega: {compareDates(data_inicio_entrega.value, data_chegada.value)}</div>
															: ""
														}
													</div>
											})
											classPuted.push({
												step: 5,
												color: 'bg-success',
												title: `Data da Chegada: ${data_chegada.label} \n Dias totais: ${compareDates(data_emissao.value, data_chegada.value)}`
											})
										}
										if (!classList.length && classPuted.length) {
											const lastClass = classPuted[classPuted.length - 1]
											if (lastClass.step != 5) {
												classList.push(classPuted[classPuted.length - 1])
											} 
										}

										return <td key={getNewId()} style={{
											position: 'relative',
											backgroundColor: i2.label.includes('domingo') || i2.label.includes('sábado') ? '#fffce0' : '',
											border: 'none',
										}}>
											<div className={`d-flex p-2`} style={{ columnGap:'2px'}}>
												{classList.map((item, index) => <div key={getNewId()} className={ `${item.color} date-lead-graph`} style={{
													position: 'absolute',
													left: 0 + (index * 20),
													width: item.step =='5' ? '120%' : '110%',
													height: '20px',
													borderRadius: '11px 0 0 11px',
													borderTopRightRadius: item.step == '5' ? '11px' : 0,
													borderBottomRightRadius: item.step == '5' ? '11px' : 0,
													zIndex:5
												}}>
													<div className="popover">{item.title}</div>

												</div>)}
											</div>
										</td>
									})}
								</tr>
							}
							)}
						</tbody>
					</Table>
				</div>
			</Card.Body>
		</Card>
	);
}
