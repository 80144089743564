import { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { apenasNumeros, createAbastecimento, getStatusCarga, numeroDecimais, numerosDecimal } from "../../services/Servicos"
import Loading from "../loading/Loading"

import './Modal.scss'
import ModalError from "./ModalError"
import { getErrorMessage } from "../../services/helpers"
import ModalErrorPretty from "./ModalErrorPretty"


export default function ModalLancarAbastecimentoChegada(props) {
    const caminhoesList = useSelector(state => state.userSlice.caminhoesList.data).filter(item => item.status && props.caminhao === item.placa)
    const cargas = useSelector(state => state.userSlice.cargasList.data).filter(item => item.id === props.cargas_id)
    const user = useSelector(state => state.userSlice)
    const [loading, setLoading] = useState({
        isLoading: false,
        msg: "Salvar",
        style: 'primary'
    })

    const [body, setBody] = useState({
        cargas_id_chegada: props.cargas_id,
        caminhao: props.caminhao,
        abastecimentoChegada: true,
        tipo_maquina: 'CAMINHAO',
        chegada_saida:'CHEGADA',
        abastecedor: user.name
    })
    const [error, setError] = useState({
        show: false,
        message: ""
    })

    const handlerSimpleClose = () => props.setShow(last => ({...last, flag: false }))

    const submitDataEdit = () => {
        if (!body.horimetro) {
            setError({
                show: true,
                message: 'Foto do horimetro não informada'
            })
            return
        }
        setLoading({
                isLoading: true,
                msg: 'Enviando',
                style:'warning'
        })

        let bodySend = {
            data_abastecimento: new Date().toISOString(),
            emissao: new Date().toISOString()
        }
        Object.keys(body).map(item => {
            if (item == 'horimetro') {
                bodySend[item]  = body[item]
                return
            }
            bodySend[item] = String(body[item]).replace('.', '').replace(',', '.').toUpperCase()
        })
        
        
        createAbastecimento(bodySend, user.tipo)
            .then(response => {
                setLoading({
                    isLoading: false,
                    msg: 'Enviado',
                    style:'success'
                })
                props.setAtualizarDespesa({ flag: true })
                setTimeout(() => {
                    handlerSimpleClose()
                }, 700)
            })
            .catch(error => {
                setLoading({
                    isLoading: false,
                    msg: 'Error',
                    style:'danger'
                })

                setError({
                    show: true,
                    message: getErrorMessage(error)
                })

                setTimeout(() => {
                    setLoading({
                        isLoading: false,
                        msg: 'Enviar',
                        style:'primary'
                    })
    
                },1000)
            })
        
    }

    const onChangeInput = (e) => {
        setBody(last => ({
            ...last,
            [e.target.name]: e.target.value
        }))
    }

    const editValue = (e) => {
        setBody(last => ({ ...last, [e.target.name]: numeroDecimais(apenasNumeros(e.target.value)) }))
    }

    const onChangeInputFile = async (e) => {
        e.preventDefault()
        
        const imageFile = e.target.files[0];

        setBody(last => ({
            ...last
            , horimetro: imageFile
        }))
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Lançar Abastecimento de Chegada <br></br>Carga N° {props.cargas_id} - {props.nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Tipo:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    name='caminhao'
                                    value='Caminhão'
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Caminhão:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Select type="text"
                                    name='caminhao'
                                    disabled
                                >
                                    {
                                        caminhoesList.map((i, k) => <option value={i.placa} key={k} defaultValue={i.placa}>{ i.tipo} - {i.placa}</option>
                                        )
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Tipo de Combustível:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Select type="text"
                                    name='tipo_combustivel'
                                    onChange={onChangeInput}
                                >
                                    <option value=''></option>
                                    <option value='diesel'>Diesel</option>
                                    <option value='gasolina'>Gasolina</option>
                                    <option value='outros'>Outros</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Carga:</Form.Label>
                            </Col>
                            <Col>
                            <Col>
                                <Form.Control type='text' disabled value={`Carga N° ${props.cargas_id} - ${props.nome}`} />
                            </Col>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Litros:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type='text' onChange={editValue} value={body?.litros_abastecidos} name='litros_abastecidos'/>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Último Km:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type='text' onChange={editValue} value={body?.ultimo_km} name='ultimo_km'/>
                            </Col>
                        </Row>
                        
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Valor Combustivel (L):</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type='text' onChange={editValue} value={body?.valor_combustivel} name='valor_combustivel'/>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm='4'>
                                <Form.Label>Abastecedor:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type='text' onChange={onChangeInput} value={body?.abastecedor} name='abastecedor'/>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col sm='4'>
                                <Form.Label>Horimetro:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control 
                                    type='file' 
                                    name='teste'
                                    onChange={onChangeInputFile}
                                    // capture='environment'
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width:'100%'}}>
                        <Col>
                            <Button variant={loading.style} onClick={submitDataEdit}
                                disabled={(loading.style == 'primary' ? false : true) || (body?.caminhao?.length && body?.litros_abastecidos?.length && body?.ultimo_km?.length ? false : true)}
                            >
                                {
                                    loading.isLoading ? <Loading msg={loading.msg} /> : loading.msg
                                }
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='secondary' onClick={handlerSimpleClose}>Cancelar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
                {
                    error ? <ModalErrorPretty setShow={setError} {...error}/>:""
                }
            </Modal>
        </>
    )
}
