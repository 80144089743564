import {
	Fragment,
	createRef,
	useEffect,
	useLayoutEffect,
	useState,
} from "react";
import {
	Button,
	Card,
	Col,
	Form,
	Row,
	Spinner,
	Table,
	Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	updateCargasList,
	updateEmbalagensList,
	updateProdutosList,
	updateRomaneiosList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getData,
	numeroDecimais,
	updateTable,
} from "../../../services/Servicos";
import { SeparacaoLayout } from "../Separacao";
import { useNavigate } from "react-router-dom";
import ModalError from "../../modal/ModalError";
import ModalEmbalarProdutos from "../../modal/ModalEmbalarProdutos";
import ModalLoading from "../../modal/ModalLoading";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import ModalSepararProdutos from "../../modal/ModalSepararProdutos";
import BodyRowProdutosTransferencias from "../../table/body/BodyRowProdutosTransferencias";
import ModalEmbalarProdutosTransferencia from "../../modal/ModalEmbalarProdutosTransferencia";
import BodyRowProdutosFrete from "../../table/body/BodyRowProdutosFrete";
import ModalEmbalarProdutosFrete from "../../modal/ModalEmbalarProdutosFrete";
import ModalPDF_AssinarDanfe from "../../modal/ModalPDF_AssinarDanfe";
import ModalFinalizarTransferenciaOpcoes from "../../modal/ModalFinalizarTransferenciaOpcoes";
import BodyRowTabelaSeparacao from "../../table/body/BodyRowTabelaSeparacao";
import { getErrorMessage, getSuccessMessage } from "../../../services/helpers";
import ModalSuccessPretty from "../../modal/ModalSuccessPretty";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const headListCargasFrete = [
	"index",
	"admin",
	"tipo",
	"conferido",
	"carregado",
	"numero_pedido",
	"codigo",
	"descricao",
	"qtde",
	"peso",
	"abreviatura",
	"qr_sku",
];
const headListProdutos = [
	"index",
	"admin",
	"tipo",
	"conferido",
	"numero_pedido",
	"codigo/embalagem",
	"descricao",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"abreviatura",
	"qr_sku",
];
const headListTransferencias = [
	"index",
	"admin",
	"tipo",
	"conferido",
	"numero_pedido",
	"codigo",
	"descricao",
	"qtde_planejada",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso_unitario",
	"peso",
	"unidade",
	"qr_sku",
];
const headListEmbalagens = [
	"index",
	"admin",
	"conferido",
	"numero_pedido",
	"qtde_produtos",
	"descricao",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"qr_sku",
	"id",
	"cargas_id",
];
const headListEmbalagensFrete = [
	"index",
	"admin",
	"conferido",
	"carregado",
	"qtde_produtos",
	"descricao",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"qr_sku",
	"id",
	"cargas_id",
];
const tableRef = createRef();
const tableRefP = createRef();
const tbodyRef = createRef();
const refFormCheck = createRef();
const qrCodeRef = createRef();

export function Conferencias({
	height = 400,
	setNomeromaneioSelecionada = () => {},
}) {
	const navigate = useNavigate();
	const user = useSelector((state) => state.userSlice);
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const embalagens = useSelector((state) => state.userSlice.embalagensList);
	const cargasLiberadasFrete = useSelector(
		(state) => state.userSlice.cargasList.data
	)
		.filter((item) => item.status === 1 || item.status === 2)
		.filter((item) => item.tipo_transporte === "FRETE");
	const cargasLiberadas = useSelector(
		(state) => state.userSlice.cargasList.data
	)
		.filter((item) => item.status === 1 || item.status === 2)
		.filter((item) => item.tipo_transporte === "PRÓPRIO");
	const transferenciasLiberadas = useSelector(
		(state) => state.userSlice.transferenciasList.data
	).filter((item) => item.status === 1 || item.status === 2); //cargas liberadas E CARGAS PARCIALMENTE CARREGADAS
	const dispatch = useDispatch();

	// novo metodo de separacao
	const [produtosEEmbalagens, setProdutosEEmbalagens] = useState([]);

	const [loading, setLoading] = useState({
		show: true,
	});

	const [indexList, setIndexList] = useState(30);
	const [headList, setHeadList] = useState({
		base: "",
		headList: [],
	});

	const [possiveisFiltros, setPossiveisFiltros] = useState([
		{
			title: "Cargas Próprias",
			filter_card: "carga_propria",
			filter: "carga_id",
			data: cargasLiberadas,
		},
		{
			title: "Cargas Frete",
			filter_card: "carga_frete",
			filter: "carga_id",
			data: cargasLiberadasFrete,
		},
		{
			title: "Nome da Transferencia",
			filter_card: "transferencia",
			filter: "transferencia",
			data: transferenciasLiberadas,
		},
	]);

	const [cargas_id, setCargasId] = useState(0);
	const [cargaSelecionada, setCargaSelecionada] = useState({});

	const [caminhao, setCaminhao] = useState({ placa: "" });
	const [error, setError] = useState({
		show: false,
		message: "",
	});
	const [success, setSuccess] = useState({
		show: false,
		message: "",
	});

	useEffect(() => {
		const refTable = tableRefP.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const [isOpen, setIsOpen] = useState({});
	const [transferenciaSelecionada, setTransferenciaSelecionada] =
		useState(null);

	const [separador, setSeparador] = useState({
		verificado: false,
		isSeparador: false,
	});

	const resetArrayProdutos = async (carga_id) => {
		setQr_sku("");
		dispatch(updateProdutosList([]));
		dispatch(updateEmbalagensList([]));
	};

	const updateArrayProdutos = async (carga_id) => {
		try {
			const produtosGet = await getData("cargas/produtos/separador", carga_id, {
				id_filial: user.id_filial,
			});

			return produtosGet.data;
		} catch (error) {
			setError({
				message: getErrorMessage(error),
				show: true,
			});
			throw error;
		}
	};

	const updateArrayRomaneios = async () => {
		const romaneiosGet = await getData(
			"cargas/pedidos/separador",
			cargas_id,
			{}
		);

		setLoading({ show: true });
		setTimeout(() => {
			dispatch(updateRomaneiosList(romaneiosGet.data));
			setLoading({ show: false });
		}, 500);
		return;
	};

	const updateArrayEmbalagens = async (carga_id) => {
		try {
			const embalagens = await getAllData("cargas/funcionarios/embalagens", {
				cargas_id: carga_id,
				id_filial: user.id_filial,
			});
			return embalagens.data;
			// dispatch(updateEmbalagensList(embalagens.data))
		} catch (error) {
			setError({
				message: getErrorMessage(error),
				show: true,
			});
			throw error;
		}
	};

	useEffect(() => {
		updateArrayRomaneios();
		resetArrayProdutos();
		possiveisFiltros.map((item) => {
			setIsOpen((last) => ({
				...last,
				[item.filter_card]: {
					open: false,
					style: {
						height: "50px",
						bi: "bi-arrow-down-short",
					},
					data: item.data,
				},
			}));
		});
	}, []);

	const onClickFilter = (e, nomeFilto) => {
		e.preventDefault();
		const divFilter = e.target.parentNode.parentNode.parentNode;

		if (divFilter.clientHeight < 293) {
			possiveisFiltros.map((item) => {
				if (item.filter_card === nomeFilto) {
					setIsOpen((last) => ({
						...last,
						[item.filter_card]: {
							...last[item.filter_card],
							open: !last[item.filter_card].open,
							style: {
								height: "310px",
								bi: "bi-x-lg",
							},
						},
					}));
				} else {
					setIsOpen((last) => ({
						...last,
						[item.filter_card]: {
							...last[item.filter_card],
							open: !last[item.filter_card].open,
							style: {
								height: "50px",
								bi: "bi-arrow-down-short",
							},
						},
					}));
				}
			});
		} else {
			setIsOpen((last) => ({
				...last,
				[nomeFilto]: {
					...last[nomeFilto],
					open: !last[nomeFilto].open,
					style: {
						height: "50px",
						bi: "bi-arrow-down-short",
					},
				},
			}));
		}
	};

	const refFormCheckOnClickFrete = async (e) => {
		setLoading({
			show: true,
		});

		setHeadList({
			base: "frete",
			headList: headListCargasFrete,
		});

		const selectedCarga = e.target.value;
		/* '/caminhoes */
		let placaCaminhao = cargasLiberadasFrete.find(
			(item) => item.id == selectedCarga
		)?.caminhao;
		setCargaSelecionada(
			cargasLiberadasFrete.find((item) => item.id == selectedCarga)
		);
		//
		if (placaCaminhao) {
			const caminhao = await getData("caminhao/separacao", placaCaminhao);
			setCaminhao(caminhao.data);
		} else {
			setCaminhao({});
		}

		resetArrayProdutos();
		setCargasId(selectedCarga);

		const [produtosL, embalagensL] = await Promise.all([
			updateArrayProdutos(selectedCarga),
			updateArrayEmbalagens(selectedCarga),
		]);

		const produtosH = [...produtosL.filter((item) => !item.embalado)].map(
			(item) => ({ ...item, tipo: "PRODUTO" })
		);

		const embalagemsH = [...embalagensL].map((item) => ({
			...item,
			tipo: "EMBALAGEM",
			qtde: item.qtde_produtos,
		}));

		const result = [...produtosH, ...embalagemsH];

		setProdutosEEmbalagens(result);
		setLoading({ show: false });

		setSeparador({
			isSeparador: false,
			verificado: false,
		});
	};

	/* selecionar cargas próprias */
	const refFormCheckOnClick = async (e) => {
		setLoading({
			show: true,
		});

		setProdutosEEmbalagens([]);
		setHeadList({
			base: "produtos",
			headList: headListProdutos,
		});

		const selectedCarga = e.target.value;
		/* '/caminhoes */
		let placaCaminhao = cargasLiberadas.find(
			(item) => item.id == selectedCarga
		)?.caminhao;
		setCargaSelecionada(
			cargasLiberadas.find((item) => item.id == selectedCarga)
		);
		//
		//
		if (placaCaminhao) {
			const caminhao = await getData("caminhao/separacao", placaCaminhao);
			setCaminhao(caminhao.data);
		} else {
			setCaminhao({});
		}

		resetArrayProdutos();
		try {
			setCargasId(selectedCarga);
			const [produtosL, embalagensL] = await Promise.all([
				updateArrayProdutos(selectedCarga),
				updateArrayEmbalagens(selectedCarga),
			]);
			const produtosH = [...produtosL.filter((item) => !item.embalado)].map(
				(item) => ({ ...item, tipo: "PRODUTO" })
			);
			const embalagemsH = [...embalagensL].map((item) => ({
				...item,
				tipo: "EMBALAGEM",
				qtde: item.qtde_produtos,
			}));
			const result = [...produtosH, ...embalagemsH];

			setProdutosEEmbalagens(result);
			setLoading({ show: false });
		} catch {}
		setSeparador({
			isSeparador: false,
			verificado: false,
		});
	};

	const refFormCheckOnClickTransferencia = async (e) => {
		setHeadList({
			base: "transferencia",
			headList: headListTransferencias,
		});
		// setProdutosEEmbalagens([])
		setLoading({ show: true });
		const selectedTransf = e.target.value;
		const cargas_id = transferenciasLiberadas.find((item) =>
			item.transferencia.includes(`_${selectedTransf}`)
		).cargas_id;
		const carga = await getAllData("gestao/cargas", { id: cargas_id });

		setCargaSelecionada(carga.data[0]);

		resetArrayProdutos();
		setTransferenciaSelecionada(`TRANSF_${selectedTransf}`);

		const [produtosTransf, embalagensTransf] = await Promise.all([
			getAllData("transferencias/funcionario/get-produtos", {
				transferencia: `TRANSF_${selectedTransf}`,
			}),
			getAllData("transferencias/funcionarios/embalagens", {
				transferencia: `TRANSF_${selectedTransf}`,
			}),
		]);

		//
		setProdutosEEmbalagens([
			...embalagensTransf.data
				.sort((a, b) => {
					if (a.descricao > b.descricao) return 1;
					if (a.descricao < b.descricao) return -1;
					return 0;
				})
				.map((item) => ({
					...item,
					tipo: "EMBALAGEM",
					qtde: item.qtde_produtos,
				})),
			...produtosTransf.data
				.sort((a, b) => {
					if (a.descricao > b.descricao) return 1;
					if (a.descricao < b.descricao) return -1;
					return 0;
				})
				.map((item) => ({ ...item, tipo: "PRODUTO" })),
		]);
		setLoading({ show: false });

		setSeparador({
			isSeparador: false,
			verificado: false,
		});
	};

	const clickEmbalarProdutosTransf = (e) => {
		e.preventDefault();
		setModalEmbalarProdutosTransf({
			show: true,
			produtos: produtosEEmbalagens.filter((item) => item.tipo == "PRODUTO"),
			transferencia: transferenciaSelecionada,
			transferencia_obj: transferenciasLiberadas.find(
				(item) => item.transferencia == transferenciaSelecionada
			),
		});
	};

	const [qr_sku, setQr_sku] = useState("");

	const [embalarProdutos, setModalEmbalarProdutos] = useState({
		show: false,
		produtos: [],
	});

	const [embalarProdutosFrete, setModalEmbalarProdutosFrete] = useState({
		show: false,
		produtos: [],
	});

	const [embalarProdutosTransf, setModalEmbalarProdutosTransf] = useState({
		show: false,
		produtos: [],
	});

	const [separarProdutos, setSepararProdutos] = useState({
		show: false,
		produtos: [],
	});

	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "primary",
		msg: "Finalizar",
	});

	const [loadingFinalizarParcial, setLoadingFinalizarParcial] = useState({
		flag: false,
		style: "primary",
		msg: "Finalizar Parcial",
	});
	const [finalizarTransferencia, setFinalizarTransferencia] = useState({
		flag: false,
		style: "primary",
		msg: "Finalizar",
	});

	const [finalizarFrete, setFinalizarFrete] = useState({
		flag: false,
		style: "primary",
		msg: "Finalizar",
	});

	const [assinarDocumento, setAssinarDocumento] = useState(false);

	const onClickFinalizarConferencia = async (e) => {
		e.preventDefault();
		if (cargaSelecionada.regiao_entrega !== "LOCAL") {
			if (
				produtosEEmbalagens.find((item) => !item.qtde && item.tipo == "PRODUTO")
			) {
				return setError({
					show: true,
					message:
						"Impossivel finalizar procedimento sem QUANTIDADE especificadas nos produtos",
				});
			}
			if (
				produtosEEmbalagens.find(
					(item) => !item.comprimento && item.tipo == "PRODUTO"
				)
			) {
				return setError({
					show: true,
					message:
						"Impossivel finalizar procedimento sem COMPRIMENTO especificadas nos produtos",
				});
			}
			if (
				produtosEEmbalagens.find(
					(item) => !item.altura && item.tipo == "PRODUTO"
				)
			) {
				return setError({
					show: true,
					message:
						"Impossivel finalizar procedimento sem ALTURA especificadas nos produtos",
				});
			}
			if (
				produtosEEmbalagens.find((item) => !item.peso && item.tipo == "PRODUTO")
			) {
				return setError({
					show: true,
					message:
						"Impossivel finalizar procedimento sem Peso especificadas nos produtos",
				});
			}
		}
		// return alert('passei')
		// //
		setLoadingFinalizar({
			flag: true,
			msg: "Validando",
			style: "warning",
		});

		let pedidos = [];

		try {
			const data = await updateTable(
				"cargas/separador/update-separacao",
				cargas_id,
				{ tipo: cargaSelecionada.regiao_entrega }
			);

			setSuccess({
				show: true,
				message: getSuccessMessage(data),
			});

			setLoadingFinalizar({
				flag: false,
				msg: data,
				style: "success",
			});
			if (produtosEEmbalagens.find((item) => !item.conferido)) {
			} else {
				setTimeout(() => {
					window.location.replace("cargas");
				}, 1500);
			}
		} catch (error) {
			setError({
				show: true,
				message: getErrorMessage(error),
			});
		} finally {
			setLoadingFinalizar({
				flag: false,
				msg: "Finalizar",
				style: "primary",
			});
		}
	};

	const onClickFinalizarTransferenciaNew = async (
		finalizarECancelar = false
	) => {
		setLoading({ show: true });
		setFinalizarTransferencia({
			flag: false,
		});

		const { transferencia, id_filial, cargas_id } = produtosEEmbalagens[0]; //.filter(item => item.tipo == 'PRODUTO')[0];
		const teste = await getData(
			"transferencias/funcionarios/produtos/verificar",
			transferencia,
			{ id_filial }
		);

		if (
			teste.data.exists &
			(user.email.toLowerCase() !== teste.data?.separador?.toLowerCase()) &
			(user.local_separacao.toLowerCase() === teste.data?.setor?.toLowerCase())
		) {
			setLoading({ show: false });

			setError({
				show: true,
				message:
					"Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
			});

			return;
		}

		// let produtos = [...produtosRomaneio.data].filter(
		// 	(item) =>
		// 		item.transferencia == transferencia && item.id_filial == user.id_filial
		// );
		let bodyUpdate = {
			data_cancelamento: new Date().toISOString(),
			user_cancelamento: user.name,
		};

		if (finalizarECancelar) {
			await updateTable(
				"transferencias/funcionario/produtos/cancelar-montante",
				transferenciaSelecionada,
				{
					...bodyUpdate,
					data_conferencia: null,
					user_conferencia: null,
					cargas_id,
				}
			).catch((error) => {
				setError({
					show: true,
					message: getErrorMessage(error),
				});
				setLoading(false);
			});
		}
		const status = !produtosEEmbalagens.find((item) => item.conferido)
			? 1
			: produtosEEmbalagens.find((item) => item.conferido) ==
					produtosEEmbalagens.length || finalizarECancelar
			? 5
			: 2;

		updateTable("transferencias/funcionarios/pedidos", "", {
			status,
			transferencia: transferenciaSelecionada,
		})
			.then((resp) => {
				setLoading({ show: false });
				if (finalizarECancelar) {
					setSuccess({
						show: true,
						message: "Atualizado com sucesso",
					});

					setTimeout(() => {
						window.location.replace("/separacao/cargas");
					}, 1000);
				}
			})
			.catch((error) => {
				// let msg = null
				setLoadingFinalizarParcial({
					flag: false,
					msg: "Error!!!!",
					style: "warning",
				});
				setError({
					show: true,
					message: getErrorMessage(error),
				});
			});
	};

	const clickEmbalarProdutos = (e) => {
		e.preventDefault();
		setModalEmbalarProdutos({
			show: true,
			produtos: produtosEEmbalagens.filter((item) => item.tipo === "PRODUTO"),
			carga: cargaSelecionada,
		});
	};

	const fnRetornoEmbalagem = async (carga) => {
		refFormCheckOnClick({
			target: {
				value: carga,
			},
		});
		return;
	};

	const fnRetornoEmbalagemTransferencia = async (transferencia) => {
		refFormCheckOnClickTransferencia({
			target: {
				value: transferencia,
			},
		});
		return;
	};

	// const [embalagens, setEmbalagens] = useState(undefined)

	const updateArrayCargas = async () => {
		const cargas = await getData("separacao/cargas", user.id_filial);

		const cargas_unicas = cargas.data
			.filter((item) => item.status_pedido == 1 || item.status_pedido == 2)
			.filter((i, n, s) => s.findIndex((o) => o.id === i.id) === n);
		dispatch(updateCargasList(cargas_unicas));
		// setLoading(false)
	};

	useEffect(() => {
		updateArrayCargas();
	}, []);

	return (
		<SeparacaoLayout>
			<div className="section-filters-table">
				<div className="card-header">
					<h3>Conferência de Produtos da Carga</h3>
				</div>
			</div>
			<div
				className="section-filters-table d-block d-sm-block"
				style={{ paddingInline: "5px" }}
			>
				<Row>
					<Col sm="12" md="8">
						<div
							style={{
								height: "100vh",
								overflow: "auto",
								paddingInline: "10px",
							}}
						>
							<div className="card-header">
								<h5>Tabela de Produtos</h5>
							</div>

							<Row>
								<Col sm="12" md="6" xl="3">
									<Card
										style={{ width: "auto" }}
										className="card-dashboard card-color-2"
									>
										<h3>
											{numeroDecimais(
												produtosEEmbalagens.reduce((p, c) => p + c.qtde, 0) *
													100
											)}
										</h3>
										<p>Qtd. Total</p>
										<i
											className="bi bi-123 bi-dashboard"
											style={{ backgroundColor: "#2F917D" }}
										></i>
									</Card>
								</Col>
								<Col sm="12" md="6" xl="3">
									<Card
										style={{ width: "auto" }}
										className="card-dashboard card-color-2"
									>
										<h3>
											{numeroDecimais(
												produtosEEmbalagens.reduce(
													(p, c) => p + Number(c.peso),
													0
												) * 100
											)}
										</h3>
										<p>Kg Total</p>
										<i
											className="bi bi-boxes bi-dashboard"
											style={{ backgroundColor: "#2F917D" }}
										></i>
									</Card>
								</Col>
								
								<Col sm="12" md="6" xl="3">
									<Card
										style={{ width: "auto" }}
										className="card-dashboard card-color-2"
									>
										<h3>
											{numeroDecimais(
												produtosEEmbalagens
													.filter(item => item.conferido == true)
													.reduce(
													(p, c) => p + Number(c.peso),
													0
												) * 100
											)}
										</h3>
										<p>Kg Separado</p>
										<i
											className="bi bi-boxes bi-dashboard"
											style={{ backgroundColor: "#2F917D" }}
										></i>
									</Card>
								</Col>
								<Col sm="12" md="6" xl="3">
									<Card
										style={{ width: "auto" }}
										className="card-dashboard card-color-2"
									>
										<h3>
											{numeroDecimais(
												produtosEEmbalagens.reduce(
													(p, c) => p + Number(c.volume),
													0
												) * 100
											)}
										</h3>
										<p>Volume</p>
										<i
											className="bi bi-dashboard"
											style={{ backgroundColor: "#2F917D" }}
										>
											m<sup>3</sup>
										</i>
									</Card>
								</Col>
								<Col sm="12" md="6" xl="3">
									<Card
										style={{ width: "auto" }}
										className="card-dashboard card-color-2"
									>
										<h3>
											{numeroDecimais(
												produtosEEmbalagens
													.filter((item) => item.abreviatura == "MTS")
													.reduce((p, c) => p + Number(c.volume), 0) * 100
											)}
										</h3>
										<p>Telha</p>
										<i
											className="bi bi-dashboard"
											style={{ backgroundColor: "#2F917D" }}
										>
											m<sup>2</sup>
										</i>
									</Card>
								</Col>
								
							</Row>
							<Container></Container>
							<div
								className="responsive-table card max-heigth-card-table"
								ref={tableRefP}
								style={{ height: "500px" }}
							>
								<ScrollbarLeft
									tableRef={tableRefP}
									tbody={tbodyRef}
									distance_coluna_2={"85px"}
								/>
								<Table size="sm" bordered>
									<thead style={{ zIndex: "49" }}>
										<tr>
											{headList.headList.map((item, index) => {
												if (item === "index") {
													return <th key={index}>#</th>;
												} else if (item.toUpperCase() === "CREATED_AT") {
													return <th key={index}>DATA DE CRIAÇÃO</th>;
												} else if (item.toUpperCase() === "UPDATED_AT") {
													return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
												}
												return <th key={index}>{item.toUpperCase()}</th>;
											})}
										</tr>
									</thead>
									<tbody ref={tbodyRef}>
										{headList.base == "transferencia"
											? produtosEEmbalagens
													// .filter((item) => item.id_filial === user.id_filial)
													.map((item, index, self) => {
														if (index < indexList) {
															return (
																<BodyRowProdutosTransferencias
																	key={index}
																	bodyList={item}
																	headList={headList.headList}
																	index={index}
																	tableLength={self.length}
																	setromaneioSelecionada={
																		setNomeromaneioSelecionada
																	}
																	qr_sku={qr_sku}
																	setError={setError}
																	setLoadingFinalizarParcial={
																		setLoadingFinalizarParcial
																	}
																	setSeparador={setSeparador}
																	separador={separador}
																	caminhao={caminhao}
																	cargaSelecionada={cargaSelecionada}
																	produtosArray={self}
																	setLoading={setLoading}
																	loading={loading}
																	updateList={setProdutosEEmbalagens}
																	fnRetorno={fnRetornoEmbalagemTransferencia}
																/>
															);
														}
													})
											: ""}
										{headList.base == "produtos"
											? produtosEEmbalagens.map((item, index, self) => {
													if (index < indexList) {
														return (
															<BodyRowTabelaSeparacao
																key={index}
																bodyList={item}
																headList={headList.headList}
																index={index}
																tableLength={self.length}
																setromaneioSelecionada={
																	setNomeromaneioSelecionada
																}
																qr_sku={qr_sku}
																setError={setError}
																setSeparador={setSeparador}
																separador={separador}
																caminhao={caminhao}
																cargaSelecionada={cargaSelecionada}
																updateList={setProdutosEEmbalagens}
															/>
														);
													}
											  })
											: ""}
										{headList.base == "frete"
											? produtosEEmbalagens.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowProdutosFrete
																key={index}
																bodyList={item}
																headList={headList.headList}
																index={index}
																tableLength={produtosRomaneio.data.length}
																setromaneioSelecionada={
																	setNomeromaneioSelecionada
																}
																qr_sku={qr_sku}
																setError={setError}
																setLoadingFinalizarParcial={
																	setLoadingFinalizarParcial
																}
																setSeparador={setSeparador}
																separador={separador}
																// setConferidoHandler = {setConferidoHandler}
																caminhao={caminhao}
																updateList={setProdutosEEmbalagens}
															/>
														);
													}
											  })
											: ""}
									</tbody>
								</Table>
							</div>
						</div>
					</Col>
					<Col sm="12" md="4" style={{ height: "100%" }}>
						<div className="card-header">
							<h5>Filtros</h5>
						</div>
						<div className="">
							{possiveisFiltros.map((item, index) => (
								<div
									className="card card-filter"
									style={isOpen?.[item.filter_card]?.style}
									key={index}
								>
									<div className="card-body card-body-filter">
										<h5
											className="card-title card-title-filter "
											onClick={(e) => onClickFilter(e, item.filter_card)}
											style={{ cursor: "pointer" }}
										>
											<span id="title" className="span-card-filter">
												{item.title}
												{loading.show ? (
													<Spinner
														animation="border"
														variant="black"
														className="loading-card-filters"
														size="sm"
													/>
												) : (
													""
												)}
											</span>
											<span
												className="close-card"
												data-toggle="tooltip"
												data-html="true"
												title="Show/Hide"
											>
												{isOpen?.[item.title]?.open ? (
													<i
														className={
															"bi " + isOpen?.[item.filter_card]?.style?.bi
														}
													></i>
												) : (
													<i
														className={
															"bi " + isOpen?.[item.filter_card]?.style?.bi
														}
													></i>
												)}
											</span>
										</h5>
										<div className="card-text" key={index}>
											<Form ref={refFormCheck}>
												{isOpen?.[item.filter_card]?.data.map(
													(_item, index) => {
														if (item.filter_card === "carga_propria") {
															return (
																<Form.Check
																	type="radio"
																	name={item.filter_card}
																	key={index}
																	value={_item.id}
																	label={_item.nome}
																	id={`check-${item.filter_card}-${index}`}
																	onClick={refFormCheckOnClick}
																/>
															);
														}
														if (item.filter_card === "carga_frete") {
															return (
																<Form.Check
																	type="radio"
																	name={item.filter_card}
																	key={index}
																	value={_item.id}
																	label={_item.nome}
																	id={`check-${item.filter_card}-${index}`}
																	onClick={refFormCheckOnClickFrete}
																/>
															);
														}
														if (item.filter_card === "transferencia") {
															return (
																<Form.Check
																	type="radio"
																	name={item.filter_card}
																	key={index}
																	value={_item.id}
																	label={_item.transferencia}
																	id={`check-${item.filter_card}-${index}`}
																	onClick={refFormCheckOnClickTransferencia}
																/>
															);
														}
													}
												)}
											</Form>
										</div>
									</div>
								</div>
							))}
							{headList.base == "frete" ? (
								<Fragment>
									<Button
										variant={loadingFinalizar.style}
										onClick={onClickFinalizarConferencia}
										className="mb-2"
									>
										{loadingFinalizar.msg}
										{loadingFinalizar.flag ? (
											<Spinner animation="border" variant="black" size="sm" />
										) : (
											""
										)}
									</Button>
								</Fragment>
							) : (
								""
							)}
							{produtosEEmbalagens.length && headList.base == "produtos" ? (
								<Fragment>
									{/* <div id="reader" width="600px" className="mb-2"></div>
                                    <Form.Control name='filterKeyword' className='form-control-input mb-2' placeholder='sku' ref={qrCodeRef} onInput={onInputQrCodeChange} /> */}
									<Button
										variant={loadingFinalizar.style}
										onClick={onClickFinalizarConferencia}
										className="mb-2"
									>
										{
											<Fragment>
												{loadingFinalizar.msg}{" "}
												{loadingFinalizar.flag ? (
													<Spinner
														animation="border"
														variant="black"
														size="sm"
													/>
												) : (
													""
												)}
											</Fragment>
										}
									</Button>
									<Button
										variant="dark"
										className="mb-2"
										onClick={clickEmbalarProdutos}
									>
										Embalar Produtos
									</Button>
									<Button
										className="mb-2"
										variant="dark"
										onClick={() => {
											// )
											setSepararProdutos((last) => ({
												...last,
												show: true,
												produtos: produtosEEmbalagens.filter((item) => item.tipo === "PRODUTO"),
											}));
										}}
									>
										Separar Produtos
									</Button>
								</Fragment>
							) : (
								""
							)}
							{produtosEEmbalagens.length &&
							headList.base == "transferencia" ? (
								<Fragment>
									<Button
										variant="primary"
										onClick={() =>
											setFinalizarTransferencia({
												flag: true,
												fn: onClickFinalizarTransferenciaNew,
											})
										}
										className="mb-2"
									>
										<Fragment>Finalizar</Fragment>
									</Button>
									<Button
										variant="dark"
										className="mb-2"
										onClick={clickEmbalarProdutosTransf}
									>
										{" "}
										Embalar Produtos{" "}
									</Button>
								</Fragment>
							) : (
								""
							)}
						</div>
					</Col>
				</Row>
			</div>
			{loading.show ? (
				<ModalLoading show={loading.show} setShow={setLoading} />
			) : (
				""
			)}
			{finalizarTransferencia.flag ? (
				<ModalFinalizarTransferenciaOpcoes
					show={finalizarTransferencia.flag}
					setShow={setFinalizarTransferencia}
					msg={
						"Deseja finalizar parcialmente esta transferencia, ou finalizar completamente e cancelar demais produtos ?"
					}
					title={"Finalizar Transferencia"}
					fn={onClickFinalizarTransferenciaNew}
				/>
			) : (
				""
			)}

			{embalarProdutosFrete.show ? (
				<ModalEmbalarProdutosFrete
					{...embalarProdutosFrete}
					setShow={setModalEmbalarProdutosFrete}
					title={"Embalar Produtos do Freteiro"}
					caminhao={caminhao}
				/>
			) : (
				""
			)}
			{embalarProdutos.show ? (
				<ModalEmbalarProdutos
					{...embalarProdutos}
					setShow={setModalEmbalarProdutos}
					title={"Embalar Produtos"}
					caminhao={caminhao}
					fnRetorno={fnRetornoEmbalagem}
				/>
			) : (
				""
			)}
			{separarProdutos.show ? (
				<ModalSepararProdutos
					{...separarProdutos}
					setShow={setSepararProdutos}
					title={"Separar Produtos"}
					caminhao={caminhao}
				/>
			) : (
				""
			)}

			{/* transferencias */}
			{embalarProdutosTransf.show ? (
				<ModalEmbalarProdutosTransferencia
					{...embalarProdutosTransf}
					setShow={setModalEmbalarProdutosTransf}
					title={"Embalar Produtos de Transferência"}
					fnRetorno={fnRetornoEmbalagemTransferencia}
				/>
			) : (
				""
			)}

			{assinarDocumento.flag ? (
				<ModalPDF_AssinarDanfe
					title={"Assinar PDFs"}
					pdf={assinarDocumento.PDF}
					setShow={setAssinarDocumento}
					show={assinarDocumento}
					cliente={assinarDocumento.cliente}
					{...assinarDocumento.pedido}
				/>
			) : (
				""
			)}

			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			{success.show ? (
				<ModalSuccessPretty setShow={setSuccess} {...success} />
			) : (
				""
			)}
		</SeparacaoLayout>
	);
}

export function ConferenciaDeCargas({}) {
	const [loading, setLoading] = useState(false);
	return (
		<SeparacaoLayout>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<div className="card-header">
					<h3>Conferências</h3>
				</div>
			</div>
		</SeparacaoLayout>
	);
}
