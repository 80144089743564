import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	createMsg,
	getAllData,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";
import { updateCargasList } from "../../redux/slicers/user";
import { getErrorMessage } from "../../services/helpers";
import ModalErrorPretty from "./ModalErrorPretty";

export default function ModalAtualizarEnviarSMS(props) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Enviar",
		style: "primary",
	});
	const [error, setError] = useState({
		show: false,
		message: "",
	});
	const [msgGestor, setMsgGestor] = useState("");
	const [file, setFile] = useState(false);
	const user = useSelector((state) => state.userSlice);

	const onChangeInputFile = (e) => {
		e.preventDefault();

		setFile(e.target.files[0]);
	};

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const clickSalvar = async (e) => {
		e.preventDefault();
		setLoading((last) => ({
			...last,
			isLoading: true,
			style: "warning",
			msg: "Enviando",
		}));

		/* update data on db */
		// cargas/produtos/separador
		if (props.fechamentoFinanceiro) {
			await createMsg(
				{
					...props,
					link: window.location.origin,
					msgGestor,
					fileT: file,
					fechamentoFinanceiro: true,
				},
				user.tipo
			)
				.then(async () => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						style: "success",
						msg: "Enviado",
					}));

					props.setAtualizarDespesa({ flag: true });
					handlerSimpleClose();
				})
				.catch((e) => {
					setLoading((last) => ({
						...last,
						isLoading: false,
						style: "danger",
						msg: "Error",
					}));

					setError({
						flag: true,
						message: getErrorMessage(e),
					});

					setTimeout(() => {
						setLoading((last) => ({
							...last,
							style: "primary",
							msg: "Enviar",
						}));
					}, 1000);
				});
			return;
		}

		/* neste local deve ser feita o envio do email para o thiago! */
		await createMsg(
			{
				...props,
				link: window.location.origin,
				msgGestor: msgGestor.trim(),
				fileT: file,
			},
			user.tipo
		)
			.then(async (response) => {
				await updateTable(
					verificaTipoUsuario(user) + "cargas/produtos/financeiro",
					props.cargas_id,
					{
						status: 6,
						cpf: props.cpf,
						liberacao_financeiro_file: response.data.filename,
					}
				)
				setLoading((last) => ({
					...last,
					isLoading: false,
					style: "success",
					msg: "Enviado",
				}));
				props.setAtualizarDespesa({ flag: true });
				handlerSimpleClose();
				// window.location.reload()
			})
			.catch((e) => {
				setError({
					show: true,
					message: getErrorMessage(e),
				});
				setLoading((last) => ({
					...last,
					isLoading: false,
					style: "danger",
					msg: "Error",
				}));
				setTimeout(() => {
					setLoading((last) => ({ ...last, style: "primary", msg: "Enviar" }));
				}, 1000);
			});
	};

	const handlerImprimir = async (e) => {
		const blob = new Blob([file], { type: "application/pdf" });
		const docUrl = URL.createObjectURL(blob);
		window.open(docUrl);
	};

	useEffect(() => {}, [file]);
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title ?? "Enviar Requisição Para Thiago"}{" "}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row className="align-items-end">
							<Col sm="12">
								<h5>Arquivo .pdf:</h5>
								<Form.Control
									className="form-control"
									type="file"
									onChange={onChangeInputFile}
								/>
							</Col>
							<Col sm="12" className="mt-5">
								<h5>Mensagem do Gestor:</h5>
							</Col>
							<Col sm="12" className="mt-1">
								<textarea
									rows="6"
									name="msg_gestor"
									style={{ width: "100%" }}
									value={msgGestor}
									onChange={(e) => setMsgGestor(e.target.value.toUpperCase())}
								></textarea>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={clickSalvar}
								disabled={loading.style !== "primary" ? true : false}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
						<Col>
							<Button variant="info" onClick={handlerImprimir}>
								Imprimir
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
			{error.show ? (
				<ModalErrorPretty
					setShow={setError}
					{...error}
				// msg={error.msg}
				// show={error.flag}
				// title={"Erro no envio do arquivo!"}
				// 	receberNotificacoes={true}
				/>
			) : (
				""
			)}
		</>
	);
}
