import { createRef, Fragment, useEffect, useState, useRef } from "react";
import {
	Button,
	Col,
	Form,
	Modal,
	Row,
	Table,
	Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { update } from "../../redux/slicers/user";
import { updateTable } from "../../services/Servicos";
import Loading from "../loading/Loading";

import { v4 as uuidv4 } from "uuid";
import "./Modal.scss";
import { addProduto, addSteps, updateSteps } from "../../redux/slicers/carga";
import ModalAtualizarDimensoes from "./ModalAtualizarDimensoes";
import ModalListarProdutosEmbalados from "./ModalListarProdutosEmbalados";
import ScrollbarLeft from "../scrollbars/ScrollbarLeft";
import { QRCodeSVG } from "qrcode.react";
import ModalLerQRCodeCarga from "./ModalLerQRCodeCarga";
import ModalErrorCarregameto_old from "./ModalErrorCarregameto_old";
import ModalErrorPretty from "./ModalErrorPretty";

const tableRef = createRef();
const tbodyRef = createRef();

const headList = [
	"index",
	"carregado",
	"id",
	"tipo",
	"numero_pedido/embalagem",
	"codigo",
	"descricao",
	"altura",
	"largura",
	"comprimento",
	"volume",
	"peso",
	"cor_caixa",
	// 'qr_code_id',
	// 'qr_code_id_teste'
];
const headListProdutos = [
	"index",
	"carregado",
	"numero_pedido",
	"codigo",
	"descricao",
	"qtde",
	"comprimento",
	"altura",
	"largura",
	"volume",
	"peso",
	"abreviatura",
	"qr_sku",
	"numero_item_embalagem",
	// , 'numero_embalagem'
];

export default function ModalGetCargaCarregamento({
	show,
	setShow,
	placaCaminhao,
	cargas_id,
	fnRetorno,
}) {
	// const cofirmarPlanejamento = useState(state => state.userSlice.confirmar_planejamento)
	const userEmail = useSelector((state) => state.userSlice.email);
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const user = useSelector((state) => state.userSlice);
	const steps = useSelector((state) => state.cargaSlicer.listaSteps);
	const embalagens = useSelector((state) => state.userSlice.embalagensList);
	const produtosEEmbalagens = user.produtosEEmbalagens.data;
	const dispatch = useDispatch();
	const [romaneios, setRomaneios] = useState([]);
	// const [produtosEEmbalagens, setProdutosEEmbalagens] = useState([])
	const [indexList, setIndexList] = useState(10);
	const [loadingConfirmation, setLoadingConfirmation] = useState(false);
	const [loadingCarregar, setLoadingCarregar] = useState({
		flag: false,
		style: "primary",
		msg: "Carregar",
	});

	const handlerSimpleClose = () => setShow(false);
	const [produtoSelecionado, setProdutoSelecionado] = useState({});

	const [error, setError] = useState({
		show: false,
		message: "",
	});

	const atualizarStatusPedido = () => {
		const algumItemNaoCarregado = produtosRomaneio.data.find(
			(item) =>
				item.numero_pedido == produtoSelecionado.numero_pedido &&
				!item.carregado
		);
		const algumItemCarregado = produtosRomaneio.data.find(
			(item) =>
				item.numero_pedido == produtoSelecionado.numero_pedido && item.carregado
		);
		const status = algumItemCarregado ? (algumItemNaoCarregado ? 10 : 11) : 9;

		const itensCarregados = produtosRomaneio.data.filter(
			(item) => item.carregado
		).length;
		const status_carga =
			itensCarregados == produtosRomaneio.data.length
				? 11
				: produtosRomaneio.data.find((item) => item.carregado)
				? 10
				: 9;

		updateTable("cargas/carregada/caminhoneiro", cargas_id, {
			status,
			pedidos: "" + produtoSelecionado.numero_pedido,
			local: "carregamento",
			status_carga,
		});

		setLoadingCarregar({
			flag: false,
			style: "success",
			msg: "Carregado",
		});
		setTimeout(() => {
			dispatch(
				updateSteps({ qr_sku: produtoSelecionado.qr_sku, concluido: true })
			);
			handlerSimpleClose();
		}, 500);
	};
	const onClickCarregar = async (e) => {
		// )
		if (produtoSelecionado.carregado) {
			setError({
				show: true,
				message: "Produto já carregado. Prossiga com outros produtos...",
			});
			return;
		}
		if (
			produtoSelecionado.largura <= 0 ||
			produtoSelecionado.altura <= 0 ||
			produtoSelecionado.comprimento <= 0
		) {
			setError({
				show: true,
				message: "Não é possivel carregar produto com parametro menor ou igual a zero!",
			});
			return;
		}
		setLoadingCarregar({
			flag: true,
			style: "warning",
			msg: "Carregando",
		});

		// if (produtoSelecionado.tipo === 'EMBALAGEM') {

		//     // .toISOString(), user: userEmail})
		//     dispatch(updateEmbalagensListCarregados({qr_sku: produtoSelecionado.qr_sku, carregado: true, data: new Date().toISOString(), user: userEmail}))

		//     // /* validar produtos embalados */
		//     // const produtosDaEmbalagem = produtosRomaneio.data.filter(item => item.embalado && Number(produtoSelecionado.numero_embalagem) === item.numero_embalagem)

		//     // for await (let produto of produtosDaEmbalagem) {
		//     //     dispatch(updateProdutosListCarregados({qr_sku: produto.qr_sku, carregado: true, data: new Date().toISOString(), user: userEmail, cor_caixa: produtoSelecionado.cor_caixa}))
		//     //     await updateTable('carga/caminhoneiro/produto/carregado', produto.id, {
		//     //         carregado: true,
		//     //         data_carregamento: new Date().toISOString(),
		//     //         user_carregamento: userEmail
		//     //     })
		//     // }

		//     // await updateTable('cargas/funcionarios/embalagens', produtoSelecionado.qr_sku, {
		//     //     carregado: true,
		//     //     data_carregamento: new Date().toISOString(),
		//     //     user_carregamento: userEmail,
		//     //     cargas_id
		//     // })

		// }
		// else {
		// //     try {
		// //         await updateTable('carga/caminhoneiro/produto/carregado', produtoSelecionado.id, {
		// //             carregado: true,
		// //             data_carregamento: new Date().toISOString(),
		// //             user_carregamento: userEmail
		// //         })

		// //     } catch (error) {
		// //
		// //     }
		//     dispatch(updateProdutosListCarregados({qr_sku: produtoSelecionado.qr_sku, carregado: true, data: new Date().toISOString(), user: userEmail, cor_caixa: produtoSelecionado.cor_caixa}))

		// }

		dispatch(
			addProduto({
				name: uuidv4(),
				position: [
					produtoSelecionado.pos_z,
					produtoSelecionado.pos_y,
					produtoSelecionado.pos_x,
				],
				args: [
					produtoSelecionado.largura,
					produtoSelecionado.altura,
					produtoSelecionado.comprimento,
				],
				mass: produtoSelecionado.peso,
				id: produtoSelecionado.id,
				step: produtoSelecionado.step_planejado,
				tipo: produtoSelecionado.tipo,
				qr_sku: produtoSelecionado.qr_sku,
				cor_caixa: produtoSelecionado.cor_caixa,
				data_carregamento: new Date().getTime(),
			})
		);

		fnRetorno(produtoSelecionado, true);
		handlerSimpleClose();
	};

	const onClickCarregarDadosPlanejados = () => {
		setLoadingConfirmation(true);
		setTimeout(() => {
			dispatch(
				update({
					confirmar_planejamento: {
						confirmado: true,
						date: new Date().toISOString(),
					},
				})
			);
		}, 1500);
	};

	// useEffect(() =>{
	//     if(user.confirmar_planejamento){
	//         // const cargas_id = produtosRomaneio.data[0].cargas_id
	//         const produtosH = [...produtosRomaneio.data.filter(item => !item.embalado)].map(item => ({...item, tipo: 'PRODUTO'}))
	//         const embalagemsH = [...embalagens.data.filter(item => item.cargas_id === cargas_id)].map(item => ({...item, tipo: 'EMBALAGEM'}))
	//         let produtosEEmbalagensL = [ ...produtosH, ...embalagemsH]

	//         if (!steps.length) {
	//             produtosEEmbalagensL
	//                 .sort((a, b) => a.step_planejado - b.step_planejado)
	//                 .map(item => {
	//                 dispatch(addSteps(
	//                     {
	//                         step: item.step_planejado,
	//                         tipo: item.tipo,
	//                         descricao: item.descricao,
	//                         pedido: item.numero_pedido,
	//                         numero_embalagem: item.numero_embalagem,
	//                         qr_sku: item.qr_sku,
	//                         concluido: false,
	//                         carregado: item.carregado,
	//                         date_concluido: "",
	//                     }
	//                 ))
	//                 })
	//         }
	//         setProdutosEEmbalagens(produtosEEmbalagensL.sort((a, b) => a.step_planejado - b.step_planejado))
	//     }

	// }, [user.confirmar_planejamento])

	// useEffect(() => {
	//     if(!atualizarPedido) return
	//     atualizarStatusPedido()
	//     setAtualizarPedido(false)
	// }, [atualizarPedido])

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition > tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList((last) => last + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	// useEffect(()=>{
	//     if(!produtosRomaneio.data.length) return
	//     let sorting = [...produtosRomaneio.data]
	//     sorting.sort((a, b) => {
	//         let stringP = ``
	//         const indexes = logicaState.logica.length
	//         if(indexes === 1){
	//             return a[logicaState.logica[0]] - b[logicaState.logica[0]]
	//         }
	//         if(indexes === 2){
	//             return a[logicaState.logica[0]] - b[logicaState.logica[0]] || a[logicaState.logica[1]] - b[logicaState.logica[1]]
	//         }
	//         if(indexes === 3){
	//             return a[logicaState.logica[0]] - b[logicaState.logica[0]] || a[logicaState.logica[1]] - b[logicaState.logica[1]] || a[logicaState.logica[2]] - b[logicaState.logica[2]]
	//         }
	//         return b.step_planejado - a.step_planejado
	//     })
	//     // ).reverse())
	//     setRomaneios(sorting.reverse())

	// }, [logicaState, produtosRomaneio.data])

	const [modalQrCode, setModalQrCode] = useState({ flag: false });

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Selecionar Produto: {placaCaminhao}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/*  {
                        // user.confirmar_planejamento.confirmado ? "" : ""
                        {/* <div style={{padding: '0 40px'}}>
                            {
                                produtosRomaneio.data.findIndex(o => o.planejado) >= 0 ? //se existir algum item planejado
                                <Alert className="bg-warning" style={{color:"#000", fontSize:'1.2rem', marginBottom:'30px'}}>
                                    <Row className='align-items-center'>
                                        <Col sm=''>
                                            Existem itens planejados. Deseja carregar Dados ?
                                        </Col>
                                        <Col sm='3'>
                                            <Button variant='dark' onClick={onClickCarregarDadosPlanejados}>{ loadingConfirmation ? <Loading msg={"Carregando"}/> : "Confirmar"}</Button>
                                        </Col>
                                    </Row>
                                </Alert>
                                :""
                            }
                        </div> 
                        // user.confirmar_planejamento.confirmado ?
                        //     steps.map((item, key) => {
                        //         // 
                        //         return (
                        //             <Alert className={item.concluido
                        //                 || item.carregado ? 'bg-success' : 'bg-info'} style={{
                        //                 color:"#000", 
                        //                 fontSize:'1.2rem', 
                        //                 marginBottom:'30px', 
                        //                 textDecoration: item.concluido || item.carregado ? 'line-through': 'none',
                        //                 }} key={key}>
                        //                 {
                        //                     item.tipo === 'EMBALAGEM' ? 
                        //                     `Passo ${key + 1}: Carregar ${item.tipo} ${item.descricao} da embalagem ${String(item.numero_embalagem).padStart(7, 0)}`
                        //                     : 
                        //                     `Passo  ${key + 1}: Carregar ${item.tipo} ${item.descricao} do pedido ${item.pedido}`
                        //                 }
                        //             </Alert>
                                
                        //     )
                        // }
                        // )
                        // :
                        // ""
                        // <Informacoes produtos={produtosRomaneio.data} />
                    } */}

					<div className="section-filters-table">
						<div className="card-header">
							<h5>Produtos da Carga</h5>
						</div>

						<div
							className=" responsive-table"
							ref={tableRef}
							style={{ position: "relative", height: "500px" }}
						>
							<ScrollbarLeft
								tableRef={tableRef}
								tbody={tbodyRef}
								coluna2={3}
								distance_coluna_2={"85px"}
							/>
							<Table hover bordered size="sm">
								<thead>
									<tr>
										{headList.map((item, index) => {
											if (item === "index") {
												return <th key={index}>#</th>;
											} else if (item.toUpperCase() === "CREATED_AT") {
												return <th key={index}>DATA DE CRIAÇÃO</th>;
											} else if (item.toUpperCase() === "UPDATED_AT") {
												return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
											}
											return <th key={index}>{item.toUpperCase()}</th>;
										})}
									</tr>
								</thead>
								<tbody ref={tbodyRef}>
									{produtosEEmbalagens
										.filter((item) => !item.embalado)
										.map((item, index) => {
											// if(index < indexList){
											return (
												<BodyRowProdutoCarregamento
													key={index}
													bodyList={item}
													index={index}
													tableLength={romaneios.length}
													setProdutoSelecionado={setProdutoSelecionado}
													produtoSelecionado={produtoSelecionado}
												/>
											);
											// }
										})}
								</tbody>
							</Table>
							{indexList < produtosEEmbalagens.length ? (
								<Loading color={"black"} msg="Loading" />
							) : (
								""
							)}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant="warning"
								onClick={() =>
									setModalQrCode({ flag: true, title: "Identificar Produto" })
								}
							>
								Identificar Produto
							</Button>
						</Col>
						<Col>
							<Button
								variant={loadingCarregar.style}
								onClick={onClickCarregar}
								disabled={produtoSelecionado.qr_sku ? false : true}
							>
								{loadingCarregar.msg}
								{loadingCarregar.flag ? (
									<Spinner animation="border" size="sm" />
								) : (
									""
								)}
								{loadingCarregar.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar 2
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
			{error.show ? (
				<ModalErrorPretty
					setShow={setError}
					{...error}
				/>
			) : (
				""
			)}
			{modalQrCode.flag ? (
				<ModalLerQRCodeCarga
					msg={modalQrCode.msg}
					title={modalQrCode.title}
					show={modalQrCode.flag}
					setShow={setModalQrCode}
					setProdutoSelecionado={setProdutoSelecionado}
				/>
			) : (
				""
			)}
		</>
	);
}

function BodyRowProdutoCarregamento({
	index,
	bodyList,
	tableLength,
	setProdutoSelecionado = () => {},
	produtoSelecionado,
}) {
	const refRow = useRef();
	const refRowCheck = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const caminhao = useSelector((state) => state.userSlice.caminhaoSelecionado);
	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const [editarDimensoes, setEditarDimensoes] = useState({
		flag: false,
		dimensao: "",
		produto: "",
		descricao: "",
		parametro: "",
		qr_sku: "",
		msg_button: "",
		currentValue: 0,
		cargas_id: "",
	});
	const [verProdutos, setVerProdutos] = useState({
		flag: false,
		produtosDaEmbalagem: [],
	});

	const onClickProdutos = (e, numero_embalagem) => {
		const produtosDaEmbalagem = produtosRomaneio.data.filter(
			(item) => item.numero_embalagem === numero_embalagem
		);
		//
		setVerProdutos((last) => ({
			...last,
			flag: true,
			produtosDaEmbalagem,
			numero_embalagem,
		}));
	};
	useEffect(() => {
		if (!produtoSelecionado.qr_sku) return;
		if (bodyList.qr_sku === produtoSelecionado.qr_sku) {
			if (produtoSelecionado.carregado) {
				refRow.current.classList = "bg-selected-danger";
				setProdutoSelecionado({});
				return;
			} else {
				refRow.current.classList = "bg-selected";
			}
		} else {
			refRow.current.classList = "";
		}
	}, [produtoSelecionado]);

	return (
		<Fragment>
			{
				<tr ref={refRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "planejado") {
							return (
								<td key={_index} className="text-center">
									<Form.Check
										type="checkbox"
										disabled
										ref={refRowCheck}
										checked={bodyList?.planejado ?? false}
										key={index}
									/>
								</td>
							);
						} else if (item === "carregado") {
							return (
								<td key={_index} className="text-center">
									<Form.Check
										type="checkbox"
										disabled
										ref={refRowCheck}
										checked={bodyList?.carregado ?? false}
										key={index}
									/>
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td key={_index} className={item} style={{ minWidth: "500px" }}>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "DTEMISSAO") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item.toLowerCase() === "numero_pedido/embalagem") {
							if (bodyList.tipo !== "PRODUTO") {
								return (
									<td key={_index}>
										<div className="d-flex justify-content-between">
											<span
												className="verificar"
												style={{ fontSize: "inherit", color: "inherit" }}
											>
												{String(bodyList.numero_embalagem).padStart(7, 0)}
											</span>
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-plus-circle bi-circle-animation"
													title="Ver Produtos"
													onClick={(e) => {
														onClickProdutos(e, bodyList.numero_embalagem);
													}}
												></i>
											</span>
										</div>
									</td>
								);
							}
							return (
								<td key={_index} className={item}>
									{" "}
									{bodyList.numero_pedido}
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList.qr_sku}
									{/* <QRCodeSVG value={"{\n" + bodyList.qr_sku + "}"} /> */}
								</td>
							);
						} else if (item === "qr_code_id_teste") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_sku + "}"} />
								</td>
							);
						}

						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{editarDimensoes.flag ? (
				<ModalAtualizarDimensoes
					show={editarDimensoes.flag}
					setShow={setEditarDimensoes}
					{...editarDimensoes}
				/>
			) : (
				""
			)}
			{verProdutos.flag ? (
				<ModalListarProdutosEmbalados
					setShow={setVerProdutos}
					show={verProdutos.flag}
					produtos={verProdutos.produtosDaEmbalagem}
					headList={headListProdutos}
					{...verProdutos}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
