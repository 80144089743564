import {
	Page,
	Text,
	Document,
	StyleSheet,
	View,
	Image,
} from "@react-pdf/renderer";
import logoFermat from "../../assets/fermat_logo_3.png";
import { Fragment, useEffect, useState } from "react";
import {
	getCorretData,
	getStatusCarga,
	locationDev,
	valorMoeda,
} from "../../services/Servicos";

const styles = StyleSheet.create({
	body: {
		paddingTop: 10,
		paddingBottom: 50,
		paddingHorizontal: 30,
		fontSize: 10,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
	},
	text: {
		margin: 12,
		fontSize: 14,
		textAlign: "justify",
	},
	header: {
		fontSize: 8,
		color: "grey",
		width: "100%",
		textAlign: "right",
	},
	pageNumber: {
		fontSize: 10,
		textAlign: "center",
		color: "grey",
		marginBottom: 5,
	},
	headerFixed: {
		display: "flex",
		flexDirection: "row",
		margin: 12,
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: 0.5,
		borderColor: "#000",
		paddingBottom: 15,
	},
	footerFixed: {
		position: "absolute",
		bottom: 0,
		margin: 12,
		left: 12,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		color: "grey",
		// border:1,
		// borderColor: '#000',
	},
	bodyText: {
		padding: 3,
		marginBottom: 5,
		backgroundColor: "rgb(180, 180, 180)",
	},
	bodyTextOverline: {
		verticalAlign: "super",
		fontSize: "5px",
	},
	thead: {
		marginHorizontal: 12,
		width: "100%",
		color: "white",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		border: 1,
		borderColor: "white",
		// padding:0
	},
	td: {
		fontSize: 8,
		borderRight: 1,
		borderColor: "white",
		paddingHorizontal: 10,
		marginBottom: 0,
		textAlign: "left",
		backgroundColor: "transparent",
	},
});

export default function PDFFile(props) {
	const formatedNumber = (number) => Number(number).toLocaleString("pt-BR");
	const [carga, setCarga] = useState({
		carga: props.cargas_id,
		data_saida: props.data_saida ?? "Não Definido",
		cartao: props.cartao,
		custo_viagem: valorMoeda(props.valor_dinheiro + props.valor_cartao),
		valor_cartao: valorMoeda(props.valor_cartao),
		valor_dinheiro: valorMoeda(props.valor_dinheiro),
		valor_extra: valorMoeda(props.credito_adicional),
		valor_devolvido: valorMoeda(
			props.despesas
				.filter((item) => item.tipo == "devolucao")
				.reduce((p, c) => p + c.valor, 0)
		),
		valor_excedido: valorMoeda(props.valor_excedido ?? 0),
		saldo_viagem: valorMoeda(
			props.valor_dinheiro +
				props.valor_cartao -
				props.despesas
					.filter((item) => item.tipo != "devolucao")
					.reduce((p, c) => c.valor + p, 0) +
				props.despesas
					.filter((item) => item.tipo == "devolucao")
					.reduce((p, c) => c.valor + p, 0)
		),
	});
	const [dadosCaminhaoCaminhoneiro, setDadosCaminhaoCaminhoneiro] = useState({
		condutor: props.motorista ?? "NÃO INFORMADO",
		cpf_condutor: props.caminhoneiro ?? "NÃO INFORMADO",
		validade_cnh: props.validade_cnh
			? new Date(props.validade_cnh).toLocaleDateString("pt-br", {
					timeZone: "UTC",
			  })
			: "N/A",
		veiculo: props.caminhao,
		veiculo_tara: Number(props.caminhao_tara * 1000).toLocaleString(),
		validade_tacografo: props.validade_tacografo
			? new Date(props.validade_tacografo).toLocaleDateString("pt-br", {
					timeZone: "UTC",
			  })
			: "N/A",
		km_previsto: 0,
		km_inicial: formatedNumber(props.ultimo_km_partida ?? 0),
		km_final: formatedNumber(props.ultimo_km_chegada ?? 0),
		km_rodado: formatedNumber(
			props.ultimo_km_chegada - props.ultimo_km_partida
		),
		litro_combustivel_partida: formatedNumber(
			props.litros_abastecidos_partida ?? 0
		),
		litro_combustivel_chegada: formatedNumber(
			props.litros_abastecidos_chegada ?? 0
		),
		litro_combustivel_adicional: formatedNumber(
			props.combustivel_adicional ?? 0
		),
		litros_utilizados: formatedNumber(props.litros_abastecidos_chegada ?? 0),
		media_km_litro: formatedNumber(
			(props.ultimo_km_chegada - props.ultimo_km_partida) /
				(props.litros_abastecidos_chegada + (props.combustivel_adicional || 0))
		),
		despesas_condutor: valorMoeda(
			props.despesas
				.filter((item) => item.tipo !== "devolucao")
				.reduce((p, c) => p + c.valor, 0)
		),
		despesas_outros: valorMoeda(props.despesa_outros ?? 0),
		qtde_nf: formatedNumber(props.qtde_pedidos),
		valor_nf: valorMoeda(
			props.romaneios.reduce((p, c) => p + c.valor_total, 0)
		),
		peso_nf: formatedNumber(props.peso_total ?? 0),
		volume_nf: formatedNumber(props.volume_total ?? 0),
		metros_nf: formatedNumber(props.metros_quadrados ?? 0),
	});
	const [romaneios, setRomaneios] = useState(props.romaneios);
	const [despesas, setDespesas] = useState(props.despesas);


	return (
		// <PDFViewer height={800} width={"100%"} showToolbar={false}>
		<Document
			title={`Relatorio do Financeiro - Carga ${props.nome}, ID ${props.cargas_id}`}
		>
			<Page style={styles.body}>
				<View style={styles.headerFixed} fixed>
					<View style={{ width: "50%" }}>
						<Image style={{ img: "100%" }} src={logoFermat}></Image>
					</View>
					<View style={styles.header}>
						<Text style={{ marginBottom: 5 }}>
							{props.cargas_id} - {props.nome}
						</Text>
						<Text style={{ marginBottom: 5 }}>Sistema B-LOG</Text>
						<Text style={{ marginBottom: 5 }}>
							FERMAT - Ind. e Com. de Texteis LTDA
						</Text>
					</View>
				</View>
				<View style={{ marginHorizontal: 12 }} fixed>
					{/* <Text style={{...styles.bodyText}}>
						Caminhoneiro: {JSON.stringify(props)}
					</Text>	 */}
					<Text style={{ ...styles.bodyText }}>
						Status da Viagem: {getStatusCarga(props.status)}
					</Text>
					<Text style={{ ...styles.bodyText }}>
						Condutor: {dadosCaminhaoCaminhoneiro.condutor}
					</Text>
					<Text style={{ ...styles.bodyText }}>
						Código da Viagem: {props.cargas_id}
					</Text>
				</View>

				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 12,
						backgroundColor: "rgb(0, 142, 59)",
						color: "white",
						margin: 12,
					}}
				>
					Dados da Viagem
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<View style={{ marginHorizontal: 12, width: "50%" }}>
						{Object.keys(carga).map((item, key) => {
							const backgroundColor =
								key % 2 === 1 ? "rgb(180, 180, 180, 0.4)" : "rgb(255,255,255)";
							return (
								<Text
									style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
									key={key}
								>
									- {item.toUpperCase().replaceAll("_", " ")}: {carga[item]}
								</Text>
							);
						})}
					</View>
					<View style={{ marginHorizontal: 12, width: "50%" }}>
						{Object.keys(dadosCaminhaoCaminhoneiro).map((item, key) => {
							const backgroundColor =
								key % 2 === 1 ? "rgb(180, 180, 180, 0.4)" : "rgb(255,255,255)";
							if (item === "peso_nf") {
								return (
									<Text
										style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
										key={key}
									>
										- {item.toUpperCase().replaceAll("_", " ")}:{" "}
										{dadosCaminhaoCaminhoneiro[item]} Kg
									</Text>
								);
							}
							if (item === "metros_nf") {
								return (
									<Text
										style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
										key={key}
									>
										- {item.toUpperCase().replaceAll("_", " ")}:{" "}
										{dadosCaminhaoCaminhoneiro[item]} m
										<Text style={styles.bodyTextOverline}>2</Text>
									</Text>
								);
							}
							if (item === "volume_nf") {
								return (
									<Text
										style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
										key={key}
									>
										- {item.toUpperCase().replaceAll("_", " ")}:{" "}
										{dadosCaminhaoCaminhoneiro[item]} m
										<Text style={styles.bodyTextOverline}>3</Text>
									</Text>
								);
							}
							return (
								<Text
									style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
									key={key}
								>
									- {item.toUpperCase().replaceAll("_", " ")}:{" "}
									{dadosCaminhaoCaminhoneiro[item]}
								</Text>
							);
						})}
					</View>
				</View>

				{/* new page because current view has break parameter */}
				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 18,
						color: "black",
						margin: 12,
						marginBottom: 40,
						backgroundColor: "transparent",
					}}
					break
				>
					Dados dos Pedidos
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "95%",
						alignItems: "center",
						padding: 0,
						marginBottom: 15,
						marginHorizontal: 12,
					}}
				>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Qtde NF</Text>
						<Text style={{ fontSize: 14 }}>
							{formatedNumber(props.qtde_pedidos)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Qtde Produtos</Text>
						<Text style={{ fontSize: 14 }}>
							{formatedNumber(props.qtde_produtos)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Valot Total</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(romaneios.reduce((p, c) => p + c.valor_total, 0))}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Peso Total</Text>
						<Text style={{ fontSize: 14 }}>
							{formatedNumber(props.peso_total ?? 0)}
						</Text>
					</View>
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						padding: 0,
					}}
				>
					<View
						style={{ ...styles.thead, backgroundColor: "rgb(16, 25, 53)" }}
						fixed
					>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 10,
								fontSize: 6,
							}}
						>
							#
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 60,
								fontSize: 6,
							}}
						>
							Emissao
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 60,
								fontSize: 6,
							}}
						>
							Nota Fiscal
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 100,
								fontSize: 6,
							}}
						>
							Vendedor
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 100,
								fontSize: 6,
							}}
						>
							Cliente
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 150,
								fontSize: 6,
							}}
						>
							Endereço
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 80,
								fontSize: 6,
							}}
						>
							Valor
						</Text>
						{/* <Text style={{...styles.bodyText, ...styles.td, fontSize: 6}}>Peso [Kg]</Text> */}
					</View>
					{romaneios.map((item, key, self) => {
						const backgroundColor =
							key % 2 === 1 ? "rgb(122, 138, 184)" : "rgb(255,255,255)";

						return (
							<View
								style={{
									...styles.thead,
									backgroundColor: backgroundColor,
									borderTop: 0,
									color: "black",
								}}
								key={key}
							>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 10,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{key + 1}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 60,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{getCorretData(item.emissao)}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 60,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{item.numero_pedido}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{item.nome_vendedor}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
										fontSize: 6,
										paddingBottom: 5,
										paddingTop: 5,
									}}
								>
									{item.nome_fantasia}
								</Text>
								<Text
									style={{
										...styles.td,
										width: 150,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{item.endereco} - {item.bairro} - {item.cidade} - {item.cep}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										borderRight: 0,
										width: 80,
										fontSize: 6,
									}}
								>
									{valorMoeda(item.valor_total)}
								</Text>
								{/* <Text style={{...styles.bodyText, ...styles.td, borderRight:0, fontSize: 6}}>{formatedNumber(item?.peso_total ?? 0)}</Text> */}
							</View>
						);
					})}
				</View>

				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "95%",
						alignItems: "center",
						padding: 0,
						marginTop: 50,
						marginHorizontal: 12,
					}}
				>
					<View style={{ width: "40%" }}>
						<Text
							style={{ fontSize: 8, marginBottom: 10, borderBottom: 0.5 }}
						></Text>
						<Text style={{ fontSize: 8, textAlign: "center" }}>
							Gerente Geral da Industria
						</Text>
					</View>
					<View style={{ width: "40%" }}>
						<Text
							style={{ fontSize: 8, marginBottom: 10, borderBottom: 0.5 }}
						></Text>
						<Text style={{ fontSize: 8, textAlign: "center" }}>
							Gerente Logística
						</Text>
					</View>
				</View>

				<View style={styles.footerFixed} fixed>
					<View>
						<Text style={{ marginBottom: 5 }}>
							THIAGO ARAUJO -{" "}
							{new Date().toLocaleString("pt-BR", {}).replace(",", "")}
						</Text>
					</View>
					<View>
						<Text
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) =>
								`${pageNumber}/${totalPages}`
							}
						/>
					</View>
				</View>

				{/* new page because current view has break parameter */}
				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 18,
						color: "black",
						margin: 12,
						marginBottom: 40,
						backgroundColor: "transparent",
					}}
					break
				>
					Despesas do Condutor
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "95%",
						alignItems: "center",
						padding: 0,
						marginBottom: 15,
						marginHorizontal: 12,
					}}
				>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Pedágio</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo === "pedagio")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Alimentação</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo === "refeicao")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Manutenção</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo === "manutencao")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>
							Total Despesas
						</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo !== "devolucao")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						padding: 0,
					}}
				>
					<View
						style={{ ...styles.thead, backgroundColor: "rgb(16, 25, 53)" }}
						fixed
					>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 10 }}>
							#
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 80 }}>
							Data
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 90 }}>
							Lançamento
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 70 }}>
							Despesa
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 100 }}>
							Forma PGTO
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 100 }}>
							Tipo Alimentação
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, border: 0 }}>
							Valor
						</Text>
					</View>
					{despesas.map((item, key, self) => {
						const backgroundColor =
							key % 2 === 1 ? "rgb(122, 138, 184)" : "rgb(255,255,255)";

						return (
							<View
								style={{
									...styles.thead,
									backgroundColor: backgroundColor,
									borderTop: 0,
									color: "black",
								}}
								key={key}
							>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 10,
										borderRight: 0,
									}}
								>
									{key + 1}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 80,
										borderRight: 0,
									}}
								>
									{item.data_despesa
										? getCorretData(item.data_despesa)
										: "Não Informada"}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 90,
										borderRight: 0,
									}}
								>
									{item.tipo_lancamento}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 70,
										borderRight: 0,
									}}
								>
									{item.tipo}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
									}}
								>
									{item.forma_pagamento}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
									}}
								>
									{item.tipo_alimentacao ?? "---"}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: "auto",
										borderRight: 0,
									}}
								>
									{valorMoeda(item.valor)}
								</Text>
							</View>
						);
					})}
				</View>

				{/* diarios de Bordo */}
				{/* new page because current view has break parameter */}
				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 18,
						color: "black",
						margin: 12,
						marginBottom: 40,
						backgroundColor: "transparent",
					}}
					break
				>
					Diário de Bordo
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						padding: 0,
					}}
				>
					<View
						style={{ ...styles.thead, backgroundColor: "rgb(16, 25, 53)" }}
						fixed
					>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 10 }}>
							#
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 80 }}>
							Data
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 120 }}>
							Horário
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 110 }}>
							Mensagem
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 120,
								borderRight: 0,
							}}
						>
							Foto
						</Text>
					</View>
					{props.diario.map((item, key, self) => {
						const backgroundColor =
							key % 2 === 1 ? "rgb(122, 138, 184)" : "rgb(255,255,255)";
						return (
							<View
								style={{
									...styles.thead,
									backgroundColor: backgroundColor,
									border: 0,
									color: "black",
								}}
								key={key}
							>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 10,
										borderRight: 0,
									}}
								>
									{key + 1}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 80,
										borderRight: 0,
									}}
								>
									{new Date(item.data).toLocaleDateString("pt-BR", {
										timeZone: "UTC",
									})}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 120,
										borderRight: 0,
									}}
								>
									{new Date(item.data).toLocaleTimeString("pt-BR", {
										timeZone: "UTC",
									})}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 110,
										borderRight: 0,
									}}
								>
									{item.mensagem}
								</Text>
								<Image
									style={{ width: 200, borderRight: 0 }}
									src={locationDev + "/files/users" + item.upload_file}
								></Image>
							</View>
						);
					})}
				</View>
			</Page>
		</Document>
		// </PDFViewer>
	);
}

export function PDFFileDownload(props) {
	const formatedNumber = (number) => Number(number).toLocaleString("pt-BR");
	const [carga, setCarga] = useState({
		carga: props.cargas_id,
		data_saida: props.data_saida ?? "Não Definido",
		cartao: props.cartao,
		custo_viagem: valorMoeda(props.valor_dinheiro + props.valor_cartao),
		valor_cartao: valorMoeda(props.valor_cartao),
		valor_dinheiro: valorMoeda(props.valor_dinheiro),
		valor_extra: valorMoeda(props.credito_adicional),
		valor_devolvido: valorMoeda(
			props.despesas
				.filter((item) => item.tipo == "devolucao")
				.reduce((p, c) => p + c.valor, 0)
		),
		valor_excedido: valorMoeda(props.valor_excedido ?? 0),
		saldo_viagem: valorMoeda(
			props.valor_dinheiro +
				props.valor_cartao -
				props.despesas
					.filter((item) => item.tipo != "devolucao")
					.reduce((p, c) => c.valor + p, 0) +
				props.despesas
					.filter((item) => item.tipo == "devolucao")
					.reduce((p, c) => c.valor + p, 0)
		),
	});
	const [dadosCaminhaoCaminhoneiro, setDadosCaminhaoCaminhoneiro] = useState({
		condutor: props.motorista ?? "NÃO INFORMADO",
		cpf_condutor: props.caminhoneiro ?? "NÃO INFORMADO",
		validade_cnh: props.validade_cnh
			? new Date(props.validade_cnh).toLocaleDateString("pt-br", {
					timeZone: "UTC",
			  })
			: "N/A",
		veiculo: props.caminhao,
		veiculo_tara: Number(props.caminhao_tara * 1000).toLocaleString(),
		validade_tacografo: props.validade_tacografo
			? new Date(props.validade_tacografo).toLocaleDateString("pt-br", {
					timeZone: "UTC",
			  })
			: "N/A",
		km_previsto: 0,
		km_inicial: formatedNumber(props.ultimo_km_partida ?? 0),
		km_final: formatedNumber(props.ultimo_km_chegada ?? 0),
		km_rodado: formatedNumber(
			props.ultimo_km_chegada - props.ultimo_km_partida
		),
		litro_combustivel_partida: formatedNumber(
			props.litros_abastecidos_partida ?? 0
		),
		litro_combustivel_chegada: formatedNumber(
			props.litros_abastecidos_chegada ?? 0
		),
		litro_combustivel_adicional: formatedNumber(
			props.combustivel_adicional ?? 0
		),
		litros_utilizados: formatedNumber(props.litros_abastecidos_chegada ?? 0),
		media_km_litro: formatedNumber(
			(props.ultimo_km_chegada - props.ultimo_km_partida) /
				(props.litros_abastecidos_chegada + props.combustivel_adicional)
		),
		despesas_condutor: valorMoeda(
			props.despesas
				.filter((item) => item.tipo !== "devolucao")
				.reduce((p, c) => p + c.valor, 0)
		),
		despesas_outros: valorMoeda(props.despesa_outros ?? 0),
		qtde_nf: formatedNumber(props.qtde_pedidos),
		valor_nf: valorMoeda(
			props.romaneios.reduce((p, c) => p + c.valor_total, 0)
		),
		peso_nf: formatedNumber(props.peso_total ?? 0),
		volume_nf: formatedNumber(props.volume_total ?? 0),
		metros_nf: formatedNumber(props.metros_quadrados ?? 0),
	});
	const [romaneios, setRomaneios] = useState(props.romaneios);
	const [despesas, setDespesas] = useState(props.despesas);

	return (
		<Document
			title={`Relatorio do Financeiro - Carga ${props.nome}, ID ${props.id}`}
		>
			<Page style={styles.body}>
				<View style={styles.headerFixed} fixed>
					<View style={{ width: "50%" }}>
						<Image style={{ img: "100%" }} src={logoFermat}></Image>
					</View>
					<View style={styles.header}>
						<Text style={{ marginBottom: 5 }}>
							{props.cargas_id} - {props.nome}
						</Text>
						<Text style={{ marginBottom: 5 }}>Sistema B-LOG</Text>
						<Text style={{ marginBottom: 5 }}>
							FERMAT - Ind. e Com. de Texterfis LTDA
						</Text>
					</View>
				</View>
				<View style={{ marginHorizontal: 12 }} fixed>
					{/* <Text style={{...styles.bodyText}}>
						Caminhoneiro: {JSON.stringify(props)}
					</Text>	 */}
					<Text style={{ ...styles.bodyText }}>
						Status da Viagem: {getStatusCarga(props.status)}
					</Text>
					<Text style={{ ...styles.bodyText }}>
						Condutor: {dadosCaminhaoCaminhoneiro.condutor}
					</Text>
					<Text style={{ ...styles.bodyText }}>
						Código da Viagem: {props.cargas_id}
					</Text>
				</View>

				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 12,
						backgroundColor: "rgb(0, 142, 59)",
						color: "white",
						margin: 12,
					}}
				>
					Dados da Viagem
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<View style={{ marginHorizontal: 12, width: "50%" }}>
						{Object.keys(carga).map((item, key) => {
							const backgroundColor =
								key % 2 === 1 ? "rgb(180, 180, 180, 0.4)" : "rgb(255,255,255)";
							return (
								<Text
									style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
									key={key}
								>
									- {item.toUpperCase().replaceAll("_", " ")}: {carga[item]}
								</Text>
							);
						})}
					</View>
					<View style={{ marginHorizontal: 12, width: "50%" }}>
						{Object.keys(dadosCaminhaoCaminhoneiro).map((item, key) => {
							const backgroundColor =
								key % 2 === 1 ? "rgb(180, 180, 180, 0.4)" : "rgb(255,255,255)";
							if (item === "peso_nf") {
								return (
									<Text
										style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
										key={key}
									>
										- {item.toUpperCase().replaceAll("_", " ")}:{" "}
										{dadosCaminhaoCaminhoneiro[item]} Kg
									</Text>
								);
							}
							if (item === "metros_nf") {
								return (
									<Text
										style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
										key={key}
									>
										- {item.toUpperCase().replaceAll("_", " ")}:{" "}
										{dadosCaminhaoCaminhoneiro[item]} m
										<Text style={styles.bodyTextOverline}>2</Text>
									</Text>
								);
							}
							if (item === "volume_nf") {
								return (
									<Text
										style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
										key={key}
									>
										- {item.toUpperCase().replaceAll("_", " ")}:{" "}
										{dadosCaminhaoCaminhoneiro[item]} m
										<Text style={styles.bodyTextOverline}>3</Text>
									</Text>
								);
							}
							return (
								<Text
									style={{ ...styles.bodyText, backgroundColor, fontSize: 8 }}
									key={key}
								>
									- {item.toUpperCase().replaceAll("_", " ")}:{" "}
									{dadosCaminhaoCaminhoneiro[item]}
								</Text>
							);
						})}
					</View>
				</View>

				{/* new page because current view has break parameter */}
				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 18,
						color: "black",
						margin: 12,
						marginBottom: 40,
						backgroundColor: "transparent",
					}}
					break
				>
					Dados dos Pedidos
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "95%",
						alignItems: "center",
						padding: 0,
						marginBottom: 15,
						marginHorizontal: 12,
					}}
				>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Qtde NF</Text>
						<Text style={{ fontSize: 14 }}>
							{formatedNumber(props.qtde_pedidos)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Qtde Produtos</Text>
						<Text style={{ fontSize: 14 }}>
							{formatedNumber(props.qtde_produtos)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Valot Total</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(romaneios.reduce((p, c) => p + c.valor_total, 0))}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Peso Total</Text>
						<Text style={{ fontSize: 14 }}>
							{formatedNumber(props.peso_total ?? 0)}
						</Text>
					</View>
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						padding: 0,
					}}
				>
					<View
						style={{ ...styles.thead, backgroundColor: "rgb(16, 25, 53)" }}
						fixed
					>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 10,
								fontSize: 6,
							}}
						>
							#
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 60,
								fontSize: 6,
							}}
						>
							Emissao
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 60,
								fontSize: 6,
							}}
						>
							Nota Fiscal
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 100,
								fontSize: 6,
							}}
						>
							Vendedor
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 100,
								fontSize: 6,
							}}
						>
							Cliente
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 150,
								fontSize: 6,
							}}
						>
							Endereço
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 80,
								fontSize: 6,
							}}
						>
							Valor
						</Text>
						{/* <Text style={{...styles.bodyText, ...styles.td, fontSize: 6}}>Peso [Kg]</Text> */}
					</View>
					{romaneios.map((item, key, self) => {
						const backgroundColor =
							key % 2 === 1 ? "rgb(122, 138, 184)" : "rgb(255,255,255)";

						return (
							<View
								style={{
									...styles.thead,
									backgroundColor: backgroundColor,
									borderTop: 0,
									color: "black",
								}}
								key={key}
							>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 10,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{key + 1}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 60,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{getCorretData(item.emissao)}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 60,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{item.numero_pedido}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{item.nome_vendedor}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
										fontSize: 6,
										paddingBottom: 5,
										paddingTop: 5,
									}}
								>
									{item.nome_fantasia}
								</Text>
								<Text
									style={{
										...styles.td,
										width: 150,
										borderRight: 0,
										fontSize: 6,
									}}
								>
									{item.endereco} - {item.bairro} - {item.cidade} - {item.cep}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										borderRight: 0,
										width: 80,
										fontSize: 6,
									}}
								>
									{valorMoeda(item.valor_total)}
								</Text>
								{/* <Text style={{...styles.bodyText, ...styles.td, borderRight:0, fontSize: 6}}>{formatedNumber(item?.peso_total ?? 0)}</Text> */}
							</View>
						);
					})}
				</View>

				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "95%",
						alignItems: "center",
						padding: 0,
						marginTop: 50,
						marginHorizontal: 12,
					}}
				>
					<View style={{ width: "40%" }}>
						<Text
							style={{ fontSize: 8, marginBottom: 10, borderBottom: 0.5 }}
						></Text>
						<Text style={{ fontSize: 8, textAlign: "center" }}>
							Gerente Geral da Industria
						</Text>
					</View>
					<View style={{ width: "40%" }}>
						<Text
							style={{ fontSize: 8, marginBottom: 10, borderBottom: 0.5 }}
						></Text>
						<Text style={{ fontSize: 8, textAlign: "center" }}>
							Gerente Logística
						</Text>
					</View>
				</View>

				<View style={styles.footerFixed} fixed>
					<View>
						<Text style={{ marginBottom: 5 }}>
							THIAGO ARAUJO -{" "}
							{new Date().toLocaleString("pt-BR", {}).replace(",", "")}
						</Text>
					</View>
					<View>
						<Text
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) =>
								`${pageNumber}/${totalPages}`
							}
						/>
					</View>
				</View>

				{/* new page because current view has break parameter */}
				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 18,
						color: "black",
						margin: 12,
						marginBottom: 40,
						backgroundColor: "transparent",
					}}
					break
				>
					Despesas do Condutor
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "95%",
						alignItems: "center",
						padding: 0,
						marginBottom: 15,
						marginHorizontal: 12,
					}}
				>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Pedágio</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo === "pedagio")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Alimentação</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo === "refeicao")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>Manutenção</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo === "manutencao")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							backgroundColor: "rgb(222, 234, 255)",
							borderRadius: 10,
							height: 60,
							width: 100,
						}}
					>
						<Text style={{ fontSize: 8, marginBottom: 10 }}>
							Total Despesas
						</Text>
						<Text style={{ fontSize: 14 }}>
							{valorMoeda(
								despesas
									.filter((item) => item.tipo !== "devolucao")
									.reduce((p, c) => p + c.valor, 0)
							)}
						</Text>
					</View>
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						padding: 0,
					}}
				>
					<View
						style={{ ...styles.thead, backgroundColor: "rgb(16, 25, 53)" }}
						fixed
					>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 10 }}>
							#
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 80 }}>
							Data
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 90 }}>
							Lançamento
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 70 }}>
							Despesa
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 100 }}>
							Forma PGTO
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 100 }}>
							Tipo Alimentação
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, border: 0 }}>
							Valor
						</Text>
					</View>
					{despesas.map((item, key, self) => {
						const backgroundColor =
							key % 2 === 1 ? "rgb(122, 138, 184)" : "rgb(255,255,255)";

						return (
							<View
								style={{
									...styles.thead,
									backgroundColor: backgroundColor,
									borderTop: 0,
									color: "black",
								}}
								key={key}
							>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 10,
										borderRight: 0,
									}}
								>
									{key + 1}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 80,
										borderRight: 0,
									}}
								>
									{item.data_despesa
										? getCorretData(item.data_despesa)
										: "Não Informada"}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 90,
										borderRight: 0,
									}}
								>
									{item.tipo_lancamento}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 70,
										borderRight: 0,
									}}
								>
									{item.tipo}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
									}}
								>
									{item.forma_pagamento}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 100,
										borderRight: 0,
									}}
								>
									{item.tipo_alimentacao ?? "---"}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: "auto",
										borderRight: 0,
									}}
								>
									{valorMoeda(item.valor)}
								</Text>
							</View>
						);
					})}
				</View>

				{/* diarios de Bordo */}
				{/* new page because current view has break parameter */}
				<Text
					style={{
						...styles.bodyText,
						textAlign: "center",
						fontSize: 18,
						color: "black",
						margin: 12,
						marginBottom: 40,
						backgroundColor: "transparent",
					}}
					break
				>
					Diário de Bordo
				</Text>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
						alignItems: "center",
						padding: 0,
					}}
				>
					<View
						style={{ ...styles.thead, backgroundColor: "rgb(16, 25, 53)" }}
						fixed
					>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 10 }}>
							#
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 80 }}>
							Data
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 120 }}>
							Horário
						</Text>
						<Text style={{ ...styles.bodyText, ...styles.td, width: 110 }}>
							Mensagem
						</Text>
						<Text
							style={{
								...styles.bodyText,
								...styles.td,
								width: 120,
								borderRight: 0,
							}}
						>
							Foto
						</Text>
					</View>
					{props.diario.map((item, key, self) => {
						const backgroundColor =
							key % 2 === 1 ? "rgb(122, 138, 184)" : "rgb(255,255,255)";
						return (
							<View
								style={{
									...styles.thead,
									backgroundColor: backgroundColor,
									border: 0,
									color: "black",
								}}
								key={key}
							>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 10,
										borderRight: 0,
									}}
								>
									{key + 1}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 80,
										borderRight: 0,
									}}
								>
									{new Date(item.data).toLocaleDateString("pt-BR", {
										timeZone: "UTC",
									})}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 120,
										borderRight: 0,
									}}
								>
									{new Date(item.data).toLocaleTimeString("pt-BR", {
										timeZone: "UTC",
									})}
								</Text>
								<Text
									style={{
										...styles.bodyText,
										...styles.td,
										width: 110,
										borderRight: 0,
									}}
								>
									{item.mensagem}
								</Text>
								<Image
									style={{ width: 200, borderRight: 0 }}
									src={locationDev + "/files/users" + item.upload_file}
								></Image>
							</View>
						);
					})}
				</View>
			</Page>
		</Document>
	);
}
