import { useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import { updateTable, verificaTipoUsuario } from "../../services/Servicos";

import "./Modal.scss";

export default function ModalErrorCarregametoSeparador({
	msg,
	title,
	show,
	setShow,
	transferencia,
	cargas_id,
}) {
	const user = useSelector((state) => state.userSlice);
	const produtosCarregados = useSelector(
		(state) => state.userSlice.produtosList.data
	).filter((item) => item.conferido && item.transferencia);

	const embalagensCarregadas = useSelector(
		(state) => state.userSlice.embalagensList.data
	).filter((item) => item.planejado);
	const boxes = useSelector((state) => state.cargaSlicer);
	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar Parcial",
	});

	const onClickFinalizarParcial = async () => {
		setLoadingFinalizar({
			flag: true,
			style: "warning",
			msg: "Aguarde",
		});

		for await (let produto of produtosCarregados.filter(
			(item) => item.planejado
		)) {
			const produtoCarregado = boxes.listaProdutos.filter(
				(item) => item.qr_sku == produto?.qr_sku
			);

			let produtoAtt = {};
			if (!produtoCarregado.length) {
				continue;
			} else {
				const [pos_z, pos_y, pos_x] = produtoCarregado[0]?.position;
				produtoAtt = {
					pos_x,
					pos_y,
					pos_z,
					step_planejado: produtoCarregado[0].step,
					user_planejamento: produto.user_planejamento,
					data_planejamento: produto.data_planejamento,
					planejado: produto.planejado,
					cor_caixa: produto.cor_caixa,
					cargas_id: cargas_id,
				};
			}

			await updateTable(
				"transferencias/produtos/atualizar",
				produto.id,
				produtoAtt
			) 
		}
		for await (const embalagem of embalagensCarregadas) {
			const embalagemCarregada = boxes.listaProdutos.filter(
				(item) => item.qr_sku == embalagem?.qr_sku
			);
			let produtoAtt = {};
			if (!embalagemCarregada.length) {
				continue;
			} else {
				const [pos_z, pos_y, pos_x] = embalagemCarregada[0]?.position;
				produtoAtt = {
					pos_x,
					pos_y,
					pos_z,
					step_planejado: embalagemCarregada[0].step,
					user_planejamento: embalagem.user_planejamento,
					data_planejamento: embalagem.data_planejamento,
					planejado: embalagem.planejado,
					cargas_id: cargas_id,
					cor_caixa: embalagem.cor_caixa,
				};
			}

			await updateTable(
				"transferencias/embalagens",
				embalagem.qr_sku,
				produtoAtt
			)

			const produtoAtt2 = {
				user_planejamento: produtoAtt.user_planejamento,
				data_planejamento: produtoAtt.data_planejamento,
				planejado: true,
				step_planejado: produtoAtt.step_planejado,
			};

			await updateTable(
				"transferencias/produtos/embalados/atualizar",
				Number(embalagem.numero_embalagem),
				produtoAtt2
			) 
			// }
		}

		const status =
			produtosCarregados.filter((item) => item.planejado).length == 0
				? 3
				: produtosCarregados.filter((item) => item.planejado).length ==
				  produtosCarregados.length
				? 5
				: 4;
		updateTable("transferencias/pedidos", "", { status, transferencia })
			.then((resp) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "success",
					msg: "Produtos Planejados!",
				}));
				setTimeout(() => {
					handlerSimpleClose();
				}, 750);
			})
			.catch((error) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "danger",
					msg: "Error!",
				}));
			});
	};
	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, flag: false }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{msg}</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loadingFinalizar.style}
								onClick={onClickFinalizarParcial}
							>
								{loadingFinalizar.msg}{" "}
								{loadingFinalizar.flag ? (
									<Spinner animation="border" variant="black" size="sm" />
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
