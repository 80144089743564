import { createRef, Fragment, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	updateCargasList,
	updateRomaneiosList,
} from "../../redux/slicers/user";
import {
	getAllData,
	updateTable,
	updateTableNew,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";

const nomeD = createRef(null);
const observacaoD = createRef(null);
const statusD = createRef(null);

export default function ModalAtualizarRomaneios({
	msg,
	title,
	show,
	setShow,
	msgButton,
}) {
	const dispatch = useDispatch();
	const [carga, setCarga] = useState({
		nome: "",
		observacao: "",
		status: 0,
	});

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: msgButton,
		style: "primary",
	});
	const user = useSelector((state) => state.userSlice);

	const updateArrayCargas = async () => {
		let id_filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo.toLowerCase() == "empresa") {
			id_filial = null;
		}
		const cargas = await getAllData("cargas", { id_filial });
		dispatch(updateCargasList(cargas.data));
	};

	const submitDataExclude = () => {
		setLoading({
			isLoading: true,
			msg: "Excluindo Romaneio",
			style: "warning",
		});

		updateTableNew(`pedidos?romaneio=${msg.bodyList.romaneio}`, {
			cargas_id: msg.bodyList.cargas_id,
			numero_pedido: msg.bodyList.numero_pedido,
			id_filial: msg.bodyList.id_filial,
			data_cancelamento: new Date().toISOString(),
		})
			.then((resp) => {
				setLoading({
					isLoading: false,
					msg: "Excluido com Sucesso",
					style: "success",
				});

				setTimeout(() => {
					updateArrayCargas();
					const romaneiosFiltered = user.romaneiosList.data.filter(
						(item) => Number(item.romaneio) !== Number(msg.msg.ROMANEIO)
					);

					dispatch(updateRomaneiosList(romaneiosFiltered));
					handlerSimpleClose();
				}, 500);
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Excluir",
						style: "primary",
					});
				}, 700);
			});
	};

	const onChangeInput = (e) => {
		e.preventDefault();

		setCarga((last) => ({
			...last,
			nome: nomeD.current.value.toUpperCase(),
			observacao: observacaoD.current.value.toUpperCase(),
			status: statusD.current.value,
		}));
	};

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{title}: {msg.msg.pk}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{title.includes("Excluir") ? <ExcluirDados msg={msg} /> : ""}
					{title.includes("Editar") ? (
						<Form>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Nome Carga:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[5].innerText}
										required
										ref={nomeD}
										onChange={onChangeInput}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Observacao:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.msg[8].innerText}
										required
										ref={observacaoD}
										onChange={onChangeInput}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Status:</Form.Label>
								</Col>
								<Col>
									<Form.Check
										type="switch"
										ref={statusD}
										onChange={onChangeInput}
									/>
								</Col>
							</Row>
						</Form>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button variant={loading.style} onClick={submitDataExclude}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									<span style={{ color: "#ffffff" }}>
										{loading.msg}
										{loading.style === "success" ? (
											<i className="bi bi-check"></i>
										) : (
											""
										)}
									</span>
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function ExcluirDados({ msg }) {
	return (
		<Fragment>
			<div>{msg.title}</div>
			<div>
				{Object.keys(msg.msg).map((key, index) => (
					<div key={index}>
						{key}:{" "}
						<b>
							<i>{msg.msg[key]}</i>
						</b>
					</div>
				))}
			</div>
		</Fragment>
	);
}
