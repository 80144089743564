import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateTable } from "../../services/Servicos";

import "./Modal.scss";

export default function ModalErrorCarregameto_old({
	msg,
	title,
	show,
	setShow,
}) {
	const produtosCarregados = useSelector(
		(state) => state.userSlice.produtosList.data
	).filter((item) => item.carregado);
	// const produtosCarregados2 = useSelector(state => state.userSlice.produtosList.data)//.filter(item => item.carregado)

	const embalagensCarregadas = useSelector(
		(state) => state.userSlice.embalagensList.data
	).filter((item) => item.carregado);
	const boxes = useSelector((state) => state.cargaSlicer);
	const [loadingFinalizar, setLoadingFinalizar] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar Parcial",
	});

	const onClickFinalizarParcial = async () => {
		setLoadingFinalizar({
			flag: true,
			style: "warning",
			msg: "Aguarde",
		});
		if (!produtosCarregados[0]?.cargas_id) return alert("Erro com a Carga");
		
		await updateTable(
			"cargas/carregada/caminhoneiro",
			produtosCarregados[0]?.cargas_id,
			{ status: 10, pedidos: null, local: "carregamento", status_carga: 10 }
		)
			.then((resp) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "success",
					msg: "Produtos Carregados!",
				}));
				setTimeout(() => {
					handlerSimpleClose();
				}, 600);
				// window.location.reload()
			})
			.catch((error) => {
				setLoadingFinalizar((last) => ({
					...last,
					flag: false,
					style: "danger",
					msg: "Error!",
				}));
			});

		// dispatch(removeAll())
		// dispatch(updateCaminhaoSelecionado({status: false}))
		// dispatch(updateProdutosList([]))
	};
	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, flag: false }));

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{msg}</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loadingFinalizar.style}
								onClick={onClickFinalizarParcial}
							>
								{loadingFinalizar.msg}{" "}
								{loadingFinalizar.flag ? (
									<Spinner animation="border" variant="black" size="sm" />
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}
