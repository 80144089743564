import { Fragment, createRef, useEffect, useRef, useState } from "react";
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	DirectionsRenderer,
	InfoWindow,
} from "@react-google-maps/api";
import "./Map.scss";
import { Button, Card, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	update,
	updateCargaSelecionada,
	updateForcarIntegracao,
	updateRomaneiosList,
} from "../../redux/slicers/user";
import {
	correctDataHoursUTC,
	correctDataUTC,
	getAllData,
	getAuthArtos,
	getDanfe,
	getDanfeVST,
	getData,
	getManifestoFileArtos,
	getManifestoId,
	getStatusCarga,
	getStatusPedidos,
	getStatusTrackingPedidos,
	getXMLString,
	locationDev,
	numerosEditadoBR,
	savalDanfeAssinada,
	uploadImg,
	valorMoeda,
	verifyConnection,
} from "../../services/Servicos";

import { QRCodeSVG } from "qrcode.react";
import ModalListarProdutosEntregador from "../modal/ModalListarProdutosEntregador";
import ModalError from "../modal/ModalError";
import ModalManifesto from "../modal/ModalManifesto";
import ModalIniciarEntrega from "../modal/ModalIniciarEntrega";
import ModalPDF_AssinarDanfe from "../modal/ModalPDF_AssinarDanfe";
import ModalVisualizarPDF from "../modal/ModalVisualizarPDF";
import ModalFotoReport from "../modal/ModalFotoReport";
import ModalUploadImg from "../modal/ModalUploadImg";
import { returnDataWithCorrection } from "../../services/helpers";
import ModalVisualizarManifesto from "../modal/ModalVisualizarManifesto";
import ModalControleDanfe from "../modal/ModalControleDanfe";
import { danfeController, fotosController } from "../../redux/slicers/carga";
import ModalControleFoto from "../modal/ModalControleFoto";
import axios from "axios";
import ModalFinalizarEntrega from "../modal/ModalFinalizarEntrega";
import BotaoSalvar from "../botoes/BotaoSalvar";
import ModalErrorPretty from "../modal/ModalErrorPretty";

const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const center = {
	lat: -15.61603896596096,
	lng: -56.068463002868285,
};

const formRef = createRef();
export default function Entregas() {
	const google = window.google;
	const [libraries] = useState(["places"]);
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
		libraries: libraries,
	});

	const [selectedMarker, setSelectedMarker] = useState(null);
	const [directions, setDirections] = useState(null);
	const [legs, setLegs] = useState([]);
	const [markers, setMarkers] = useState([]);

	const [map, setMap] = useState(null);
	const user = useSelector((state) => state.userSlice);
	const romaneios = user.romaneiosList;
	const cargasSelecionadas = user.cargaSelecionada;
	const [waypoints, setWaypoints] = useState([]);
	const [loading, setLoading] = useState({
		flag: false,
		local: "calcular",
	});
	const [currentPosition, setCurrentPosition] = useState({
		latitude: 0,
		longitude: 0,
	});
	const [startPosition, setStartPosition] = useState({
		latitude: 0,
		longitude: 0,
	});
	const [nfInfo, setNfInfo] = useState({
		id_filial: 0,
		numero_pedido: 0,
	});
	const dispatch = useDispatch();

	const cargas_id = useSelector((state) => state.userSlice.cargas_id);

	/* perguntar ao usuário sobre permissão de uso da geolocalização */
	const sucessCallbackLocation = (position) => {
		setCurrentPosition({
			latitude: position.coords.latitude,
			longitude: position.coords.longitude,
		});
		if (startPosition.latitude || startPosition.longitude) {
			setStartPosition({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			});
		}
	};

	const errorCallbackLocation = (position) => {
		//
	};

	const setRoutes = async () => {
		if (!currentPosition.latitude || !currentPosition.longitude) return;
		const nextDirection = romaneios.data.filter((item) => !item?.entregue);

		let origem = `${currentPosition.latitude}, ${currentPosition.longitude}`;
		let destino = `${nextDirection[0]?.endereco}, ${nextDirection[0]?.numero} ${nextDirection[0]?.bairro}, ${nextDirection[0]?.cidade}`; //nextDirection[0]?.cep.replace('-','').replace('.','')
		setNfInfo({
			numero_pedido: nextDirection[0]?.numero_pedido,
			id_filial: nextDirection[0]?.id_filial,
		});
		formRef.current.origem.value = origem;
		formRef.current.destino.value = destino;

		if (!origem.length && !destino.length) return;
		const directionsService = new google.maps.DirectionsService();
		const directions = await directionsService.route({
			origin: origem,
			destination: destino,
			travelMode: google.maps.TravelMode.DRIVING,
			unitSystem: google.maps.UnitSystem.METRIC,
			waypoints: waypoints.map((item) => ({ location: item, stopover: true })),
		});
		setDirections(directions);
		setLegs(directions.routes[0].legs);
		setLoading((last) => ({ ...last, flag: false, local: "" }));
	};

	const calcularRotaHandler = async (e) => {
		setLoading((last) => ({ ...last, flag: true }));
		setMarkers([]);
		navigator.geolocation.getCurrentPosition(
			sucessCallbackLocation,
			errorCallbackLocation
		);
		//
		const nextDirection = romaneios.data
			.filter((item) => item.status != 13)
			.sort((a, b) => {
				if (a.step_planejado > b.step_planejado) return 1;
				if (a.step_planejado < b.step_planejado) return -1;
				return 0;
			})
			.reverse();
		// setWaypoints(['-15.588524, -56.102531','-15.583020, -56.092483'])
		setWaypoints(
			nextDirection.slice(1, nextDirection.length).map((direction) => {
				/* montar endereço,cidade,bairro */
				const string = `${direction?.endereco}, ${direction?.bairro}, ${direction?.cidade}`;
				//
				return string;
				direction?.cep.replace(".", "").replace("-", "");
			})
		);
	};

	const resetRoutes = (e) => {
		e.preventDefault();
		setWaypoints([]);
		setDirections(null);
		setLegs([]);
		setNfInfo({
			id_filial: "",
			numero_pedido: "",
		});
		formRef.current.origem.value = "";
		formRef.current.destino.value = "";
	};

	useEffect(() => {
		setRoutes();
	}, [currentPosition]);

	const getRomaneiosEntrega = async () => {
		try {
			let romaneios = [];
			for await (let cargas of cargasSelecionadas) {
				const data = await getData("entregas/carga", cargas.id);
				romaneios.push(...data.data);
			}
			const filteredTable = romaneios.filter(
				(i, n, s) => s.findIndex((o) => o.romaneio === i.romaneio) === n
			);
			dispatch(updateRomaneiosList(filteredTable));
		} catch (error) {}
		setLoading(true);
	};
	
	useEffect(() => {
		getRomaneiosEntrega();
	}, []);

	const verifyStatusCargas = async () => {
		const cargas = await Promise.all([
			...cargasSelecionadas.map((item) =>
				getData("cargas/motorista/find-carga-disponivel-entrega", item.id)
			),
		]);
		dispatch(updateCargaSelecionada(cargas.map((item) => item.data)));
	};
	useEffect(() => {
		verifyStatusCargas();
	}, []);

	const manifesto_id = useSelector((state) => state.userSlice.manifesto_id);

	return (
		<Fragment>
			<PedidosAEntregar
				setDirections={setDirections}
				setLegs={setLegs}
				setMarkers={setMarkers}
				nfInfo={nfInfo}
				cargas_id={cargas_id}
			/>
			<div className="card-header">
				<h5>Rotas</h5>
			</div>
			<div className="map-div">
				{isLoaded ? (
					<div className="map-div-routes">
						<div className="map-div-routes-filter">
							<Form ref={formRef}>
								<Form.Group as={Row} className="mb-3 align-items-center">
									<Form.Label column sm="12" md="3">
										Partida:
									</Form.Label>
									<Col sm="10" md="7">
										<Form.Control
											type="text"
											placeholder="Partida"
											name="origem"
											disabled
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3 align-items-center">
									<Form.Label column sm="12" md="3">
										Destino:
									</Form.Label>
									<Col sm="10" md="7">
										{/* api da google para autocompletar os locais */}
										{/* <Autocomplete> */}
										<Form.Control
											type="text"
											placeholder="Destino"
											name="destino"
											disabled
										/>
										{/* </Autocomplete> */}
									</Col>
								</Form.Group>
								{waypoints.length ? (
									<Form.Group as={Row} className="mb-3">
										<Form.Label column sm="12" md="3">
											Paradas:
										</Form.Label>
										<Col sm="12" md="8">
											<div className="waypoints">
												{waypoints.map((item, index) => (
													<span className="filters-card" key={index}>
														<span className="filters">
															<span className="title">{item}</span>
															{/* <i className="bi bi-x-lg" onClick={removeParada}></i> */}
														</span>
													</span>
												))}
											</div>
										</Col>
									</Form.Group>
								) : (
									""
								)}

								{legs.length ? (
									<Form.Group as={Row} className="mb-3">
										<Form.Label column sm="12" md="3">
											Resumo:
										</Form.Label>
										<Col sm="10" md="8">
											<div className="waypoints-novo">
												{legs.map((item, index) => (
													<span className="waypoints-novo-card" key={index}>
														<span className="filters-card-duration">
															<span className="filters">
																<i className="bi bi-arrow-90deg-down"></i>
																<span className="title">
																	{item.duration.text}
																</span>
																<i className="bi bi-arrow-90deg-down rotate-rigth"></i>
															</span>
														</span>
														<span className="filters-card-distance">
															<span className="filters">
																<i className="bi bi-arrow-90deg-up"></i>
																<span className="title">
																	{item.distance.text}
																</span>
																<i className="bi bi-arrow-90deg-up rotate-rigth"></i>
															</span>
														</span>
														<span className="filters-card">
															<span className="filters filters-address">
																<span className="title">
																	{item.start_address.slice(0, 25)}
																</span>
																{/* <i className="bi bi-x-lg" onClick={removeParada}></i> */}
															</span>
														</span>
														<span className="filters-card">
															<span className="filters filters-address">
																<span className="title">
																	{item.end_address.slice(0, 25)}
																</span>
																{/* <i className="bi bi-x-lg" onClick={removeParada}></i> */}
															</span>
														</span>
													</span>
												))}
											</div>
										</Col>
									</Form.Group>
								) : (
									""
								)}
								<Row className="justify-content-end align-items-center">
									<Col sm={12} md={12} lg={6} xl={3} className="mt-2">
										<Button variant="primary" onClick={calcularRotaHandler}>
											{loading.flag && loading.local === "calcular" ? (
												<span style={{ color: "#ffffff" }}>
													Calculando{" "}
													<Spinner
														animation="border"
														variant="white"
														size="sm"
													/>
												</span>
											) : (
												"Gerar Rota"
											)}
										</Button>
									</Col>
									<Col sm={12} md={12} lg={6} xl={3} className="mt-2">
										<Button variant="secondary" onClick={resetRoutes}>
											Cancelar Rota
										</Button>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
				) : (
					""
				)}

				{isLoaded ? (
					<GoogleMap
						mapContainerStyle={{ width: "100%", height: "100%" }}
						center={center}
						zoom={12}
						options={{
							streetViewControl: false,
							mapTypeControl: false,
							fullscreenControl: true,
						}}
						onLoad={setMap}
						// onClick={testOnClickMap}
					>
						{markers.map((item, index) => {
							return (
								<Marker
									key={index}
									position={item.position}
									label={labels[index]}
								/>
							);
						})}

						<Marker
							icon={{
								// url:"https://cdn-icons-png.flaticon.com/512/4047/4047296.png",
								url: "https://cdn-icons-png.flaticon.com/512/936/936810.png",

								scaledSize: new google.maps.Size(65, 60),
							}}
							position={{
								lat: currentPosition.latitude,
								lng: currentPosition.longitude,
							}}
						/>
						{directions && (
							<DirectionsRenderer
								directions={directions}
								onClick={setSelectedMarker}
								options={{
									polylineOptions: {
										zIndex: 50,
										strokeColor: "#1976d2",
										strokeWeight: 5,
									},
								}}
							/>
						)}
						{selectedMarker && (
							<InfoWindow
								position={{
									lat: selectedMarker?.geometry?.cordinates[1],
									lng: selectedMarker?.geometry?.cordinates[0],
								}}
								onCloseClick={setSelectedMarker(null)}
							></InfoWindow>
						)}
					</GoogleMap>
				) : (
					""
				)}
			</div>
			{/* <Descarregamento/> */}
		</Fragment>
	);
}

const headList = [
	"index",
	"numero_pedido",
	"nome_fantasia",
	"danfe",
	"assinatura_offline",
	"foto",
	"status",
	"cargas_id",
	"romaneio",
	"cpf_cnpj",
	"cep",
	"endereco",
	"cidade",
	"bairro",
	"numero",
];

const tableRef = createRef();
const tbodyRef = createRef();

function PedidosAEntregar(props) {
	const dispatch = useDispatch();
	const google = window.google;
	const romaneios = useSelector((state) => state.userSlice.romaneiosList.data); //.filter(item => item.cargas_id == props.cargas_id)
	// const manifesto_id = useSelector(state => state.userSlice.manifesto_id)
	const user = useSelector((state) => state.userSlice);
	const danfes = useSelector((state) => state.cargaSlicer.listasDanfes);

	const [nfInfo, setNfInfo] = useState(null);
	const carga = useSelector((state) => state.userSlice.cargaSelecionada);

	const [danfe, setDanfe] = useState({
		view: "",
		download: "",
	});

	const [loadingManifesto, setLoadingManifesto] = useState({
		flag: false,
		msg: "Manifesto de Carga",
		style: "primary",
	});
	const [loadindBD, setLoadindBD] = useState({
		flag: false,
		msg: "Baixar Danfes",
		style: "primary",
	});

	const [manifestoDeCargaFile, setManifestoDeCargaFile] = useState({
		show: false,
		PDF: "",
	});

	const [error, setError] = useState({
		show: false,
	});
	const [iniciarEntrega, setIniciarEntrega] = useState({
		show: false,
	});
	const [error2, setError2] = useState({
		flag: false,
	});
	const [finalizarProcesso, setFinalizarProcesso] = useState({
		flag: false,
	});

	const showAllDanfes = async () => {
		if (!carga.length) {
			return setError2({
				flag: true,
				list: [`msg: Nenhuma carga selecionada!`],
				title: "Aviso",
			});
		}
		if (danfes.length === romaneios.filter(i => !i.danfe).length) {
			return setError({
				show: true,
				message: 'Todas as Danfes foram Baixadas',
			});
		}

		setLoadindBD({
			flag: true,
			msg: "Baixando",
			style: "warning",
		});

		for await (let item of romaneios) {
			if (
				danfes.find(
					(danfe) =>
						danfe.numero_pedido == item.numero_pedido &&
						danfe.id_filial == item.id_filial
				)
			)
				continue;
			if (item.danfe) continue;
			await getDanfeVST(item.id_filial, item.numero_pedido)
				.then((resp) => {
					dispatch(
						danfeController({
							action: "add",
							dados: {
								numero_pedido: item.numero_pedido,
								id_filial: item.id_filial,
								cargas_id: item.cargas_id,
								danfe: new Uint8Array(resp),
								danfe_assinada: [],
								status: "NÃO ASSINADA",
							},
						})
					);
				})
				.catch((err) => {
					setError2({
						flag: true,
						list: {
							error: `Danfe não baixada do VST. Pedido ${item.numero_pedido}`,
							...err,
						},
						title: "Aviso",
					});
				});
		}

		setLoadindBD({
			flag: false,
			msg: "Concluido",
			style: "success",
		});

		setTimeout(() => {
			setLoadindBD({
				flag: false,
				msg: "Danfes Baixadas",
				style: "success",
			});
		}, 500);
	};

	useEffect(() => {
		if (!nfInfo?.numero_pedido || !nfInfo?.id_filial) {
			setDanfe({
				view: "",
				download: "",
			});
			return;
		}

		// findXml()
	}, [nfInfo]);

	const onClickIniciarEntrega = (e) => {
		const data = new Date();

		if (!carga.length) {
			return setError2({
				flag: true,
				list: {
					tipo: "Erro",
					alerta: "Proibido iniciar Entrega",
					motivo: "Não existe carga para ser entregue!",
				},
				title: "Atenção",
			});
		}
		if (romaneios.find((item) => item.status < 11)) {
			setError2({
				flag: true,
				list: {
					tipo: "Erro",
					alerta: "Proibido iniciar Entrega",
					motivo: "Tentativa de Inicio de Entrega com produtos A CARREGAR",
					// ...romaneios.filter(item => item.status < 11).map(item => ({ pedido: item.numero_pedido }))
				},
				title: "Atenção",
			});
			return;
		}
		if (carga.find((item) => item.data_inicio_entrega)) {
			const cargaI = carga.find((item) => item.data_inicio_entrega);
			dispatch(
				update({
					processo_entrega: {
						flag: true,
					},
				})
			);
			return setError2({
				flag: true,
				list: {
					tipo: "Erro",
					alerta: "Proibido iniciar Entrega",
					motivo: `Entrega iniciada em ${new Date(
						cargaI.data_inicio_entrega
					).toLocaleString("pt-br")}`,
				},
				title: "Atenção",
			});
		}

		setIniciarEntrega({
			show: true,
			msg: `Você esta iniciando a Entrega! Você Confirma ter recebido o dinheiro para entregar a carga?`,
			title: "Inicio da Entrega",
			cargas_id: user.cargas_id,
			donwloadDanfes: showAllDanfes,
		});
	};

	useEffect(() => {
		if (
			danfes.length &&
			danfes.length >= romaneios.filter((item) => !item.danfe).length
		) {
			setLoadindBD({
				flag: false,
				msg: "Danfes Baixadas",
				style: "success",
			});
		}
	}, []);

	let fotos = useSelector((state) => state.cargaSlicer.listasFotos);
	// automação de envio de danfes caso internet do usuario caia e ele se esqueca de enviar!
	const enviarDanfeAssinada = async () => {
		//se todas foram salvar no banco de dados, retorne imediatamente
		if (!romaneios.find((romaneio) => !romaneio.danfe)) return true;

		const blobToFile = (blob, fileName) => {
			blob.lastModifiedDate = new Date();
			blob.name = fileName;
			const file = new File([blob], `${fileName}.pdf`);
			return file;
		};

		const danfesNaoAssinadas = danfes.filter(
			(item) =>
				item.status.toUpperCase() != "ASSINADA" &&
				item.status.toUpperCase() != "ENVIADA"
		);
		if (danfesNaoAssinadas.length) {
			setError2({
				flag: true,
				list: `Danfe do pedido ${danfesNaoAssinadas
					.map((item) => item.numero_pedido)
					.join(", ")} não assinada!`,
				title: "Aviso",
			});
			return false;
		}
		for await (let danfe of danfes.filter(
			(item) => item.status.toUpperCase() == "ASSINADA"
		)) {
			const blob = new Blob([danfe.danfe_assinada], {
				type: "application/pdf",
			});

			try {
				await savalDanfeAssinada({
					file: blobToFile(blob, `${danfe.numero_pedido}`),
					teste: new Date().toLocaleTimeString(),
					cargas_id: danfe.cargas_id,
					numero_pedido: danfe.numero_pedido,
					caminhoneiro: user.cpf,
					caminhao: user.caminhaoSelecionado.caminhao_placa,
				});
				dispatch(
					danfeController({
						action: "update_status",
						numero_pedido: danfe.numero_pedido,
						id_filial: danfe.id_filial,
						status: "ENVIADA",
					})
				);
			} catch (error) {
				console.log({
					error: "Tentativa de envio de Danfe para o DB",
					...error,
				});
			}
		}
		return true;
	};

	// automação de envio das danfes caso internet do usuario caia e ele se esqueca de enviar!
	const enviarFotos = async () => {
		function dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(","),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[arr.length - 1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		}

		// })))
		// return
		let error = null;

		for await (let fotoL of fotos.filter(
			(item) => item.status.toLowerCase() != "enviada"
		)) {
			try {
				const data = await uploadImg(
					"entregas/pedido/upload_img",
					fotoL.cargas_id,
					fotoL.numero_pedido,
					{ foto: dataURLtoFile(fotoL.imgUrl, `Image-${fotoL.numero_pedido}`) }
				);
				dispatch(
					fotosController({
						action: "update",
						id: fotoL.id,
						dados: {
							status: "Enviada",
						},
					})
				);
			} catch (error) {
				error = error;
			}
		}
		if (error) {
			return;
		}
	};

	const handlerFinalizarEntrega = async (e) => {
		if (!user.cargaSelecionada.length) {
			return setError2({
				flag: true,
				list: {
					tipo: "Erro",
					alerta: "Proibido finalizar Entrega",
					motivo: "Não existe carga para ser entregue!",
					solucao: "Contate o Gestor da Logistica",
				},
				title: "Erro ao Finalizar Entrega",
			});
		}
		if (romaneios.find((item) => item.status < 13)) {
			return setError2({
				flag: true,
				list: {
					title: "Erro ao Finalizar Entrega",
					motivo: "Carga com pedidos não entregue!",
					solucao:
						"Finalize o Descarregamento para atualizar o status da Carga",
				},
				title: "Erro",
			});
		}

		setBtnFinalizarEntrega({ msg: "Enviando danfes", style: "warning" });
		const danf = await enviarDanfeAssinada();
		if (!danf)
			return setBtnFinalizarEntrega({
				msg: "Finalizar Entrega",
				style: "primary",
			});

		setBtnFinalizarEntrega({ msg: "Enviando fotos", style: "warning" });
		await enviarFotos();
		setBtnFinalizarEntrega({ msg: "Finalizar Entrega", style: "primary" });
		setFinalizarProcesso({
			flag: true,
			msg: `Você esta Finalizando a Entrega!? Clique no botão apenas se voce JÁ CHEGOU na origem!`,
			title: "Chegada do Caminhão a Origem da Entrega",
			cargas_id: user.cargas_id,
		});
	};

	const [btnFinalizarEntrega, setBtnFinalizarEntrega] = useState({
		style: "primary",
		msg: "Finalizar Entrega",
	});

	const [forceIntegration, setForceIntegration] = useState({ show: false })
	const handleOpenForceIntegration = () => {
		setForceIntegration({show: true, bodyList: { danfes, romaneios, fotos }})
	}
	return (
		<Fragment>
			<Row>
				<Col sm="12" md="8">
					<Card className="p-4">
						<Card.Title className="card-header">
							<h5>Romaneios</h5>
						</Card.Title>
						<Card.Body className="p-0">
							<div
								className=" responsive-table"
								ref={tableRef}
								style={{ position: "relative", height: "500px" }}
							>
								<Table hover size="sm" bordered>
									<thead>
										<tr>
											{headList.map((item, index) => {
												if (item === "index") {
													return <th key={index}>#</th>;
												} else if (item.toUpperCase() === "CREATED_AT") {
													return <th key={index}>DATA DE CRIAÇÃO</th>;
												} else if (item.toUpperCase() === "UPDATED_AT") {
													return <th key={index}>ULTIMA ATUALIZAÇÃO</th>;
												}
												return <th key={index}>{item.toUpperCase()}</th>;
											})}
										</tr>
									</thead>
									<tbody ref={tbodyRef}>
										{[...romaneios]
											.sort((a, b) => {
												if (a.step_planejado > b.step_planejado) return 1;
												if (a.step_planejado < b.step_planejado) return -1;
												return 0;
											})
											.reverse()
											.map((item, index) => {
												// if(index < indexList){
												return (
													<BodyRowRomaneios
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={romaneios.length}
														cargas_id={props.cargas_id}
													/>
												);
												// }
											})}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col sm="12" md="4">
					<Card className="p-4">
						<Card.Title className="card-header">
							<h5>Nota Fiscal Da Entrega</h5>
						</Card.Title>
						<Card.Body
							style={{
								margin: "auto",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Button
								variant={loadindBD.style}
								className="mb-3"
								onClick={showAllDanfes}
							>
								{loadindBD.msg}
								{loadindBD.flag ? <Spinner size="sm" animation="border" /> : ""}
								{loadindBD.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>

							<Button
								variant={loadingManifesto.style}
								// onClick={getManifestoCarga}
								disabled
							>
								{loadingManifesto.flag ? (
									<>
										{loadingManifesto.msg}{" "}
										<Spinner animation="border" size="sm" />
									</>
								) : (
									loadingManifesto.msg
								)}
								{loadingManifesto.style === "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Card.Body>
					</Card>
					<Card className="p-4">
						<Card.Title className="card-header">
							<h5>Entregas</h5>
						</Card.Title>
						<Card.Body
							style={{
								margin: "auto",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Button
								variant="primary"
								className="my-2"
								disabled={
									user.cargaSelecionada[0]?.data_inicio_entrega ? true : false
								}
								onClick={onClickIniciarEntrega}
							>
								{user.cargaSelecionada[0]?.data_inicio_entrega
									? `Entrega Iniciada: ${returnDataWithCorrection(
											user.cargaSelecionada[0]?.data_inicio_entrega
									  ).toLocaleString("pt-br", { timeZone: "UTC" })}`
									: "Iniciar Entrega"}
							</Button>
							<BotaoSalvar
								style={btnFinalizarEntrega.style}
								msg={btnFinalizarEntrega.msg}
								fnRetorno={handlerFinalizarEntrega}
							/>
							<Button variant="dark" className="my-2" onClick={handleOpenForceIntegration}>Forçar Integração</Button>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{manifestoDeCargaFile.show ? (
				<ModalVisualizarManifesto
					title={"Manifesto de Carga"}
					pdf={manifestoDeCargaFile.PDF}
					setShow={setManifestoDeCargaFile}
					show={manifestoDeCargaFile.show}
				/>
			) : (
				""
			)}

			{iniciarEntrega.show ? (
				<ModalIniciarEntrega
					setShow={setIniciarEntrega}
					{...iniciarEntrega}
					processo={"data_inicio_entrega"}
					downloadDanfes={showAllDanfes}
				/>
			) : (
				""
			)}
			{finalizarProcesso.flag ? (
				<ModalFinalizarEntrega
					title={finalizarProcesso.title}
					msg={finalizarProcesso.msg}
					setShow={setFinalizarProcesso}
					show={finalizarProcesso.flag}
					cargas_id={finalizarProcesso.cargas_id}
					processo={"data_chegada"}
				/>
			) : (
				""
			)}
			{error2.flag ? (
				<ModalError show={error2.flag} setShow={setError2} {...error2} />
			) : (
				""
			)}

			{error.show ? (
				<ModalErrorPretty setShow={setError} {...error} />
			) : (
				""
			)}

			{forceIntegration.show ? <ModalForcarIntegracao setShow={setForceIntegration} {...forceIntegration} /> : ""}
		</Fragment>
	);
}

function BodyRowRomaneios({
	headList,
	bodyList,
	index,
	tableLength,
	cargas_id,
	setCargaSelecionada = () => {},
}) {
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const user = useSelector((state) => state.userSlice);
	const carga = useSelector((state) => state.cargaSlicer);
	const [handlerControleDanfe, setHandlerControleDanfe] = useState({
		flag: false,
	});
	const [handlerControleFoto, setHandlerControleFoto] = useState({
		flag: false,
	});
	const [error, setError] = useState({
		flag: false,
	});

	const [produtosModal, setProdutosModal] = useState({
		flag: false,
		numero_pedido: "",
	});

	const [assinarDocumento, setAssinarDocumento] = useState(false);

	const showPdfToSign = async (e, data) => {
		e.preventDefault();
		const numero_pedido = data.numero_pedido;
		const id_filial = data.id_filial;

		const arayBuffer = await getDanfeVST(id_filial, numero_pedido);
		var link = document.createElement("a");
		link.href = "data:application/pdf;base64," + encodeURI(arayBuffer);
		link.download = `danfe_${numero_pedido}.pdf`;
		link.dispatchEvent(new MouseEvent("click"));
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}

		const id = refRow.current.children[1].innerText;
		const nome = refRow.current.children[2].innerText;
		setCargaSelecionada({ id, nome });
	};

	const onClickPlusRow = (e) => {
		e.preventDefault();
		setProdutosModal({
			flag: true,
			numero_pedido: bodyList.numero_pedido,
			cargas_id: bodyList.cargas_id,
		});
	};

	const findDanfe = async (e, numero_pedido, id_filial, NOME_FANTASIA) => {
		if (!bodyList.foto)
			return setError({
				flag: true,
				msg: `Impossível assinar Danfe sem FOTO do PRODUTO ENTREGUE!`,
				title: "AVISO",
			});
		setError((last) => ({ ...last, numero_pedido }));

		setAssinarDocumento({
			flag: true,
			PDF: await getDanfeVST(id_filial, numero_pedido),
			cliente: NOME_FANTASIA,
			pedido: bodyList,
		});

		setError((last) => ({
			...last,
			numero_pedido: "",
		}));
	};

	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});

	const [fotoProdutoEntregue, setFotoProdutoEntregue] = useState({
		flag: false,
		imgSrc: "",
	});

	const [uploadImg, setUploadImg] = useState({ flag: false });

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "assinatura_offline") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											{!bodyList?.danfe?.length ? (
												error?.numero_pedido === bodyList.numero_pedido ? (
													<Spinner
														animation="border"
														size="sm"
														color={"black"}
													/>
												) : (
													<i
														className="bi bi-gear"
														style={{ cursor: "pointer" }}
														onClick={() => {
															if (!carga.listasDanfes.length)
																return setError({
																	flag: true,
																	list: ["Danfe não Baixada!"],
																	title: "Aviso",
																});
															if (
																!carga.listasDanfes.find(
																	(item) =>
																		item.numero_pedido ==
																			bodyList.numero_pedido &&
																		item.id_filial == bodyList.id_filial
																)
															)
																return setError({
																	flag: true,
																	list: ["Danfe não Baixada!"],
																	title: "Aviso",
																});
															setHandlerControleDanfe({
																flag: true,
																...bodyList,
															});
														}}
													></i>
												)
											) : (
												<i
													className="bi bi-bookmark-check"
													style={{ cursor: "default" }}
												></i>
											)}
										</span>
									</div>
								</td>
							);
						} else if (item === "assinar") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											{!bodyList?.danfe?.length ? (
												error?.numero_pedido === bodyList.numero_pedido ? (
													<Spinner
														animation="border"
														size="sm"
														color={"black"}
													/>
												) : (
													<i
														className="bi bi-vector-pen"
														title="Assinar Canhoto"
														onClick={(e) =>
															findDanfe(
																e,
																bodyList.numero_pedido,
																bodyList.id_filial,
																bodyList.nome_fantasia
															)
														}
													></i>
												)
											) : (
												<i
													className="bi bi-bookmark-check"
													style={{ cursor: "default" }}
												></i>
											)}
											{/* {
                                            } */}
										</span>
									</div>
								</td>
							);
						} else if (item === "valor") {
							return <td key={_index}>{valorMoeda(bodyList.valor)}</td>;
						} else if (item === "foto") {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									<div
										className="admin"
										// style={{ width: "10vw", border: "0.2px solid #0000003f" }}
									>
										<span className="excluir">
											<i
												className="bi bi-gear me-2"
												style={{ cursor: "pointer" }}
												onClick={() =>
													setHandlerControleFoto({
														flag: true,
														...bodyList,
													})
												}
											></i>
											{bodyList.foto ? bodyList.foto : 0}
										</span>
									</div>
								</td>
							);
						} else if (item === "danfe") {
							return (
								<td key={_index}>
									<div className="admin">
										<span
											className="excluir"
											onClick={(e) => showPdfToSign(e, bodyList)}
										>
											<i className="bi bi-download" title="Download"></i>
										</span>
										{!bodyList?.danfe?.length ? (
											""
										) : (
											<span className="excluir">
												<i
													className="bi bi-file-earmark-pdf"
													onClick={(e) => {
														setVerPDF({
															show: true,
															PDF:
																locationDev +
																"/files/financeiro/danfes/" +
																bodyList[item],
															title: "Danfe Assinada",
														});
													}}
													style={{ cursor: "pointer" }}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item === "romaneio") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="verificar">
											<i
												className="bi bi-plus-circle"
												title="Verificar Produtos"
												onClick={onClickPlusRow}
												ref={refEditar}
											></i>
										</span>
										<span style={{ fontSize: "0.9rem" }}>
											{String(bodyList[item]).padStart(6, "0")}
										</span>
									</div>
								</td>
							);
						} else if (item === "emissao") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.emissao)}
								</td>
							);
						} else if (item === "updated_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.updatedAt)}
								</td>
							);
						} else if (item === "created_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.createdAt)}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{getStatusPedidos(bodyList.status)}
								</td>
							);
						} else if (
							item === "nome_razao" ||
							item === "endereco" ||
							item === "cpf_cnpj" ||
							item === "cep"
						) {
							/* primary key can not be modified */
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList[item]}
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_code_id + "}"} />
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{produtosModal.flag ? (
				<ModalListarProdutosEntregador
					msg={""}
					title={"Produtos do Pedido"}
					show={produtosModal}
					setShow={setProdutosModal}
					pedido={produtosModal.numero_pedido}
					cargas_id={produtosModal.cargas_id}
				/>
			) : (
				""
			)}
			{assinarDocumento.flag ? (
				<ModalPDF_AssinarDanfe
					title={"Assinar PDF"}
					pdf={assinarDocumento.PDF}
					setShow={setAssinarDocumento}
					show={assinarDocumento}
					cliente={assinarDocumento.cliente}
					{...assinarDocumento.pedido}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					title={error.title}
					msg={error.msg}
					setShow={setError}
					show={error.flag}
					{...error}
				/>
			) : (
				""
			)}

			{verPDF.show ? (
				<ModalVisualizarPDF
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.show}
				/>
			) : (
				""
			)}
			{fotoProdutoEntregue.flag ? (
				<ModalFotoReport
					imgSrc={fotoProdutoEntregue.imgSrc}
					setShow={setFotoProdutoEntregue}
					show={fotoProdutoEntregue.flag}
					alternative={fotoProdutoEntregue.alternative}
				/>
			) : (
				""
			)}
			{uploadImg.show ? (
				<ModalUploadImg
					show={uploadImg.show}
					setShow={setUploadImg}
					{...uploadImg}
					title="Salvar Imagem do Produto Entregue"
				/>
			) : (
				""
			)}

			{handlerControleDanfe.flag ? (
				<ModalControleDanfe
					show={handlerControleDanfe.flag}
					setShow={setHandlerControleDanfe}
					{...handlerControleDanfe}
				/>
			) : (
				""
			)}
			{handlerControleFoto.flag ? (
				<ModalControleFoto
					show={handlerControleFoto.flag}
					setShow={setHandlerControleFoto}
					{...handlerControleFoto}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function ModalForcarIntegracao({ show, setShow, bodyList}) {

	const [fotosEnviadas, setFotosEnviadas] = useState([])
	const [showImage, setShowImage] = useState({})
	
	const [error, setError] = useState({
		show: false,
	});
	const [BTNEnviar, setBTNEnviar] = useState({
		message: 'Forçar',
		style: 'dark'
	});

	const handlerSimpleClose = () => setShow((last) => ({ ...last, show: false }));

	const user = useSelector((state) => state.userSlice);
	let fotos = useSelector((state) => state.cargaSlicer.listasFotos);
	const danfes = useSelector((state) => state.cargaSlicer.listasDanfes);

	const dispatch = useDispatch();

	useEffect(() => {
		async function getFotosEnviadas() {
			if (navigator.onLine) {
				try {
					const allFotos = await Promise.all(bodyList.romaneios.map(async rom => ({
						...rom,
						fotos_enviadas: await getAllData(`entregas/pedido/show-imgs/${rom.cargas_id}/${rom.numero_pedido}`)
					})))
					dispatch(updateRomaneiosList(allFotos));
					const fotosEnviadasL = allFotos.filter(i => i.fotos_enviadas?.data?.length).map(item => item.fotos_enviadas.data).flat()
					setFotosEnviadas(fotosEnviadasL)
				} catch (error) {
					console.log(error)
				}
			}
		}
		getFotosEnviadas()
	}, [navigator.onLine, fotos])

	const handlerForcarIntegracao = async () => {
		if(!navigator.onLine) 
			return setError({
				show: true,
				message: "Você esta sem internet!",
			});
		
		if (!fotos.find((item) => item.status != "Enviada"))
			return setError({
				show: true,
				message: "Nenhuma foto precisa ser enviada!",
			});
		
		
		setBTNEnviar({
			message: "Enviando",
			style: "warning",
		});
		
		function dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(","),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[arr.length - 1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		}

		let error = null;

		for await (let fotoL of fotos.filter((item) => item.status != "Enviada")) {
			try {
				const data = await uploadImg(
					"entregas/pedido/upload_img",
					fotoL.cargas_id,
					fotoL.numero_pedido,
					{ foto: dataURLtoFile(fotoL.imgUrl, `Image-${fotoL.numero_pedido}`) }
				);
				dispatch(
					fotosController({
						action: "update",
						id: fotoL.id,
						dados: {
							status: "Enviada",
						},
					})
				);
			} catch (error) {
				error = error;
				break;
			}
		}

		if (error) {
			setError({
				show: true,
				message: "Falha na tentativa de envio das fotos",
			});
			return;
		}

		setBTNEnviar({
			message: "Enviado",
			style: "success",
		});
		setTimeout(() => {
			window.location.replace('/map')
		}, 1000)

		
	}

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
				style={{
					backdropFilter: "brightness(0.3)",
				}}
			>
				<Modal.Header closeButton>
					<h4>Forçar Integração{ navigator.onLine ? ' - Online' : ' - Sem internet'}</h4>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: '#80808026', overflow: 'auto', height: '80vh' }}>
					<div className="mb-4 d-flex justify-content-center" /* style={{position:'sticky', top:'0', zIndex:'100'}} */>
						<BotaoSalvar fnRetorno={handlerForcarIntegracao} msg={BTNEnviar.message} style={BTNEnviar.style}/>
					</div>
					<div className="card-header">
						<h5>Notas Fiscais</h5>
					</div>
					<>
						{ 
							danfes.map((item, key) => 
								<Card className="p-3 mb-3 monitoramento-pedido-list" hidden={ bodyList?.romaneios.find(p => p.numero_pedido == item.numero_pedido && p.danfe) }>
								<Row className="align-items-center p-1 justify-content-between">
									<Col sm="2" className="">
										<div className="d-flex align-items-cente">
											<i
												className="bi bi-file-earmark-pdf"
												style={{ fontSize: "2.5rem" }}
											></i>
											<div style={{ marginLeft: "7px" }}>
												<div
													className="text-muted"
													style={{ fontSize: "1rem" }}
												>
													Pedido N°{item.numero_pedido}
												</div>
											</div>
										</div>
									</Col>
									<Col sm="3" className="text-center">
										<div>LOCAL</div>
									</Col>
									<Col sm="3">
											<div
												style={{
													fontSize: "0.8rem",
													padding: "7px 15px",
													borderRadius: "10px",
													textAlign:'center',
													backgroundColor: item.status == 'NÃO ASSINADA' ? "#ff6767" : item.status == 'ASSINADA' ? "#c3e100" : "#00c66a",
												}}
											>
												{ item.status }
											</div>
									</Col>
								</Row>
							</Card>
							)
						}
						{ 
							bodyList?.romaneios.filter(i => i.danfe).map((item, key) => 
							<Card className="p-3 mb-3 monitoramento-pedido-list">
								<Row className="align-items-center p-1 justify-content-between">
									<Col sm="2" className="">
										<div className="d-flex align-items-cente">
											<i
												className="bi bi-file-earmark-pdf"
												style={{ fontSize: "2.5rem" }}
											></i>
											<div style={{ marginLeft: "7px" }}>
												<div
													className="text-muted"
													style={{ fontSize: "1rem" }}
												>
													Pedido N°{item.numero_pedido}
												</div>
											</div>
										</div>
									</Col>
									<Col sm="3" className="text-center">
										<div>SERVIDOR</div>
									</Col>
									<Col sm="3">
											<div
												style={{
													fontSize: "0.8rem",
													padding: "7px 15px",
													borderRadius: "10px",
													backgroundColor: "#00c66a",
													textAlign:'center'
												}}
											>
												ENVIADO
											</div>
									</Col>
								</Row>
							</Card>
							)
						}
					</>

					<div className="card-header">
						<h5>Fotos</h5>
					</div>
					<>
					{ 
						fotos.map((item, key) => 
							<Card onClick={() => setShowImage({ show: true, pedido: item })}
								className="p-3 mb-3 monitoramento-pedido-list"
								hidden={fotosEnviadas.find(p => p.numero_pedido == item.numero_pedido && item.status.toUpperCase() == 'ENVIADA')}>
							<Row className="align-items-center p-1 justify-content-between">
								<Col sm="2" className="">
									<div className="d-flex align-items-cente">
										<i
											className="bi bi-image"
											style={{ fontSize: "2.5rem" }}
										></i>
										<div style={{ marginLeft: "7px" }}>
											<div
												className="text-muted"
												style={{ fontSize: "1rem" }}
											>
												Pedido N°{item.numero_pedido}
											</div>
										</div>
									</div>
								</Col>
								<Col sm="3" className="text-center">
									<div>LOCAL</div>
								</Col>
								<Col sm="3">
										<div
											style={{
												fontSize: "0.8rem",
												padding: "7px 15px",
												borderRadius: "10px",
												backgroundColor: item.status.toUpperCase() == 'NÃO ENVIADA' ? "#c3e100" : '#000',
												color: "#fff",
												textAlign:'center'
											}}
										>
											{ item.status.toUpperCase()}
										</div>
								</Col>
							</Row>
						</Card>
						)
					}
					{ 
						fotosEnviadas.map((item, key) => 
						<Card className="p-3 mb-3 monitoramento-pedido-list" onClick={() => setShowImage({show: true, pedido: item})}>
							<Row className="align-items-center p-1 justify-content-between">
								<Col sm="2" className="">
									<div className="d-flex align-items-cente">
										<i
											className="bi bi-image"
											style={{ fontSize: "2.5rem" }}
										></i>
										<div style={{ marginLeft: "7px" }}>
											<div
												className="text-muted"
												style={{ fontSize: "1rem" }}
											>
												Pedido N°{item.numero_pedido}
											</div>
										</div>
									</div>
								</Col>
								<Col sm="3" className="text-center">
									<div>SERVIDOR</div>
								</Col>
								<Col sm="3">
										<div
											style={{
												fontSize: "0.8rem",
												padding: "7px 15px",
												borderRadius: "10px",
												backgroundColor: "#00c66a",
												textAlign:'center'
											}}
										>
											ENVIADO
										</div>
								</Col>
							</Row>
						</Card>
						)
					}
					</>
				</Modal.Body>
				{ 
					showImage.show ? <ModalListarFotos setShow={setShowImage} {...showImage} /> : ""
				}
				{error.show ? (
					<ModalErrorPretty setShow={setError} {...error} />
				) : (
					""
				)}
			</Modal>
		</>
	);
}

function ModalListarFotos({ show, setShow, pedido }) {
	const handlerSimpleClose = () => setShow((last) => ({ ...last, show: false }));
	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
				style={{
					backdropFilter: "brightness(0.3)",
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Foto {pedido.imgUrl ? pedido.status.toUpperCase() : 'ENVIADA'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "auto", maxHeight:'70vh' }}>
					<Row>
						<Col>
							<img
								src={pedido.imgUrl || `${locationDev}/files/users/entregas/carga_${pedido.cargas_id}/${pedido.foto}`}
								style={{
									width: "100%",
									objectFit: "contain",
									border: "solid 0.1px black",
									boxShadow: "0 0 20px 0 #0000007f",
								}} /* width={'80%'} height={'100%'} */
							/>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	);
}