import PlanoLositicaLayout from "../../planoLogistica/PlanoLositicaLayout";
import { useEffect, useRef, useState } from "react";
import {
	GoogleMap,
	useJsApiLoader,
	Marker,
	InfoWindow,
} from "@react-google-maps/api";
import "../../entregas/Map.scss";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import {
	getAllData,
	getCorretData,
	getData,
	getStatusPedidos,
	numerosDecimal,
	valorMoeda,
} from "../../../services/Servicos";

import { useDispatch, useSelector } from "react-redux";
import ModalError from "../../modal/ModalError";
import { getPrettierNumber } from "../../../services/helpers";

const center = {
	lat: -15.61603896596096,
	lng: -56.068463002868285,
};
export default function Monitoramento() {
	const google = window.google;
	const [libraries] = useState(["places"]);
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
		libraries: libraries,
	});
	const locations = useSelector((state) => state.userSlice.locationsList);

	const [map, setMap] = useState(/** @type google.maps.Map */ (null));
	const [infoClicked, setInfoClicked] = useState(false);
	const [markers, setMarkers] = useState([]);
	const [endereco, setEndereco] = useState("");
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState({
		flag: false,
		msg: "",
	});
	const infoClickedRef = useRef(null);
	const user = useSelector((state) => state.userSlice);
	const motoristas = user.funcionariosList?.data?.filter(item => item.tipo == 'Caminhoneiro') || []

	const getCode = async (location) => {
		const geocoder = new google.maps.Geocoder();
		const location_ret = await geocoder.geocode({
			location: location.position,
		});

		return location_ret.results[0].formatted_address;
	};

	const selectMarker = (e) => {
		const position = { position: { lat: e.lat, lng: e.lng } };
		e.position = position;
		getCode(e.position);
		setInfoClicked(e);
	};

	useEffect(() => {
		if(!isLoaded) return // verifica se api do maps carregou!
		async function getData() {
			setLoading(true);
			try {
				const {data}= await getAllData(
					"dashboard/empresa/monitoramento-entregas"
				);
				if (!data) return;

				let locations = data
					.map((item) => ({
						...item,
						cargas_id: item.cargas_id,
						// lat: item.lat,// || -15.6401388,
						// lng: item.lng,// || -56.0259115,
						volume_atual: getPrettierNumber(item.metros_total - item.pedidos_entregue?.metros),
						peso_atual: getPrettierNumber(item.peso_atual),
						volume_total: getPrettierNumber(item.volume_total),
						peso_total: getPrettierNumber(item.peso_total),
						peso_entregue: getPrettierNumber(item.pedidos_entregue.peso),
						volume_entregue: getPrettierNumber(item.metros_total - item.pedidos_entregue?.metros),
						metros_total: getPrettierNumber(item.metros_total),
						caminhoneiro:	motoristas.find(({cpf}) => cpf == item.motorista)?.name,
					}));

				locations = await Promise.all(
					locations.map(async location => ({
						...location,
						location_name: location.location ? await getCode({ position: { lat: location.location.lat, lng: location.location.lng }}) : 'Sem Registro'
					}))
				)
				setMarkers(locations);
			} catch (error) {
				// setError({show: true, message: getErrorMessage(error)})
			}
			setLoading(false);
		}
		const myClock = setInterval(async () => {
			await getData()
		}, 10000);

		return () => {
			clearInterval(myClock);
		};
	}, [isLoaded]);

	// useEffect(() => {
	//     // ))
	//     // setMarkers(locations.data)
	//
	// }, [locations])

	const [manutencao, setManutencao] = useState({ flag: true });
	const [styledButton, setStyledButton] = useState(false);
	const [cardTruckSelected, setCardTruckSelected] = useState(null);

	const selectCardInfos = (id) => {
		// e.preventDefault()
		if (cardTruckSelected == id) return setCardTruckSelected(null);
		document.getElementById(`${id}`).scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
		setCardTruckSelected(id);
	};


	return (
		<PlanoLositicaLayout>
			<div className="map-div" style={{ height: "80vh" }}>
				{isLoaded ? (
					<div className="map-div-routes" style={{ width: "auto" }}>
						<div
							className={`map-div-monitoramentos  ${
								styledButton ? "" : "hide-card"
							}`}
						>
							<div className="card-header">
								<h5>
									Monitoramentos{" "}
									{loading ? (
										<Spinner size="sm" color="black" animation="border" />
									) : (
										""
									)}
								</h5>
								<i
									className={`${
										styledButton
											? "bi bi-x-circle-fill"
											: "bi bi-plus-circle-fill"
									}`}
									onClick={(e) => setStyledButton(!styledButton)}
								></i>
							</div>
							{markers.map((item, key) => {
								return (
									<div
										key={key}
										onClick={(e) => selectCardInfos(item.cargas_id)}
										style={{ marginBottom: "20px" }}
										id={item.cargas_id}
									>
										<Row
											className={`card-truck ${
												cardTruckSelected == item.cargas_id
													? "show-card-truck-infos"
													: ""
											}`}
										>
											<h4 className="card-header mb-4" style={{ width: "90%" }}>
												Carga {item.cargas_id}, Caminhão {item.caminhao},
												Motorista {item.caminhoneiro}
											</h4>
											<Col sm="12">
												<Row>
													<Col sm="12">
														<div className="card-info">
															<i className="bi bi-geo-alt"></i>
															<div className="card-title">
																<span className="title">
																	Última Localização
																</span>
																<span className="msg">
																	{item.location_name}
																</span>
															</div>
														</div>
													</Col>
													<Col sm="12"></Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card-info">
															<i className="bi bi-stopwatch"></i>
															<div className="card-title">
																<span className="title">
																	Última Atualização
																</span>
																<span className="msg">
																	{item.data_inicio_entrega}
																</span>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card-info">
															<i className="bi bi-truck"></i>
															<div className="card-title">
																<span className="title">Peso Atual</span>
																<span className="msg">
																	{item.peso_atual} [kg]
																</span>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card-info">
															<i className="bi bi-bounding-box"></i>
															<div className="card-title">
																<span className="title">Volume Atual </span>
																<span className="msg">
																	{item.volume_entregue} [m<sup>2</sup>]
																</span>
															</div>
														</div>
													</Col>
												</Row>

												<h6 className="card-header mt-4 mb-2">NFes</h6>
												<Row>
													<Col sm="4">
														<div className="card-info">
															<i className="bi bi-card-checklist"></i>
															<div className="card-title">
																<span className="title">Total</span>
																<span className="msg">{item.qtde_pedidos}</span>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card-info">
															<div className="div-card-icons">
																<i className="bi bi-box"></i>
																<i className="bi bi-arrow-down-circle-fill"></i>
															</div>
															<div className="card-title">
																<span className="title">Entregue</span>
																<span className="msg">
																	{item?.pedidos_entregue?.qtde}
																</span>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card-info">
															<i className="bi bi-box2"></i>
															<div className="card-title">
																<span className="title">A Entregar</span>
																<span className="msg">
																	
																	{item.qtde_pedidos - item.pedidos_entregue?.qtde}
																</span>
															</div>
														</div>
													</Col>
												</Row>
												<h6 className="card-header mt-4 mb-2">Produtos</h6>
												<Row>
													<Col sm="4">
														<div className="card-info">
															<i className="bi bi-1-square"></i>
															<div className="card-title">
																<span className="title">Total</span>
																<span className="msg">
																	{item.qtde_produtos}
																</span>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card-info">
															<i className="bi bi-3-square"></i>
															<div className="card-title">
																<span className="title">Entregue</span>
																<span className="msg">
																	{item.produtos_entregue}
																</span>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card-info">
															<i className="bi bi-9-square"></i>
															<div className="card-title">
																<span className="title">A Entregar</span>
																<span className="msg">
																	{item.qtde_produtos - item.produtos_entregue}
																</span>
															</div>
														</div>
													</Col>
												</Row>
												<h6 className="card-header mt-4 mb-2">Peso / Volume</h6>
												<Row>
													<Col sm="4">
														<div className="card-info">
															<div className="div-card-icons">
																<i className="bi bi-box"></i>
																<i className="bi bi-arrow-up-right-circle-fill"></i>
															</div>
															<div className="card-title">
																<span className="title">Volume Enviado</span>
																<span className="msg">
																	{item.metros_total} [m<sup>2</sup>]
																</span>
															</div>
														</div>
													</Col>

													<Col sm="4">
														<div className="card-info">
															<div className="div-card-icons">
																<i className="bi bi-box2"></i>
																<i className="bi bi-arrow-up-right-circle-fill"></i>
															</div>
															<div className="card-title">
																<span className="title">Peso Enviado</span>
																<span className="msg">
																	{item.peso_total} [kg]
																</span>
															</div>
														</div>
													</Col>
												</Row>
											</Col>
											<div className="truck_img"></div>
										</Row>
									</div>
								);
							})}
						</div>
					</div>
				) : (
					""
				)}
				{isLoaded ? (
					<GoogleMap
						mapContainerStyle={{ width: "100%", height: "100%" }}
						center={center}
						zoom={5.8}
						options={{
							streetViewControl: true,
							mapTypeControl: false,
							fullscreenControl: true,

							styles: [
								{
									featureType: "poi.business",
									stylers: [{ visibility: "off" }],
								},
								{
									featureType: "poi.medical",
									stylers: [{ visibility: "off" }],
								},
								{
									featureType: "poi.park",
									stylers: [{ visibility: "off" }],
								},
								{
									featureType: "poi.attraction",
									stylers: [{ visibility: "off" }],
								},
								{
									featureType: "poi.government",
									stylers: [{ visibility: "off" }],
								},
								{
									featureType: "poi.school",
									stylers: [{ visibility: "off" }],
								},
								{
									featureType: "poi.sports_complex",
									stylers: [{ visibility: "off" }],
								},
							],
						}}
						onLoad={setMap}
					>
						{markers.map((item, index) => {
							if (!item.location) return
							const { lat, lng } = item.location
							return (
								<Marker
									key={index}
									position={{
										lat: parseFloat(lat),
										lng: parseFloat(lng),
									}}
									icon={{
										// url:"https://cdn-icons-png.flaticon.com/512/4047/4047296.png",
										url: "https://cdn-icons-png.flaticon.com/512/936/936810.png",

										scaledSize: new google.maps.Size(65, 60),
									}}
									// label={item.truckInfo.caminhao}
									onClick={(e) => {
										selectMarker(item);
										selectCardInfos(item.cargas_id);
										setStyledButton(true);
									}}
									ref={infoClickedRef}
								/>
							);
						})}

						{infoClicked && (
							<InfoWindow
								position={{
									lat: parseFloat(infoClicked?.location?.lat/*  + 0.0009 */),
									lng: parseFloat(infoClicked?.location?.lng),
								}}
								onCloseClick={() => setInfoClicked(null)}
								options={{
									position: "absolute",
									top: 100,
									maxWidth: 400,
								}}
							>
								<div style={{ marginBottom: "20px" }}>
									<Row>
										{/* <Card.Title>Carga </Card.Title>
                                        <Card.Title></Card.Title>
                                        <Card.Title>Condutor </Card.Title> */}
										<Col sm="12">
											<h4
												className="card-header mb-4"
												style={{ width: "100%" }}
											>
												Carga N°{infoClicked.cargas_id}, Caminhao{" "}
												{infoClicked.caminhao}
											</h4>

											<Row className="mb-3">
												<Col sm="12">
													<div className="card-info">
														<div className="card-title">
															<i className="bi bi-person"></i>
															<span
																className="title"
																style={{ marginLeft: "5px" }}
															>
																Motorista:
															</span>
														</div>
														<b>
															<span className="title">
																- {infoClicked.caminhoneiro}
															</span>
														</b>
													</div>
												</Col>
												<Col sm="12"></Col>
											</Row>
											<Row className="mb-3">
												<Col sm="12">
													<div className="card-info">
														<div className="card-title">
															<i className="bi bi-geo-alt"></i>
															<span
																className="title"
																style={{ marginLeft: "5px" }}
															>
																Última Localização:
															</span>
														</div>
														<b>
															<span className="title">
																- {infoClicked.location_name}
															</span>
														</b>
													</div>
												</Col>
												<Col sm="12"></Col>
											</Row>
											<Row className="mb-3">
												<Col sm="12">
													<div className="card-info">
														<div className="card-title">
															<i className="bi bi-stopwatch"></i>
															<span
																className="title"
																style={{ marginLeft: "5px" }}
															>
																Última Atualização:
															</span>
														</div>
														<b>
															<span className="title">
																- {infoClicked.data_inicio_entrega}
															</span>
														</b>
													</div>
												</Col>
												<Col sm="12"></Col>
											</Row>
											<Row className="mb-3">
												<Col sm="12">
													<div className="card-info">
														<div className="card-title">
															<i className="bi bi-truck"></i>
															<span
																className="title"
																style={{ marginLeft: "5px" }}
															>
																Peso Atual:
															</span>
														</div>
														<b>
															<span className="title">
																- {infoClicked.peso_atual} Kg
															</span>
														</b>
													</div>
												</Col>
												<Col sm="12"></Col>
											</Row>
											<Row className="mb-3">
												<Col sm="12">
													<div className="card-info">
														<div className="card-title">
															<i className="bi bi-bounding-box"></i>
															<span
																className="title"
																style={{ marginLeft: "5px" }}
															>
																Volume Atual:
															</span>
														</div>
														<b>
															<span className="title">
																- {infoClicked.volume_atual} m<sup>2</sup>
															</span>
														</b>
													</div>
												</Col>
												<Col sm="12"></Col>
											</Row>
										</Col>
										<div className="truck_img"></div>
									</Row>
								</div>
							</InfoWindow>
						)}
					</GoogleMap>
				) : (
					""
				)}
			</div>
			{modal.flag ? (
				<ModalError
					show={modal.flag}
					title="Atenção"
					setShow={setModal}
					msg={modal.msg}
				/>
			) : (
				""
			)}
			{/* {manutencao.flag ? <ModalError show={manutencao.flag} title='Alerta' setShow={setManutencao} {...manutencao} /> : ""} */}
		</PlanoLositicaLayout>
	);
}
