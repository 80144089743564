import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	createManifesto,
	getData,
	numerosEditadoBR,
	updateTableNew,
	valorMoeda,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";
import axios from "axios";
import { getErrorMessage } from "../../services/helpers";
import ModalErrorPretty from "./ModalErrorPretty";
import ModalSuccessPretty from "./ModalSuccessPretty";

export default function ModalEncerraManifesto(props) {
	const user = useSelector((state) => state.userSlice);
	
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Encerrar",
		style: "primary",
	});
	const [municipios, setMunicipios] = useState([]);
	const [estados, setEstados] = useState([]);
	const [estadosDestino, setEstadosDestino] = useState([]);
	const [municipiosDestino, setMunicipiosDestino] = useState([]);


	const [error, setError] = useState({ show: false, message:"" });
	const [success, setSuccess] = useState({ show: false, message:"" });


	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, show: false }));

	const [body, setBody] = useState({chave: props.bodyList.chave, uf:"MT"})

	const onChangeInput = (e) => {
		
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const onSubmitForm = async (e) => {
		e.preventDefault()
		setLoading({isLoading: true, msg:"Carregando", style:'warning'})
		try {
			if(!body.chave) throw new Error("Chave MDFe não informada.")
			if(!body.uf) throw new Error("UF de Encerramento não informada.")
			if (!body.municipio) throw new Error("Município de Encerramento não informada.")
			
			const updateData = await updateTableNew('manifesto/v2/encerra-mdfe', body)

			if(updateData.error) throw new Error(updateData.error)
			setLoading({isLoading: false, msg:"Encerrado", style:'success'})
			props.updateStatusManifesto()
			
			setTimeout(() => {
				handlerSimpleClose()
			}, 750)
		} catch (error) {
			setError({show: true, message: getErrorMessage(error)})
		} finally {
			setLoading({isLoading: false, msg:"Encerrar", style:'primary'})
		}
	}


	useEffect(() => {
		axios
			.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
			.then((response) => {
				setEstados(
					response.data.sort((a, b) => {
						if (a.sigla > b.sigla) return 1;
						if (a.sigla < b.sigla) return -1;
						return 0;
					})
				);
				setEstadosDestino(
					response.data.sort((a, b) => {
						if (a.sigla > b.sigla) return 1;
						if (a.sigla < b.sigla) return -1;
						return 0;
					})
				);
			});
	}, []);

	useEffect(() => {
		if(!body.uf) return
		(async () => {
			try {
				const muni = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${body.uf}/municipios`)
				setMunicipios(muni.data)
			} catch (error) {
				setError({ show: true, message: getErrorMessage(error) })
			}
		})()
	}, [body.uf]);


	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Encerrar Manifesto
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={onSubmitForm}>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>UF de Encerramento:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="uf"
									onChange={onChangeInput}
									value={body.uf}
									required
								>
									<option value=""></option>
									{estados.map((item, key) => (
										<option value={item.sigla} key={key}>
											{item.sigla}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Município de Encerramento:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="municipio"
									onChange={onChangeInput}
									value={body.municipio}
									required
								>
									<option value=""></option>
									{municipios.map((item, key) => (
										<option value={item.nome} key={key}>
											{item.nome}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Chave:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									value={body.chave}
									disabled
								/>
							</Col>
						</Row>
					<Row style={{ width: "100%" }} className="mt-5">
						<Col>
							<Button variant={loading.style} type="submit">
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
					</Form>
				</Modal.Body>
				{error.show ? (
					<ModalErrorPretty setShow={setError} {...error} />
				) : (
					""
				)}
				{success.show ? (
					<ModalSuccessPretty setShow={setSuccess} {...success} />
				) : (
					""
				)}
			</Modal>
		</>
	);
}
